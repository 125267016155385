import React, { useState, useRef } from 'react';
import Select from 'react-select'

// Apollo
import { gql, useQuery } from '@apollo/client';

// Lodash Debounce
import debounce from 'lodash.debounce';

const PRODUCTS = gql`
    query Products($name: String, $first: Int!, $page: Int!) {
        products(name: $name, first: $first, page: $page, orderBy: [{ column: NAME, order: ASC }]) {
            data {
                id
                name
                productCategory {
                    id
                    name
                }
                serviceCategories {
                    id
                    name
                    start_date
                    end_date
                }
            }
        }
    }
`;

// -------------------------------------- SELECT PRODUCT --------------------------------------- \\
const SelectProduct = ({ product, setProduct, offSeason }) => {
    const [state, setState] = useState({
        inputValue: '',
    });

    const { loading, data } = useQuery(PRODUCTS, {
        variables: {
            page: 1,
            first: 10,
            name: '%' + state.inputValue + '%',
        },
        fetchPolicy: 'network-only',
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, inputValue: value == null ? '' : value });
    };
    const delayedOnSearch = useRef(debounce(inputValue => onSearch(inputValue), 500)).current;

    const handleDelayedOnSearch = (value, reason) => {
        if (reason.action === 'input-change') {
            delayedOnSearch(value);
        }
    };

    return (
        <>
            <Select
                placeholder="Chercher un produit..."
                value={product}
                onChange={value => setProduct(value)}
                onInputChange={(inputValue, reason) => handleDelayedOnSearch(inputValue, reason)}
                options={data ? data.products.data.map(c => ({ value: c.id, label: c.name, productCategory: c.productCategory, serviceCategories: c.serviceCategories })) : []}
                isLoading={loading}
                isClearable={true}
                noOptionsMessage={() => 'Aucun'}
                menuPortalTarget={document.body}
                maxMenuHeight={200}
                styles={{
                    control: base => ({ ...base, fontSize: 14, backgroundColor: offSeason ? 'rgba(255, 0, 0, 0.5)' : 'unset' }),
                    menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                    container: base => ({ ...base, minWidth: 200, width: '100%' }),
                }}
            />
        </>
    );
};

SelectProduct.defaultProps = {
    offSeason: false,
};

export default SelectProduct;
