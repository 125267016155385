import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

// ---------------------------------------- SELECT COLOR ---------------------------------------- \\
const SelectColor = ({ color, setColor, placeholder }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    {placeholder}
                </label>
                <div className="flex items-center">
                    {/* -------------------- SELECTED COLOR -------------------- */}
                    <div
                        className="rounded h-7 w-7 border border-blueGray-300"
                        style={{ backgroundColor: color }}
                    />
                    {/* -------------------- OPEN BUTTON -------------------- */}
                    <button
                        onClick={() => setOpen(!open)}
                        type="button"
                        className="border border-blueGray-300 px-3 py-1 rounded text-blueGray-300 text-sm font-semibold
                            hover:text-white hover:bg-blueGray-300 ml-2"
                    >
                        {open ? 'Fermer la fenêtre' : 'Choisir une couleur'}
                    </button>
                </div>
            </div>
            {/* -------------------- COLOR PICKER -------------------- */}
            {open && (
                <div className="absolute">
                    <SketchPicker
                        color={color}
                        onChange={value => setColor(value.hex)}
                    />
                </div>
            )}
        </>
    );
};

SelectColor.defaultProps = {
    placeholder: 'Sélectionneur une couleur',
};

export default SelectColor;
