import React, { useState } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Import Messages
import messages from 'messages.json';

// ---------------------------------------- INPUT FILE ---------------------------------------- \\
const InputFile = ({ inputId }) => {
    const { addToast } = useToasts();
    const [fileName, setFileName] = useState(undefined);

    const onChange = event => {
        // Max size file : 25 Mo
        if (document.getElementById('upload-' + inputId).files[0].size >= 25000000) {
            addToast(messages['file.max.size'], { appearance: 'warning' });
        }
        else {
            const fileName = event.target.value;
            setFileName(fileName.replace(/^.*[\\/]/, ''));
        }
    };

    return (
        <div className="div-input-file">
            <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={'upload-' + inputId} className="label-input-file">
                    <span className="text-sm text-blueGray-600">Sélectionner...</span>
                    <i className="fas fa-upload ml-2" />
                </label>
                <input
                    id={'upload-' + inputId}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={e => onChange(e)}
                />
            </div>
            <span className="text-sm text-blueGray-600 m-1">{fileName}</span>
        </div>
    );
};

export default InputFile;
