import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

// CSS
import 'react-datepicker/dist/react-datepicker.css';

// Locale
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

// -------------------------------------- DATE PICKER SEARCH ---------------------------------------------- \\
const DatePickerSearch = ({
    placeholderStartDate,
    placeholderEndDate,
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    onSearch,
}) => {
    return (
        <div className="flex">
            {/* -------------------- START DATE -------------------- */}
            <DatePicker
                locale="fr"
                selected={startDate}
                onChange={onChangeStartDate}
                wrapperClassName="date-picker-search"
                dateFormat="dd/MM/yyyy"
                showPopperArrow={false}
                placeholderText={placeholderStartDate}
                customInput={
                    <input
                        className="date-picker-search px-3 py-2 border rounded-l-lg text-sm"
                    />
                }
            />
            {/* -------------------- END DATE -------------------- */}
            <DatePicker
                locale="fr"
                selected={endDate}
                onChange={onChangeEndDate}
                wrapperClassName="date-picker-search"
                dateFormat="dd/MM/yyyy"
                showPopperArrow={false}
                placeholderText={placeholderEndDate}
                customInput={
                    <input
                        className="date-picker-search px-3 py-2 border border-l-0 text-sm"
                    />
                }
            />
            {/* -------------------- SEARCH -------------------- */}
            <button
                onClick={onSearch}
                className="px-3 rounded-r-lg bg-blueGray-400 hover:bg-blueGray-500"
            >
                <i className="fas fa-search text-white" />
            </button>
        </div>
    );
};

DatePickerSearch.defaultProps = {
    placeholderStartDate: 'Début',
    placeholderEndDate: 'Fin',
};

export default DatePickerSearch;
