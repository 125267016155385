import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import ProductCategoryCards from './product-category-cards';
import ProductCategoryList from './product-category-list';
import ProductCategory from './product-category';
import CreateProductCategory from './create-product-category';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Modal from 'components/modal';
import InputSearch from 'components/input-search';

const PRODUCT_CATEGORIES = gql`
    query ProductCategories($parentId: Int, $name: String, $first: Int!, $page: Int!) {
        productCategories(parentId: $parentId, name: $name, first: $first, page: $page, orderBy: [{ column: NAME, order: ASC }]) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                photo
                description
                parentProductCategory {
                    id
                    name
                }
                childProductCategories {
                    id
                }
            }
        }
    }
`;

// -------------------------------------- PRODUCT CATEGORY ROUTER ------------------------------------------ \\
const ProductCategoryRouter = () => {
    return (
        <Switch>
            <Route exact path="/app/product-categories">
                <ProductCategories />
            </Route>
            <Route exact path="/app/product-categories/:productCategoryId">
                <ProductCategory />
            </Route>
        </Switch>
    );
};

// ---------------------------------------- PRODUCT CATEGORIES --------------------------------------------- \\
const ProductCategories = () => {

    const [state, setState] = useState({
        menu: 'card',
        page: 1,
        first: 100,
        parentId: 0,
        searchName: '',

        // List
        productCategory: null,

        // Cards
        breadcrumb: [],
        productCategoryId: null,
        parentProductCategory: null,

        // Modals
        openCreate: false,
        openUpdate: false,
        openDelete: false,
        openAddSubProductCategory: false,
    });

    const { loading, error, data, refetch } = useQuery(PRODUCT_CATEGORIES, {
        variables: {
            first: state.first,
            page: state.page,
            name: '%' + state.searchName + '%',
            parentId: state.parentId,
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({
            ...state,
            openCreate: false,
            openUpdate: false,
            openDelete: false,
        });
        refetch();
    };

    const onSearch = value => {
        if (state.menu === 'card') {
            if (value === '') {
                setState({ ...state, searchName: value, page: 1, first: 100, parentId: 0 });
            }
            else {
                setState({ ...state, searchName: value, page: 1, first: 100, parentId: -1 });
            }
        }
        if (state.menu === 'list') {
            setState({ ...state, searchName: value, page: 1, first: 20, parentId: -1 });
        }
    };

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex flex-col lg:flex-row items-center justify-between p-3">
                    <div className="flex flex-col lg:flex-row items-center">
                        <div className="mr-2">
                            <button
                                onClick={() => setState({ ...state, menu: 'card', page: 1, first: 100, parentId: state.searchName !== '' ? -1 : 0, breadcrumb: [] })}
                                type="button"
                                className={(state.menu === 'card' ? 'bg-indigo-500' : 'bg-blueGray-300') + ' text-white hover:bg-indigo-600 text-xs font-bold uppercase px-6 py-2 rounded-l-lg outline-none focus:outline-none ease-linear transition-all duration-150'}
                            >
                                <i className="fas fa-box-open text-xl opacity-75" />
                            </button>
                            <button
                                onClick={() => setState({ ...state, menu: 'list', page: 1, first: 20, parentId: -1 })}
                                type="button"
                                className={(state.menu === 'list' ? 'bg-indigo-500' : 'bg-blueGray-300') + '  text-white hover:bg-indigo-600 text-xs font-bold uppercase px-6 py-2 rounded-r-lg outline-none focus:outline-none ease-linear transition-all duration-150'}
                            >
                                <i className="fas fa-list text-xl opacity-75" />
                            </button>
                        </div>
                        <div className="mt-2 lg:mt-0">
                            <InputSearch
                                placeholder="Chercher une catégorie"
                                onSearch={searchName => onSearch(searchName)}
                            />
                        </div>
                    </div>
                    <div className="mt-2 lg:mt-0">
                        <Button
                            label="Ajouter une catégorie"
                            onClick={() => setState({ ...state, openCreate: true })}
                        />
                    </div>
                </div>
                {/* -------------------- HEADERS -------------------- */}
                {state.menu === 'card' && (
                    <ProductCategoryCards
                        loading={loading}
                        error={error}
                        data={data}
                        state={state}
                        setState={setState}
                    />
                )}
                {state.menu === 'list' && (
                    <ProductCategoryList
                        loading={loading}
                        error={error}
                        data={data}
                        onRefetch={onRefetch}
                        state={state}
                        setState={setState}
                    />
                )}
            </Card>
            {/* --------------------------- CREATE --------------------------- */}
            {state.openCreate && (
                <Modal
                    title="Ajouter une catégorie de produit"
                    required
                >
                    <CreateProductCategory
                        onClose={() => setState({ ...state, openCreate: false })}
                        refetch={onRefetch}
                    />
                </Modal>
            )}
        </>
    );
};

export default ProductCategoryRouter;
