import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import Input from 'components/input';

// Messages
import messages from 'messages.json';

// -------------------------------------- FORGOTTEN PASSWORD ---------------------------------------------- \\
const ForgottenPassword = () => {

    const [state, setState] = useState({
        email: '',
        password_token: '',
        new_password: '',
        confirmed_password: '',
    });

    return (
        <>
            <div className="w-full grid grid-cols-3">
                <div />
                <div className="bg-white rounded shadow">
                    {/* -------------------- TITLE -------------------- */}
                    <div className="text-blueGray-400 text-center font-bold pt-4">
                        <small>Mot de passe oublié</small>
                    </div>
                    <div className="p-4">
                        <div className="text-sm mb-4">
                            1. Recevez un code pour modifier votre mot de passe :
                        </div>
                        {/* -------------------- EMAIL -------------------- */}
                        <Input
                            value={state.email}
                            onChange={e => setState({ ...state, email: e.target.value })}
                            type="email"
                            placeholder="Email"
                            required
                        />
                        {/* -------------------- SEND PASSWORD TOKEN -------------------- */}
                        <SendPasswordToken
                            email={state.email}
                        />
                    </div>
                    <div className="border-2 border-t border-blueGray-200" />
                    <div className="p-4">
                        <div className="text-sm mb-4">
                            2. Entrez le code reçu par adresse mail et choisissez votre nouveau mot de passe : (minimum 6 caractères)
                        </div>
                        {/* -------------------- PASSWORD TOKEN -------------------- */}
                        <Input
                            value={state.password_token}
                            onChange={e => setState({ ...state, password_token: e.target.value })}
                            placeholder="Code"
                            required
                        />
                        {/* -------------------- NEW PASSWORD -------------------- */}
                        <Input
                            value={state.new_password}
                            onChange={e => setState({ ...state, new_password: e.target.value })}
                            type="password"
                            placeholder="Nouveau mot de passe"
                            required
                        />
                        {/* -------------------- CONFIRM PASSWORD -------------------- */}
                        <Input
                            value={state.confirmed_password}
                            onChange={e => setState({ ...state, confirmed_password: e.target.value })}
                            type="password"
                            placeholder="Confirmer le nouveau mot de passe"
                            required
                        />
                        {/* -------------------- UPDATE PASSWORD -------------------- */}
                        <UpdatePassword
                            password_token={state.password_token}
                            new_password={state.new_password}
                            confirmed_password={state.confirmed_password}
                        />
                    </div>
                </div>
            </div>
            {/* -------------------- FORGOTTEN PASSWORD -------------------- */}
            <div className="text-center text-white mt-2">
                <Link to="/auth">Retour à l'accueil</Link>
            </div>
        </>
    );
};

// -------------------------------------- SEND PASSWORD TOKEN --------------------------------------------- \\
const SEND_PASSWORD_TOKEN = gql`
    mutation SendPasswordToken($email: String!) {
        sendPasswordToken(email: $email)
    }
`;

const SendPasswordToken = ({ email }) => {
    const { addToast } = useToasts();
    const [sendPasswordToken, { loading, error, data }] = useMutation(SEND_PASSWORD_TOKEN);

    const onSendPasswordToken = () => {
        if (email == null || email === '') {
            addToast('Veuillez  saisir votre adresse mail', { appearance: 'warning' });
        }
        else {
            sendPasswordToken({ variables: { email: email } });
        }
    };

    useEffect(() => {
        if (data && data.sendPasswordToken) {
            addToast(data.sendPasswordToken, { appearance: 'success' });
        }
        if (error) {
            addToast(error.message, { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <button
            onClick={onSendPasswordToken}
            disabled={loading}
            className={(loading ? 'cursor-not-allowed bg-green-600' : 'cursor-pointer bg-indigo-600 hover:bg-indigo-700') + ' w-full p-1 rounded shadow'}
        >
            <b className="text-white text-sm uppercase">
                {loading ? 'Envoi en cours...' : 'Envoyer le code'}
            </b>
        </button>
    );
};

// ---------------------------------------- UPDATE PASSWORD ---------------------------------------------- \\
const UPDATE_PASSWORD = gql`
    mutation UpdatePassword($passwordToken: String!, $password: String!) {
        updatePassword(passwordToken: $passwordToken, password: $password)
    }
`;

const UpdatePassword = ({ password_token, new_password, confirmed_password }) => {
    const history = useHistory();
    const { addToast } = useToasts();
    const [updatePassword, { loading, error, data }] = useMutation(UPDATE_PASSWORD);

    const onUpdatePassword = () => {
        if (password_token === '' || new_password === '' || confirmed_password === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else if (new_password !== confirmed_password) {
            addToast('Les mots de passe ne correspondent pas', {  appearance: 'warning' });
        }
        else if (new_password.length < 6) {
            addToast('Veuillez saisir un mot de passe contenant minimum 6 caractères', { appearance: 'warning' });
        }
        else {
            updatePassword({
                variables: {
                    passwordToken: password_token,
                    password: new_password,
                },
            });
        }
    };

    useEffect(() => {
        if (data && data.updatePassword) {
            addToast(data.updatePassword, { appearance: 'success' });
            history.push('/auth');
        }
        if (error) {
            addToast(error.message, { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <button
            onClick={onUpdatePassword}
            disabled={loading}
            className={(loading ? 'cursor-not-allowed bg-green-600' : 'cursor-pointer bg-indigo-600 hover:bg-indigo-700') + ' w-full p-1 rounded shadow'}
        >
            <b className="text-white text-sm uppercase">
                {loading ? 'En cours...' : 'Mettre à jour le mot de passe'}
            </b>
        </button>
    );
};

export default ForgottenPassword;
