import React from 'react';
import Pagination from './pagination';

// ---------------------------------------- PAGINATION CONTAINER ---------------------------------------- \\
const PaginationContainer = ({ currentPage, pageSize, totalCount, onChangePage }) => {
    return (
        <div className="w-full flex flex-col-reverse lg:flex-row lg:justify-between items-center p-3 border-t border-solid border-blueGray-100">
            <div className="text-sm mt-2 lg:mt-0">
                {totalCount} Résultat{totalCount > 1 ? 's' : ''}
            </div>
            <div>
                <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    onChangePage={onChangePage}
                />
            </div>
        </div>
    );
};

export default PaginationContainer;
