import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';

const DESCRIPTION_MODEL = gql`
    query DescriptionModel($id: ID!) {
        descriptionModel(id: $id) {
            id
            name
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
        }
    }
`;

// ------------------------------------------- DESCRIPTION MODEL --------------------------------------------------- \\
const DescriptionModel = () => {
    const history = useHistory();
    const { descriptionModelId } = useParams();

    const { loading, error, data } = useQuery(DESCRIPTION_MODEL, {
        variables: {
            id: descriptionModelId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) return <Loading />;
    if (error || data.descriptionModel == null) return <Error />;

    return (
        <Card>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center p-3">
                <div className="w-1/3">
                    <Button
                        label="Retour"
                        onClick={() => history.push('/app/description-models')}
                    />
                </div>
                <div className="w-1/3 text-center">
                    <h3 className="font-semibold text-base text-blueGray-700">
                        {data.descriptionModel.name}
                    </h3>
                </div>
            </div>
            {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
            <div className="flex items-center justify-between bg-blueGray-100 p-3">
                {data.descriptionModel.creator && (
                    <div className="text-xs uppercase font-semibold text-blueGray-500">
                        Créateur : {data.descriptionModel.creator.fullName}
                    </div>
                )}
                {data.descriptionModel.lastModifier && (
                    <div className="text-xs uppercase font-semibold text-blueGray-500">
                        Dernière modification : {data.descriptionModel.lastModifier.fullName}
                    </div>
                )}
            </div>
        </Card>
    );
};

export default DescriptionModel;
