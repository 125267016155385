import React from 'react';

// ------------------------------------------- PHOTO SELECTOR --------------------------------------------------- \\
const PhotoSelector = ({ onClick }) => {
    return (
        <div
            onClick={onClick}
            className="photo-selector border-blueGray-300 hover:bg-blueGray-400"
        >
            <i className="fas fa-plus text-xl mr-2" />
            <i className="far fa-image text-4xl" />
        </div>
    );
};

export default PhotoSelector;
