import React from 'react';

// ---------------------------------------- ERROR ---------------------------------------- \\
const Error = () => {
    return (
        <div className="w-full text-center">
            <i className="fas fa-exclamation-triangle" />
            <b className="text-blueGray-700 ml-2 text-sm">
                Une erreur est survenue
            </b>
        </div>
    );
};

export default Error;
