import React, { useState } from 'react';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import ServiceProductForm from './service-product-form';
import { productIsOffSeason } from '../off-season';

// Components
import Loading from 'components/loading';
import Error from 'components/error';
import InputSearch from 'components/input-search';
import Button from 'components/button';
import ItemCard from 'components/item-card';

// ---------------------------------- SEARCH PRODUCT BY SERVICE CATEGORIES ----------------------------------- \\
const SearchProductByServiceCategories = ({ date, state, setState }) => {
    return (
        <>
            {/* -------------------- SERVICE CATEGORIES -------------------- */}
            {(state.product == null && state.breadcrumb.length === 0) && (
                <ServiceCategories
                    state={state}
                    setState={setState}
                />
            )}
            {/* -------------------- PRODUCTS -------------------- */}
            {(state.product == null && state.breadcrumb.length > 0) && (
                <ServiceCategory
                    date={date}
                    state={state}
                    setState={setState}
                />
            )}
            {/* -------------------- SERVICE PRODUCT FORM -------------------- */}
            {state.product != null && (
                <>
                    <div className="mb-4">
                        <Button
                            label="Retour"
                            onClick={() => setState({ ...state, product: null })}
                        />
                    </div>
                    <div className="mb-4">  
                        <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Produit
                            <span className="text-base text-indigo-700 ml-1">*</span>
                        </label>
                        {productIsOffSeason(date, state.product) && (
                            <span className="text-blueGray-600 text-xs font-bold ml-2 mb-2">
                                (Produit hors période)
                            </span>  
                        )}
                        <div
                            className="px-3 py-2 border-O shadow rounded"
                            style={{ backgroundColor: productIsOffSeason(date, state.product) ? 'rgba(255, 0, 0, 0.5)' : 'unset' }}
                        >
                            <label className="text-sm">
                                {state.product.label}
                            </label>
                        </div>
                    </div>
                    <ServiceProductForm
                        state={state}
                        setState={setState}
                    />
                </>
            )}
        </>
    );
};

// ------------------------------------------ SERVICE CATEGORIES --------------------------------------------- \\
const SERVICE_CATEGORIES = gql`
    query ServiceCategories($name: String, $first: Int!, $page: Int!) {
        serviceCategories(
            name: $name,
            first: $first,
            page: $page,
            orderBy: [
                { column: START_DATE, order: ASC },
                { column: END_DATE, order: ASC },
                { column: PRIORITY, order: ASC },
                { column: NAME, order: ASC },
            ],
        ) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                photo
            }
        }
    }
`;

const ServiceCategories = ({ state, setState }) => {
    const [searchName, setSearchName] = useState('');

    const { loading, error, data } = useQuery(SERVICE_CATEGORIES, {
        variables: {
            first: 100,
            page: 1,
            name: '%' + searchName + '%',
        },
        fetchPolicy: 'network-only',
    });

    const onClickServiceCategory = serviceCategory => {
        let breadcrumb = [];
        breadcrumb.push({ id: serviceCategory.id, title: serviceCategory.name })
        setState({ ...state, breadcrumb, serviceCategoryId: serviceCategory.id });
    };

    if (error) return <Error />;

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center mb-4">
                <Button
                    label="Retour"
                    onClick={() => setState({ ...state, page: 'research-choice' })}
                />
                <div className="ml-2">
                    <InputSearch
                        placeholder="Chercher une catégorie"
                        onSearch={value => setSearchName(value)}
                    />
                </div>
            </div>
            {/* -------------------- PRODUCT CATEGORIES -------------------- */}
            {loading ? (
                <Loading />
            ) : (
                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
                    {data.serviceCategories.data.map(serviceCategory => (
                        <ItemCard
                            key={serviceCategory.id}
                            onClick={() => onClickServiceCategory(serviceCategory)}
                            photo={serviceCategory.photo}
                            title={serviceCategory.name}
                            icon="fa-box-open"
                            hover
                        />
                    ))}
                </div>
            )}
        </>
    );
};

// ------------------------------------------ SERVICE CATEGORY ----------------------------------------------- \\
const SERVICE_CATEGORY = gql`
    query ServiceCategory($id: ID!) {
        serviceCategory(id: $id) {
            id
            name
            photo
            products {
                id
                name
                photo
                productCategory {
                    id
                    name
                }
                serviceCategories {
                    id
                    name
                    start_date
                    end_date
                }
            }
        }
    }
`;

const ServiceCategory = ({ date, state, setState }) => {
    const { loading, error, data } = useQuery(SERVICE_CATEGORY, {
        variables: {
            id: state.serviceCategoryId,
        },
        fetchPolicy: 'network-only',
    });

    const onClickBreadcrumb = (serviceCategoryId, serviceCategoryIndex) => {
        let breadcrumb = state.breadcrumb;
        breadcrumb = breadcrumb.filter((item, index) =>  index <= serviceCategoryIndex);
        setState({ ...state, breadcrumb, serviceCategoryId });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <div className="flex mb-4">
                <div
                    onClick={() => setState({ ...state, breadcrumb: [], serviceCategoryId: null })}
                    className={'cursor-pointer hover:text-indigo-500 px-2 py-1 rounded bg-indigo-100 uppercase text-xs text-blueGray-800 font-bold'}
                >
                    Tous
                </div>
                {state.breadcrumb.map((item, index) => (
                    <div key={item.id} className="flex items-center">
                        <i className="fas fa-chevron-right ml-2" />
                        <div
                            onClick={() => onClickBreadcrumb(item.id, index)}
                            className={(index + 1 !== state.breadcrumb.length && 'cursor-pointer hover:text-indigo-500') + ' px-2 py-1 rounded bg-indigo-100 uppercase text-xs text-blueGray-800 font-bold ml-2'}
                        >
                            {item.title}
                        </div>
                    </div>
                ))}
            </div>
            {/* -------------------- PRODUCTS -------------------- */}
            <div className="mt-4 mb-2">
                Produits
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
                {data.serviceCategory.products.length > 0 ? (
                    data.serviceCategory.products.map(product => (
                        <ItemCard
                            key={product.id}
                            onClick={() => setState({ ...state, product: { value: product.id, label: product.name, productCategory: product.productCategory, serviceCategories: product.serviceCategories }})}
                            photo={product.photo}
                            title={product.name}
                            offSeason={productIsOffSeason(date, product)}
                            icon="fa-apple-alt"
                            hover
                        />
                    ))
                ) : (
                    <ItemCard empty />
                )}
            </div>
        </>
    );
};

export default SearchProductByServiceCategories;
