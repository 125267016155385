import React from 'react';

// ------------------------------------------- CHECKBOX --------------------------------------------------- \\
const CustomizedCheckbox = ({ value, onChange, placeholder, disabled }) => {
    return (
        <div className="w-full mb-4">
            <input
                value={value}
                onChange={onChange}
                disabled={disabled}
                type="checkbox"
                className="form-checkbox rounded-sm h-5 w-5"
            />
            <label className="text-blueGray-600 text-sm font-bold ml-2">
                {placeholder}
            </label>
        </div>
    );
};

CustomizedCheckbox.defaultProps = {
    disabled: false,
};

export default CustomizedCheckbox;
