import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Toast
import { useToasts } from 'react-toast-notifications';

// Views
import ProductForm from './product-form';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const CREATE_PRODUCT = gql`
    mutation createProduct(
        $name: String!
        $status: ProductStatus!
        $description: String
        $productCategory: ConnectProductCategoryBelongsTo!
        $allergens: SyncAllergenBelongsToMany
        $serviceCategories: SyncServiceCategoryBelongsToMany
        $productOrigins: SyncProductOriginBelongsToMany
        $complements: SyncComplementBelongsToMany
        $productionAreaProducts: CreateProductionAreaProductHasMany
    ) {
        createProduct(input: {
            name: $name
            status: $status
            description: $description
            productCategory: $productCategory
            allergens: $allergens
            serviceCategories: $serviceCategories
            productOrigins: $productOrigins
            complements: $complements
            productionAreaProducts: $productionAreaProducts
        }) {
            id
        }
    }
`;

// ----------------------------------------- CREATE PRODUCT ----------------------------------------------- \\
const CreateProduct = () => {
    const history = useHistory();
    const { search } = useLocation();
    const { addToast } = useToasts();
    const [createProduct, { loading, error, data }] = useMutation(CREATE_PRODUCT);

    // Product Category
    let productCategoryId = new URLSearchParams(search).get('productCategoryId');
    let productCategoryName = new URLSearchParams(search).get('productCategoryName');

    const [state, setState] = useState({
        name: '',
        status: '',
        productCategory: (productCategoryId && productCategoryName) ? ({ value: productCategoryId, label: productCategoryName }) : null,
        productOrigins: [],
        complements: [],
        allergens: [],
        serviceCategories: [],
        productionAreaProducts: [],
        openProductionAreaProduct: false,
        description: '',
    });

    const getProductionAreaProducts = () => {
        if (state.productionAreaProducts != null && state.productionAreaProducts.length > 0) {
            let result = [];
            state.productionAreaProducts.forEach(productionAreaProduct => {
                result.push({
                    name: productionAreaProduct.name,
                    productionArea: { connect: productionAreaProduct.productionAreaId },
                });
            });
            return result;
        }
        return [];
    };

    const onCreate = () => {
        if (state.name === '' || state.status === '' || state.productCategory == null) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            createProduct({
                variables: { 
                    name: state.name,
                    status: state.status.value,
                    productCategory: { connect: state.productCategory.value },
                    productOrigins: { sync: state.productOrigins ? state.productOrigins.map(po => po.value) : [] },
                    complements: { sync: state.complements ? state.complements.map(c => c.value) : [] },
                    allergens: { sync: state.allergens ? state.allergens.map(a => a.value) : [] },
                    serviceCategories: { sync: state.serviceCategories.map(c => c.value) },
                    productionAreaProducts: { create: getProductionAreaProducts() },
                    description: state.description,
                }
            });
        }
    }

    useEffect(() => {
        if (data) {
            addToast(messages['product.saved'], { appearance: 'success' });
            history.push('/app/products');
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <Card>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center py-3 px-6">
                <div className="w-1/3">
                    <Button
                        label="Retour"
                        onClick={() => history.push('/app/products')}
                    />
                </div>
                <div className="w-1/3 text-center">
                    <h3 className="font-semibold text-base text-blueGray-700">
                        Ajouter un produit
                    </h3>
                </div>
                <div className="w-1/3 text-right">
                    <span className="text-sm text-indigo-300 font-semibold">
                        <i className="fas fa-star-of-life text-xs mr-2" />
                        Requis
                    </span>
                </div>
            </div>
            {/* -------------------- FORM -------------------- */}
            <div className="flex flex-col w-full px-6 py-3 border-t border-solid border-blueGray-100">
                {loading ? (
                    <Loading />
                ) : (
                    <ProductForm
                        state={state}
                        setState={setState}
                    />
                )}
            </div>
            {/* -------------------- SAVE -------------------- */}
            <div className="py-3 text-center border-t border-solid border-blueGray-100">
                <button
                    onClick={onCreate}
                    type="submit"
                    className="bg-indigo-500 hover:bg-green-600 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150
                        text-white text-xs"
                >
                    Sauvegarder
                </button>
            </div>
        </Card>
    );
};

export default CreateProduct;
