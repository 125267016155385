import React from 'react';

// Assets
import { serviceTypes, serviceStatuses } from 'assets/constants';

// Views
import { serviceCategoryIsOutOfDate } from './off-season';

// Components
import SelectClient from 'components/autocomplete/select-client';
import SelectServiceCategory from 'components/autocomplete/select-service-category';
import SelectExtras from 'components/autocomplete/select-extras';
import DatePicker from 'components/date-picker';
import Input from 'components/input';
import Select from 'components/select';

// ----------------------------------------- SERVICE FORM ----------------------------------------------- \\
const ServiceForm = ({ state, setState }) => {
    return (
        <>
            {/* --------------------- DATE --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <DatePicker
                    value={state.date}
                    onChange={date => setState({ ...state, date })}
                    required
                />
            </div>
            {/* --------------------- CLIENT --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Client
                    <span className="text-base text-indigo-700 ml-1">*</span>
                </label>
                <SelectClient
                    client={state.client}
                    setClient={client => setState({ ...state, client })}
                />
            </div>
            {/* --------------------- TYPE --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <Select
                    placeholder="Type"
                    required
                    value={state.type}
                    onChange={type => setState({ ...state, type })}
                    options={serviceTypes}
                />
            </div>
            {/* --------------------- SERVICE CATEGORY --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Catégorie
                    <span className="text-base text-indigo-700 ml-1">*</span>
                </label>
                {serviceCategoryIsOutOfDate(state.date, state.serviceCategory) && (
                    <label className="text-blueGray-600 text-xs font-bold mb-2 ml-2">
                        (Hors période)
                    </label>
                )}
                <SelectServiceCategory
                    serviceCategory={state.serviceCategory}
                    setServiceCategory={serviceCategory => setState({ ...state, serviceCategory })}
                    offSeason={serviceCategoryIsOutOfDate(state.date, state.serviceCategory)}
                />
            </div>
            {/* --------------------- STATUS --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <Select
                    placeholder="Statut"
                    value={state.status}
                    onChange={status => setState({ ...state, status })}
                    options={serviceStatuses}
                />
            </div>
            {/* --------------------- START TIME --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <Input
                    value={state.start_time}
                    onChange={e => setState({ ...state, start_time: e.target.value })}
                    placeholder="Horaire de début"
                    type="time"
                />
            </div>
            {/* --------------------- GUESTS NUMBER --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <Input
                    value={state.guests_number}
                    onChange={e => setState({ ...state, guests_number: e.target.value })}
                    placeholder="Nombre de convives"
                    type="number"
                />
            </div>
            {/* --------------------- PLACE --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <Input
                    value={state.place}
                    onChange={e => setState({ ...state, place: e.target.value })}
                    placeholder="Lieu"
                />
            </div>
            {/* --------------------- EXTRAS --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Extras
                </label>
                <SelectExtras
                    extras={state.extras}
                    setExtras={extras => setState({ ...state, extras })}
                />
            </div>
            {/* --------------------- NOTE --------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <Input
                    value={state.note}
                    onChange={e => setState({ ...state, note: e.target.value })}
                    placeholder="Note"
                    multiline
                />
            </div>
        </>
    );
};

export default ServiceForm;
