import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Services
import { isAuthenticated } from 'services/storage';

// Components
import Login from './login';
import ForgottenPassword from './forgotten-password';

// Assets
import LogoAVS from 'assets/img/logo-avs-white.png';
import RegisterScreen from 'assets/img/register-screen.png';

// ------------------------------------------- AUTH --------------------------------------------------- \\
const Auth = () => {

    if (isAuthenticated()) {
        return <Redirect from="*" to="/app" />
    };

    return (
        <div className="h-full bg-gradient-to-b from-indigo-900 to-indigo-400">
            <div
                className="h-full bg-no-repeat bg-cover flex flex-col"
                style={{ backgroundImage: `url(${RegisterScreen})` }}
            >
                {/* -------------------- LOGO AVS -------------------- */}
                <div className="container mx-auto flex items-center p-6">
                    <b className="uppercase text-white">
                        Portail AVS
                    </b>
                    <img alt='logo-avs' src={LogoAVS} className='h-8 w-8' />
                </div>
                <div className="container mx-auto p-6 flex-1 flex flex-col justify-center items-center">
                    <Switch>
                        <Route exact path="/auth">
                            <Login />
                        </Route>
                        <Route exact path="/auth/forgotten-password">
                            <ForgottenPassword />
                        </Route>
                        <Redirect from="*" to="/auth" />
                    </Switch>
                </div>
                {/* -------------------- FOOTER -------------------- */}
                <div className="bg-blueGray-800">
                    <div className="container mx-auto p-6 text-sm font-semibold">
                        <span className="text-blueGray-500">Copyright © {new Date().getFullYear()}</span>
                        <span className="text-white ml-1">Solivers</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;
