import React from 'react';

// ------------------------------------------- BADGE --------------------------------------------------- \\
const Badge = ({ label, color }) => {
    return (
        <div
            className="flex w-max rounded-full px-2 py-1 m-auto lg:m-0"
            style={{ backgroundColor: color }}
        >
            <b className="text-xs text-white uppercase">
                {label}
            </b>
        </div>
    );
};

export default Badge;
