import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Services
import { getFormattedDate, getLabel, getColor } from 'services/helpers';

// Assets
import { serviceTypes, serviceStatuses } from 'assets/constants';

// Components
import Button from 'components/button';
import Scrollbar from 'components/scrollbar';

// ------------------------------------------ SERVICES ------------------------------------------------ \\
const Services = ({ onClose, services }) => {
    return (
        <>
            <div className="px-3 py-6 w-full">
                {/* -------------------- HEADERS -------------------- */}
                <div className="flex items-center bg-blueGray-100 py-3 px-6">
                    <div className="w-2/12 text-xs uppercase font-semibold text-blueGray-500">
                        Statut
                    </div>
                    <div className="w-3/12 text-xs uppercase font-semibold text-blueGray-500">
                        Client
                    </div>
                    <div className="w-2/12 text-xs uppercase font-semibold text-blueGray-500">
                        Date
                    </div>
                    <div className="w-2/12 text-xs uppercase font-semibold text-blueGray-500">
                        Type
                    </div>
                    <div className="w-3/12 text-xs uppercase font-semibold text-blueGray-500">
                        Catégorie
                    </div>
                </div>
                {/* -------------------- LIST -------------------- */}
                <Scrollbar maxHeight={200}>
                    <ServiceList
                        services={services}
                    />
                </Scrollbar>
            </div>
            <div className="text-center p-4 border-t border-solid border-blueGray-200">
                <Button
                    label="fermer"
                    onClick={onClose}
                />
            </div>
        </>
    );
};

// ----------------------------------------- SERVICE LIST --------------------------------------------- \\
const ServiceList = ({ services }) => {
    const history = useHistory();

    return (
        <>
            {services.length > 0 ? (
                services.map(service => (
                    <Fragment key={service.id}>
                        <div
                            onClick={() => history.push('/app/services/' + service.id)}
                            className="flex items-center py-3 px-6 cursor-pointer hover:bg-gray-100"
                        >
                            <div className="w-2/12">
                                <div
                                    className="flex w-max rounded-full px-2 py-1"
                                    style={{ backgroundColor: getColor(serviceStatuses, service.status) }}
                                >
                                    <b className="text-xs text-white uppercase">
                                        {getLabel(serviceStatuses, service.status)}
                                    </b>
                                </div>
                            </div>
                            <div className="w-3/12">
                                <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                                <b className="text-sm text-blueGray-600">
                                    {service.client.name}
                                </b>
                            </div>
                            <div className="w-2/12">
                                <b className="text-sm text-blueGray-600">
                                    {getFormattedDate(service.date)}
                                </b>
                            </div>
                            <div className="w-2/12">
                                <b className="text-sm text-blueGray-600">
                                    {getLabel(serviceTypes, service.type)}
                                </b>
                            </div>
                            <div className="w-3/12">
                                <b className="text-sm text-blueGray-600">
                                    {service.serviceCategory.name}
                                </b>
                            </div>
                        </div>
                        <div className="border-t border-solid border-blueGray-100" />
                    </Fragment>
                ))
            ) : (
                <>
                    <div className="py-3 px-6">
                        <b className="text-sm text-blueGray-600">
                            Aucune
                        </b>
                    </div>
                    <div className="border-t border-solid border-blueGray-100" />
                </>
            )}
        </>
    );
};

export default Services;
