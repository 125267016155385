import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import ModalButtons from 'components/modal-buttons';
import Input from 'components/input';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const CREATE_PRODUCT_ORIGIN_CATEGORY = gql`
    mutation createProductOriginCategory($name: String!) {
        createProductOriginCategory(name: $name) {
            id
        }
    }
`;

// ---------------------------------------- CREATE PRODUCT ORIGIN CATEGORY --------------------------------------- \\
const CreateProductOriginCategory = ({ onClose, refetch }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        name: '',
    });

    const [createProductOriginCategory, { loading, error, data }] = useMutation(CREATE_PRODUCT_ORIGIN_CATEGORY);

    const onCreateProductOriginCategory = () => {
        if (state.name === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            createProductOriginCategory({ variables: { name: state.name } });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['product.origin.category.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        {/* --------------------- NAME --------------------- */}
                        <Input
                            value={state.name}
                            onChange={e => setState({ ...state, name: e.target.value })}
                            placeholder="Nom"
                            required
                        />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onCreateProductOriginCategory}
                    />
                </>
            )}
        </>
    );
};

export default CreateProductOriginCategory;
