import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import ProductCategoryForm from '../product-category-form';
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const UPDATE_PRODUCT_CATEGORY = gql`
    mutation UpdateProductCategory($id: ID!, $name: String!, $description: String, $productCategory: ConnectProductCategoryBelongsTo) {
        updateProductCategory(input: {
            id: $id,
            attributes: {
                name: $name,
                description: $description,
                parentProductCategory: $productCategory,
            }
        }) {
            id
        }
    }
`;

// ---------------------------------------- CREATE PRODUCT CATEGORY --------------------------------------- \\
const UpdateProductCategory = ({ onClose, refetch, productCategory }) => {
    const { addToast } = useToasts();

    const getParentProductCategory = () => {
        if (productCategory && productCategory.parentProductCategory) {
            return ({ value: productCategory.parentProductCategory.id, label: productCategory.parentProductCategory.name });
        }
        return null;
    };

    const [state, setState] = useState({
        name: productCategory.name || '',
        description: productCategory.description || '',
        productCategory: getParentProductCategory(),
    });

    const [updateProductCategory, { loading, error, data }] = useMutation(UPDATE_PRODUCT_CATEGORY);

    const onCreateExtraCategory = () => {
        if (state.name === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            updateProductCategory({
                variables: {
                    id: productCategory.id,
                    name: state.name,
                    description: state.description,
                    productCategory: state.productCategory ? { connect: state.productCategory.value } : null,
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['product.category.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <ProductCategoryForm state={state} setState={setState} />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onCreateExtraCategory}
                        labelRight="Enregistrer"
                    />
                </>
            )}
        </>
    );
};

export default UpdateProductCategory;
