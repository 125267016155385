import React from 'react';
import Select from 'react-select';

// ---------------------------------------- SELECT FILTER ------------------------------------------------ \\
const SelectFilter = ({ placeholder, value, onChange, options, isMulti, minWidth }) => {
    return (
        <Select
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            options={options}
            isMulti={isMulti}
            isClearable={true}
            noOptionsMessage={() => 'Aucun'}
            menuPortalTarget={document.body}
            maxMenuHeight={200}
            styles={{
                control: base => ({ ...base, fontSize: 14, outline: 'none', textDecoration: 'none' }),
                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                container: base => ({ ...base, minWidth: minWidth, width: '100%' }),
            }}
        />
    );
};

SelectFilter.defaultProps = {
    placeholder: "Sélectionner...",
    isMulti: false,
    minWidth: 200,
};

export default SelectFilter;
