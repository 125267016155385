import React, { useState, useEffect } from 'react';

// ---------------------------------------- PRODUCTS ------------------------------------------------ \\
const Products = ({ products }) => {
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        if (products != null && products.length > 0) {

            let newProductCategories = [];
            products.forEach(product => {
                newProductCategories.push(product.productCategory);
            });

            newProductCategories = [...new Set(newProductCategories)];
            setProductCategories(newProductCategories);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="border border-t border-blueGray-100" />
            <div className="p-6">
                <div className="py-2 text-center">
                    <b className="text-sm text-blueGray-500 uppercase">
                        Produits
                    </b>
                </div>
                {productCategories.map((productCategory, index) => (
                    <div
                        key={productCategory.id}
                        style={{ marginTop: index !== 0 ? 40 : 0 }}
                    >
                        <div className="text-xs font-bold uppercase mb-2">
                            {productCategory.name}
                        </div>
                        {/* -------------------- HEADERS -------------------- */}
                        <div className="flex items-center bg-blueGray-100 py-3 px-6">
                            <b className="text-xs uppercase font-semibold text-blueGray-500">
                                Nom
                            </b>
                        </div>
                        {/* -------------------- LIST -------------------- */}
                        {products
                            .filter(p => p.productCategory.id === productCategory.id)
                            .map((product, index) => (
                                <div
                                    key={index}
                                    className="flex items-center py-3 px-6 border-b border-solid border-blueGray-100"
                                >
                                    {/* -------------------- PRODUCT -------------------- */}
                                    <i className="fas fa-circle text-xxs text-blueGray-400 align-middle mr-2" />
                                    <b className="text-sm text-blueGray-600">
                                        {product.name}
                                    </b>
                                </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    );
};

export default Products
