import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import Products from './products';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';
import Modal from 'components/modal';

const ALLERGEN = gql`
    query Allergen($id: ID!) {
        allergen(id: $id) {
            id
            name
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
            products {
                id
                name
                status
                productCategory {
                    name
                }
            }
        }
    }
`;

// ------------------------------------------- ALLERGEN --------------------------------------------------- \\
const Allergen = () => {
    const history = useHistory();
    const { allergenId } = useParams();
    const [open, setOpen] = useState(false);

    const { loading, error, data } = useQuery(ALLERGEN, {
        variables: {
            id: allergenId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) return <Loading />;
    if (error || data.allergen == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex items-center p-3">
                    <div className="w-1/3">
                        <Button
                            label="Retour"
                            onClick={() => history.push('/app/allergens')}
                        />
                    </div>
                    <div className="w-1/3 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            {data.allergen.name}
                        </h3>
                    </div>
                </div>
                {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
                <div className="flex items-center justify-between bg-blueGray-100 p-3">
                    {data.allergen.creator && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Créateur : {data.allergen.creator.fullName}
                        </div>
                    )}
                    {data.allergen.lastModifier && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Dernière modification : {data.allergen.lastModifier.fullName}
                        </div>
                    )}
                </div>
                {/* -------------------- PRODUCTS -------------------- */}
                <div className="p-6">
                    <div className="flex items-center w-full py-1">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-600">Produits liés</b>
                        </div>
                        <div className="w-8/12">
                            {data.allergen.products && data.allergen.products.length > 0 ? (
                                <button
                                    onClick={() => setOpen(true)}
                                    type="button"
                                    className="border border-blueGray-400 px-3 py-1 rounded text-blueGray-400 text-sm font-semibold uppercase
                                        hover:text-white hover:bg-blueGray-400"
                                >
                                    Voir la liste
                                </button>
                            ) : (
                                <b className="text-sm text-blueGray-600">Aucun</b>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
            {/* --------------------------- PRODUCTS --------------------------- */}
            {open && (
                <Modal title={'Produits liés à l\'allergène : ' + data.allergen.name}>
                    <Products
                        onClose={() => setOpen(false)}
                        products={data.allergen.products}
                    />
                </Modal>
            )}
        </>
    );
};

export default Allergen;
