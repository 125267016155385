import React, { Fragment, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ReactTooltip from 'react-tooltip';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Moment
import moment from 'moment';

// Services
import { getFormattedDate } from 'services/helpers';

// Views
import Bundle from './bundle';
import CreateBundle from './create-bundle';
import DeleteBundle from './delete-bundle';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Error from 'components/error';
import Pagination from 'components/pagination';
import Modal from 'components/modal';
import DatePickerSearch from 'components/date-picker-search';

const BUNDLES = gql`
    query Bundles($startDate: String, $endDate: String, $first: Int!, $page: Int!) {
        bundles (startDate: $startDate, endDate: $endDate, first: $first, page: $page) {
            paginatorInfo {
                total
            }
            data {
                id
                startDate
                endDate
                bundleDays {
                    id
                    date
                }
                clientDescriptions {
                    id
                }
            }
        }
    }
`;

// ----------------------------------------- BUNDLE ROUTER ----------------------------------------------- \\
const BundleRouter = () => {
    return (
        <Switch>
            <Route exact path="/app/bundles">
                <Bundles />
            </Route>
            <Route exact path="/app/bundles/:bundleId">
                <Bundle />
            </Route>
        </Switch>
    );
};

// ------------------------------------------- BUNDLES --------------------------------------------------- \\
const Bundles = () => {
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    const [state, setState] = useState({
        page: 1,
        first: 20,
        startDate: null,
        endDate: null,
        searchStartDate: '',
        searchEndDate: '',
        bundle: null,
        openCreate: false,
        openUpdate: false,
        openDelete: false,
    });

    const { loading, error, data, refetch } = useQuery(BUNDLES, {
        variables: {
            first: state.first,
            page: state.page,
            startDate: state.searchStartDate,
            endDate: state.searchEndDate,
        },
        fetchPolicy: 'network-only',
    });

    const onSearch = () => {
        if (state.startDate != null && state.endDate != null && (state.startDate <= state.endDate)) {
            setState({
                ...state,
                searchStartDate: moment(state.startDate).format('YYYY-MM-DD'),
                searchEndDate: moment(state.endDate).format('YYYY-MM-DD'),
            });
        }
        else {
            setState({ ...state, searchStartDate: '', searchEndDate: '' });
        }
    };

    const onRefetch = () => {
        setState({
            ...state,
            openCreate: false,
            openUpdate: false,
            openDelete: false,
        });
        refetch();
    };

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex flex-col lg:flex-row items-center justify-between p-3">
                    <DatePickerSearch
                        startDate={state.startDate}
                        onChangeStartDate={startDate => setState({ ...state, startDate })}
                        endDate={state.endDate}
                        onChangeEndDate={endDate => setState({ ...state, endDate })}
                        onSearch={onSearch}
                    />
                    <div className="mt-2 lg:mt-0">
                        <Button
                            label="Créer une liasse"
                            onClick={() => setState({ ...state, openCreate: true })}
                        />
                    </div>
                </div>
                {/* -------------------- HEADERS -------------------- */}
                {mediaLG ? (
                    <div className="flex items-center bg-blueGray-100 text-xs uppercase font-semibold text-blueGray-500">
                        <div className="w-10/12 p-3">
                            Liasse
                        </div>
                        <div className="w-2/12 p-3 text-center">
                            Action
                        </div>
                    </div>
                ) : (
                    <div className="border-t border-blueGray-100" />
                )}
                {/* -------------------- LIST -------------------- */}
                <BundleList
                    loading={loading}
                    error={error}
                    data={data}
                    onRefetch={onRefetch}
                    state={state}
                    setState={setState}
                />
                {/* -------------------- PAGINATION -------------------- */}
                <Pagination
                    currentPage={state.page}
                    pageSize={state.first}
                    totalCount={data ? data.bundles.paginatorInfo.total : 0}
                    onChangePage={value => setState({ ...state, page: value })}
                />
            </Card>
            {/* --------------------------- CREATE --------------------------- */}
            {state.openCreate && (
                <Modal
                    title="Créer une liasse"
                    required
                >
                    <CreateBundle
                        onClose={() => setState({ ...state, openCreate: false })}
                        refetch={onRefetch}
                    />
                </Modal>
            )}
        </>
    );
};

// ------------------------------------------ BUNDLE LIST ------------------------------------------------ \\
const BundleList = ({ loading, error, data, onRefetch, state, setState }) => {
    const history = useHistory();

    const onClickDelete = (event, bundle) => {
        event.stopPropagation();
        setState({ ...state, openDelete: true, bundle });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {data.bundles.data.map((bundle, index) => (
                <Fragment key={bundle.id}>
                    {index !== 0 && (
                        <div className="border-t border-solid border-blueGray-100" />
                    )}
                    <div
                        onClick={() => history.push('/app/bundles/' + bundle.id)}
                        className="flex flex-wrap text-center lg:text-left items-center cursor-pointer hover:bg-gray-100"
                    >
                        <div className="w-full lg:w-10/12 px-3 py-2 lg:py-3">
                            <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                            <b className="text-sm text-blueGray-600">
                                Liasse du {getFormattedDate(bundle.startDate)} au {getFormattedDate(bundle.endDate)}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3 text-center">
                            <button
                                onClick={e => onClickDelete(e, bundle)}
                                data-tip="Supprimer"
                                data-for="btn-round-delete"
                                type="button"
                                className="h-7 w-7 rounded-full border border-solid outline-none focus:outline-none ease-linear transition-all duration-150
                                    bg-transparent border-blueGray-400 hover:border-blueGray-600 hover:bg-blueGray-600
                                    font-bold uppercase text-xs text-blueGray-400 hover:text-white ml-2"
                            >
                                <i className="fas fa-trash" />
                            </button>
                            <ReactTooltip
                                id="btn-round-delete"
                                delayShow={1000}
                                delayHide={100}
                                textColor="#fff"
                                backgroundColor="#3730A3"
                                effect="solid"
                            />
                        </div>
                    </div>
                </Fragment>
            ))}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer une liasse"
                >
                    <DeleteBundle
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={onRefetch}
                        bundle={state.bundle}
                    />
                </Modal>
            )}
        </>
    );
};

export default BundleRouter;
