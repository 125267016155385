import React, { Fragment, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Services
import { getLabel } from 'services/helpers';

// Assets
import { productionAreaTypes } from 'assets/constants';

// Views
import ProductionArea from './production-area';
import CreateProductionArea from './create-production-area';
import UpdateProductionArea from './update-production-area';
import DeleteProductionArea from './delete-production-area';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Error from 'components/error';
import Pagination from 'components/pagination';
import Modal from 'components/modal';
import UpdateDeleteButtons from 'components/update-delete-buttons';
import InputSearch from 'components/input-search';

const PRODUCTION_AREAS = gql`
    query ProductionAreas($name: String, $first: Int!, $page: Int!) {
        productionAreas(name: $name, first: $first, page: $page, orderBy: [{ column: NAME, order: ASC }]) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                type
                description
            }
        }
    }
`;

// -------------------------------------- PRODUCTION AREA ROUTER ------------------------------------------ \\
const ProductionAreaRouter = () => {
    return (
        <Switch>
            <Route exact path="/app/production-areas">
                <ProductionAreas />
            </Route>
            <Route exact path="/app/production-areas/:productionAreaId">
                <ProductionArea />
            </Route>
        </Switch>
    );
};

// ---------------------------------------- PRODUCTION AREAS ---------------------------------------------- \\
const ProductionAreas = () => {
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    const [state, setState] = useState({
        page: 1,
        first: 20,
        searchName: '',
        productionArea: null,
        openCreate: false,
        openUpdate: false,
        openDelete: false,
    });

    const { loading, error, data, refetch } = useQuery(PRODUCTION_AREAS, {
        variables: {
            first: state.first,
            page: state.page,
            name: '%' + state.searchName + '%',
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({
            ...state,
            openCreate: false,
            openUpdate: false,
            openDelete: false,
        });
        refetch();
    };

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex flex-col lg:flex-row items-center justify-between p-3">
                    <InputSearch
                        placeholder="Chercher une zone"
                        onSearch={searchName => setState({ ...state, page: 1, searchName })}
                    />
                    <div className="mt-2 lg:mt-0">
                        <Button
                            label="Ajouter une zone"
                            onClick={() => setState({ ...state, openCreate: true })}
                        />
                    </div>
                </div>
                {/* -------------------- HEADERS -------------------- */}
                {mediaLG ? (
                    <div className="flex items-center bg-blueGray-100 text-xs uppercase font-semibold text-blueGray-500">
                        <div className="w-6/12 p-3">
                            Nom
                        </div>
                        <div className="w-4/12 p-3">
                            Type
                        </div>
                        <div className="w-2/12 p-3 text-center">
                            Action
                        </div>
                    </div>
                ) : (
                    <div className="border-t border-blueGray-100" />
                )}
                {/* -------------------- LIST -------------------- */}
                <ProductionAreaList
                    loading={loading}
                    error={error}
                    data={data}
                    onRefetch={onRefetch}
                    state={state}
                    setState={setState}
                />
                {/* -------------------- PAGINATION -------------------- */}
                <Pagination
                    currentPage={state.page}
                    pageSize={state.first}
                    totalCount={data ? data.productionAreas.paginatorInfo.total : 0}
                    onChangePage={value => setState({ ...state, page: value })}
                />
            </Card>
            {/* --------------------------- CREATE --------------------------- */}
            {state.openCreate && (
                <Modal
                    title="Ajouter une zone de production"
                    required
                >
                    <CreateProductionArea
                        onClose={() => setState({ ...state, openCreate: false })}
                        refetch={onRefetch}
                    />
                </Modal>
            )}
        </>
    );
};

// --------------------------------------- PRODUCTION AREA LIST ------------------------------------------- \\
const ProductionAreaList = ({ loading, error, data, onRefetch, state, setState }) => {
    const history = useHistory();

    const onClick = (event, open, productionArea) => {
        event.stopPropagation();
        setState({ ...state, [open]: true, productionArea });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {data.productionAreas.data.map((productionArea, index) => (
                <Fragment key={productionArea.id}>
                    {index !== 0 && (
                        <div className="border-t border-solid border-blueGray-100" />
                    )}
                    <div
                        onClick={() => history.push('/app/production-areas/' + productionArea.id)}
                        className="flex flex-wrap text-center lg:text-left items-center cursor-pointer hover:bg-gray-100"
                    >
                        <div className="w-full lg:w-6/12 px-3 py-2 lg:py-3">
                            <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                            <b className="text-sm text-blueGray-600">
                                {productionArea.name}
                            </b>
                        </div>
                        <div className="w-full lg:w-4/12 px-3 py-2 lg:py-3">
                            <b className="text-sm text-blueGray-600">
                                {getLabel(productionAreaTypes, productionArea.type)}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3 text-center">
                            <UpdateDeleteButtons
                                onClickUpdate={e => onClick(e, 'openUpdate', productionArea)}
                                onClickDelete={e => onClick(e, 'openDelete', productionArea)}
                            />
                        </div>
                    </div>
                </Fragment>
            ))}
            {/* --------------------------- UPDATE --------------------------- */}
            {state.openUpdate && (
                <Modal
                    title="Modifier une zone de production"
                    required
                >
                    <UpdateProductionArea
                        onClose={() => setState({ ...state, openUpdate: false })}
                        refetch={onRefetch}
                        productionArea={state.productionArea}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer une zone de production"
                >
                    <DeleteProductionArea
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={onRefetch}
                        productionArea={state.productionArea}
                    />
                </Modal>
            )}
        </>
    );
};

export default ProductionAreaRouter;
