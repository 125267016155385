import React, { useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const DELETE_SERVICE_CATEGORY = gql`
    mutation DeleteServiceCategory($id: ID!) {
        deleteServiceCategory(id: $id) {
            id
        }
    }
`;

// ------------------------------------------- DELETE SERVICE CATEGORY ------------------------------------------- \\
const DeleteServiceCategory = ({ onClose, refetch, serviceCategory }) => {
    const { addToast } = useToasts();

    const [deleteServiceCategory, { loading, error, data }] = useMutation(DELETE_SERVICE_CATEGORY);

    const onDeleteServiceCategory = () => {
        deleteServiceCategory({ variables: { id: serviceCategory.id } });
    };

    useEffect(() => {
        if (data) {
            addToast('Catégorie de prestation supprimée', { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="text-center font-semibold text-base py-6">
                        <span>Êtes-vous sûr de vouloir supprimer cette catégorie de prestation ?</span>
                        <br />
                        <strong>{serviceCategory.name}</strong>
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onDeleteServiceCategory}
                        labelRight="Supprimer"
                    />
                </>
            )}
        </>
    );
};

export default DeleteServiceCategory;
