import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import Products from './products';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';
import Modal from 'components/modal';

const PRODUCT_ORIGIN = gql`
    query ProductOrigin($id: ID!) {
        productOrigin(id: $id) {
            id
            name
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
            productOriginCategory {
                id
                name
            }
            products {
                id
                name
                status
                productCategory {
                    name
                }
            }
        }
    }
`;

// ------------------------------------------- PRODUCT ORIGIN --------------------------------------------------- \\
const ProductOrigin = () => {
    const history = useHistory();
    const { productOriginId } = useParams();
    const [open, setOpen] = useState(false);

    const { loading, error, data } = useQuery(PRODUCT_ORIGIN, {
        variables: {
            id: productOriginId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) return <Loading />;
    if (error || data.productOrigin == null) return <Error />;

    return (
        <Card>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center p-3">
                <div className="w-1/3">
                    <Button
                        label="Retour"
                        onClick={() => history.push('/app/product-origins')}
                    />
                </div>
                <div className="w-1/3 text-center">
                    <h3 className="font-semibold text-base text-blueGray-700">
                        {data.productOrigin.name}
                    </h3>
                </div>
            </div>
            {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
            <div className="flex items-center justify-between bg-blueGray-100 p-3">
                {data.productOrigin.creator && (
                    <div className="text-xs uppercase font-semibold text-blueGray-500">
                        Créateur : {data.productOrigin.creator.fullName}
                    </div>
                )}
                {data.productOrigin.lastModifier && (
                    <div className="text-xs uppercase font-semibold text-blueGray-500">
                        Dernière modification : {data.productOrigin.lastModifier.fullName}
                    </div>
                )}
            </div>
            <div className="p-6">
                {/* -------------------- PRODUCT ORIGIN CATEGORY -------------------- */}
                <div className="flex items-center w-full py-2">
                    <div className="w-4/12">
                        <b className="text-sm text-blueGray-600">Catégorie de l'origine</b>
                    </div>
                    <div className="w-8/12">
                        <b className="text-sm text-blueGray-600">{data.productOrigin.productOriginCategory?.name}</b>
                    </div>
                </div>
                <div className="border border-t border-blueGray-100" />
                {/* -------------------- PRODUCTS -------------------- */}
                <div className="flex items-center w-full py-2">
                    <div className="w-4/12">
                        <b className="text-sm text-blueGray-600">Produits liés</b>
                    </div>
                    <div className="w-8/12">
                        {data.productOrigin.products && data.productOrigin.products.length > 0 ? (
                            <button
                                onClick={() => setOpen(true)}
                                type="button"
                                className="border border-blueGray-400 px-3 py-1 rounded text-blueGray-400 text-sm font-semibold uppercase
                                    hover:text-white hover:bg-blueGray-400"
                            >
                                Voir la liste
                            </button>
                        ) : (
                            <b className="text-sm text-blueGray-600">Aucun</b>
                        )}
                    </div>
                </div>
            </div>
            {/* --------------------------- PRODUCTS --------------------------- */}
            {open && (
                <Modal title={'Produits liés à l\'origine : ' + data.productOrigin.name}>
                    <Products
                        onClose={() => setOpen(false)}
                        products={data.productOrigin.products}
                    />
                </Modal>
            )}
        </Card>
    );
};

export default ProductOrigin;
