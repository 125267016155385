import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

// Apollo
import { gql, useQuery, useMutation } from '@apollo/client';

// Toast
import { useToasts } from 'react-toast-notifications';

// Views
import UserForm from '../users/user-form';
import UpdateUserPassword from '../users/update-user-password';

// Services
import { getStoredUser } from 'services/storage';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Modal from 'components/modal';

// Messages
import messages from 'messages.json';

const USER = gql`
    query User($id: ID!) {
        user(id: $id) {
            id
            fullName
            last_name
            first_name
            email
            mobile_phone
            phone
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
        }
    }
`;

const UPDATE_USER = gql`
    mutation updateUser(
        $id: ID!,
        $last_name: String!,
        $first_name: String!,
        $email: String!,
        $phone: String,
        $mobile_phone: String,
    ) {
        updateUser(input: {
            id: $id,
            last_name: $last_name,
            first_name: $first_name,
            email: $email,
            phone: $phone,
            mobile_phone: $mobile_phone,
        }) {
            id
        }
    }
`;

// -------------------------------------------- PROFILE ---------------------------------------------------- \\
const Profile = () => {
    const user = getStoredUser();
    const userId = user ? user.id : 0;

    const [state, setState] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        mobile_phone: '',
        openPassword: false,
    });

    const { loading, error, data } = useQuery(USER, {
        variables: {
            id: userId,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (data) {
            setState({
                ...state,
                first_name: data.user.first_name,
                last_name: data.user.last_name,
                email: data.user.email,
                phone: data.user.phone,
                mobile_phone: data.user.mobile_phone,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading) return <Loading />;
    if (error || data.user == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TITLE -------------------- */}
                <div className="w-full flex px-6 py-3 text-center">
                    <div className="w-1/3" />
                    <div className="w-1/3 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            Profil
                        </h3>
                    </div>
                    <div className="w-1/3 text-right">
                        <button
                            onClick={() => setState({ ...state, openPassword: true })}
                            data-tip="Changer le mot de passe"
                            data-for="btn-round-password"
                            type="button"
                            className="h-7 w-7 rounded-full border border-solid outline-none focus:outline-none ease-linear transition-all duration-150
                                bg-transparent border-blueGray-400 hover:border-blueGray-600 hover:bg-blueGray-600
                                font-bold uppercase text-xs text-blueGray-400 hover:text-white mr-2"
                        >
                            <i className="fas fa-unlock-alt" />
                        </button>
                        <ReactTooltip
                            id='btn-round-password'
                            delayShow={1000}
                            delayHide={100}
                            textColor='#fff'
                            backgroundColor='#3730A3'
                            effect='solid'
                        />
                    </div>
                </div>
                <div className="mb-2 border-b border-blueGray-100" />
                {/* -------------------- FORM -------------------- */}
                <div className="w-full flex flex-col lg:w-1/2 mx-auto py-3">
                    <UserForm
                        state={state}
                        setState={setState}
                    />
                </div>
                {/* -------------------- UPDATE -------------------- */}
                <UpdateProfile
                    state={state}
                    userId={userId}
                />
            </Card>
            {/* --------------------------- UPDATE PASSWORD --------------------------- */}
            {state.openPassword && (
                <Modal
                    title="Changer le mot de passe"
                    required
                >
                    <UpdateUserPassword
                        onClose={() => setState({ ...state, openPassword: false })}
                        refetch={() => setState({ ...state, openPassword: false })}
                        user={data.user}
                    />
                </Modal>
            )}
        </>
    );
};

// ----------------------------------------- UPDATE PROFILE ------------------------------------------------- \\
const UpdateProfile = ({ state, userId }) => {
    const { addToast } = useToasts();
    const [updateUser, { loading, error, data }] = useMutation(UPDATE_USER);

    const onUpdateUser = () => {
        if (state.first_name === '' || state.last_name === '' || state.email === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            updateUser({
                variables: {
                    id: userId,
                    last_name: state.last_name,
                    first_name: state.first_name,
                    email: state.email,
                    phone: state.phone,
                    mobile_phone: state.mobile_phone,
                }
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['user.saved'], { appearance: 'success' });
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <div className="py-3 text-center border-t border-solid border-blueGray-100">
            <button
                onClick={onUpdateUser}
                type="submit"
                className="bg-indigo-500 hover:bg-green-600 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150
                    text-white text-xs"
            >
                {loading ? 'En cours...' : 'Enregistrer'}
            </button>
        </div>
    );
};

export default Profile;
