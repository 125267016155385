import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Moment
import moment from 'moment';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Services
import { getColor, getLabel } from 'services/helpers';

// Assets
import { serviceCategoryStatuses } from 'assets/constants';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';
import PhotoDisplayer from 'components/photo-displayer';
import PhotoSelector from 'components/photo-selector';
import Modal from 'components/modal';
import UpdateDeleteButtons from 'components/update-delete-buttons';

// Views
import Products from './products';
import AddPhotoToServiceCategory from './upload-service-category-photo';
import UpdateServiceCategory from '../update-service-category';
import DeleteServiceCategory from '../delete-service-category';

const SERVICE_CATEGORY = gql`
    query ServiceCategory($id: ID!) {
        serviceCategory(id: $id) {
            id
            name
            photo
            start_date
            end_date
            status
            number
            priority
            color
            description
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
            products {
                id
                name
                productCategory {
                    id
                    name
                }
            }
        }
    }
`;

// ------------------------------------------- SERVICE CATEGORY --------------------------------------------------- \\
const ServiceCategory = () => {
    const history = useHistory();
    const { serviceCategoryId } = useParams();

    const [state, setState] = useState({
        openUpdate: false,
        openDelete: false,
        openPhoto: false,
    });

    const { loading, error, data, refetch } = useQuery(SERVICE_CATEGORY, {
        variables: {
            id: serviceCategoryId,
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({ ...state, openPhoto: false, openUpdate: false });
        refetch();
    };

    if (loading) return <Loading />;
    if (error || data.serviceCategory == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex items-center p-3">
                    <div className="w-1/3">
                        <Button
                            label="Retour"
                            onClick={() => history.push('/app/service-categories')}
                        />
                    </div>
                    <div className="w-1/3 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            {data.serviceCategory.name}
                        </h3>
                    </div>
                    <div className="w-1/3 text-right">
                        <UpdateDeleteButtons
                            onClickUpdate={() => setState({ ...state, openUpdate: true })}
                            onClickDelete={() => setState({ ...state, openDelete: true })}
                            tooltipPlace="bottom"
                        />
                    </div>
                </div>
                {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
                <div className="flex items-center justify-between bg-blueGray-100 p-3">
                    {data.serviceCategory.creator && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Créateur : {data.serviceCategory.creator?.fullName}
                        </div>
                    )}
                    {data.serviceCategory.lastModifier && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Dernière modification : {data.serviceCategory.lastModifier?.fullName}
                        </div>
                    )}
                </div>
                <div className="flex flex-col lg:flex-row items-center p-6">
                    {/* -------------------- INFOS -------------------- */}
                    <div className="w-12/12 lg:w-9/12">
                        {/* -------------------- STATUS -------------------- */}
                        {data.serviceCategory.status && (
                            <>
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Statut</b>
                                    </div>
                                    <div className="w-8/12">
                                        <div
                                            className="flex w-min rounded-full px-2 py-1"
                                            style={{ backgroundColor: getColor(serviceCategoryStatuses, data.serviceCategory.status) }}
                                        >
                                            <b className="text-xs text-white uppercase">
                                                {getLabel(serviceCategoryStatuses, data.serviceCategory.status)}
                                            </b>
                                        </div>
                                    </div>
                                </div>
                                <div className="border border-t border-blueGray-100" />
                            </>
                        )}
                        {/* -------------------- DATE -------------------- */}
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-500">Date</b>
                            </div>
                            <div className="w-8/12">
                                <b className="text-sm text-blueGray-700">
                                    {moment(data.serviceCategory.start_date).format('DD MMMM')}
                                    &nbsp;au&nbsp;
                                    {moment(data.serviceCategory.end_date).format('DD MMMM')}
                                </b>
                            </div>
                        </div>
                        <div className="border border-t border-blueGray-100" />
                        {/* -------------------- PRIORITY -------------------- */}
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-500">Priorité</b>
                            </div>
                            <div className="w-8/12">
                                <b className="text-sm text-blueGray-700">
                                    {data.serviceCategory.priority}
                                </b>
                            </div>
                        </div>
                        <div className="border border-t border-blueGray-100" />
                        {/* -------------------- COLOR -------------------- */}
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-500">Couleur</b>
                            </div>
                            <div className="w-8/12">
                                {data.serviceCategory.color && (
                                    <div
                                        className="rounded h-7 w-7 border border-blueGray-300"
                                        style={{ backgroundColor: data.serviceCategory.color }}
                                    />
                                )}
                            </div>
                        </div>
                        {/* -------------------- NUMBER -------------------- */}
                        {data.serviceCategory.number && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Nombre de produit par personne</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.serviceCategory.number}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* -------------------- DESCRIPTION -------------------- */}
                        {data.serviceCategory.description && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Description</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.serviceCategory.description}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* -------------------- PHOTO -------------------- */}
                    <div className="w-12/12 lg:w-3/12 flex items-center justify-center">
                        {(data.serviceCategory.photo == null || data.serviceCategory.photo === '') ? (
                            <PhotoSelector
                                onClick={() => setState({ ...state, openPhoto: true })}
                            />
                        ) : (
                            <PhotoDisplayer
                                onClick={() => setState({ ...state, openPhoto: true })}
                                fileUrl={data.serviceCategory.photo}
                            />
                        )}
                    </div>
                </div>
                {/* -------------------- SERVICE PRODUCTS -------------------- */}
                {(data.serviceCategory.products != null && data.serviceCategory.products.length > 0) && (
                    <Products
                        products={data.serviceCategory.products}
                    />
                )}
            </Card>
            {/* --------------------------- PHOTO --------------------------- */}
            {state.openPhoto && (
                <Modal
                    title="Ajouter une photo"
                    required
                >
                    <AddPhotoToServiceCategory
                        onClose={() => setState({ ...state, openPhoto: false })}
                        refetch={onRefetch}
                        serviceCategoryId={serviceCategoryId}
                    />
                </Modal>
            )}
            {/* --------------------------- UPDATE --------------------------- */}
            {state.openUpdate && (
                <Modal
                    title="Modifier une catégorie de prestation"
                    required
                >
                    <UpdateServiceCategory
                        onClose={() => setState({ ...state, openUpdate: false })}
                        refetch={onRefetch}
                        serviceCategory={data.serviceCategory}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer une catégorie de prestation"
                >
                    <DeleteServiceCategory
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={() => history.push('/app/service-categories')}
                        serviceCategory={data.serviceCategory}
                    />
                </Modal>
            )}
        </>
    );
};

export default ServiceCategory;
