import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Apollo Client
import { ApolloProvider } from '@apollo/client';
import client from 'services/apollo-config';

// Toast Notifications
import { ToastProvider } from 'react-toast-notifications';

// CSS
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Main Component : App
import App from './app';

ReactDOM.render(
    <ApolloProvider client={client}>
        <BrowserRouter>
            <ToastProvider
                autoDismiss
                autoDismissTimeout={8000}
                placement="bottom-right"
            >
                <App />
            </ToastProvider>
        </BrowserRouter>
    </ApolloProvider>,
  	document.getElementById('root')
);
