import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Services
import { isAuthenticated } from 'services/storage';

// Components
import Auth from 'views/auth';
import LoggedApp from 'views/app';

// ------------------------------------------- APP --------------------------------------------------- \\
const App = () => {
    return (
        <Switch>
            <Route path="/auth">
                <Auth />
            </Route>
            <PrivateRoute path="/app">
                <LoggedApp />
            </PrivateRoute>
            <Redirect from="*" to="/auth" />
        </Switch>
    );
};

// ---------------------------------------- PRIVATE ROUTE -------------------------------------------- \\
function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated() ? (
                    children
                ) : (
                    <Redirect to={{ pathname: '/auth', state: { from: location } }} />
                )
            }
        />
    );
}

export default App;
