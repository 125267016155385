import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import ProductCategoryForm from '../product-category-form';
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const CREATE_PRODUCT_CATEGORY = gql`
    mutation CreateProductCategory($name: String!, $description: String, $productCategory: ConnectProductCategoryBelongsTo) {
        createProductCategory(input: {
            name: $name,
            description: $description,
            parentProductCategory: $productCategory,
        }) {
            id
        }
    }
`;

// ---------------------------------------- CREATE PRODUCT CATEGORY --------------------------------------- \\
const CreateProductCategory = ({ onClose, refetch, productCategory }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        name: '',
        description: '',
        productCategory: productCategory || null,
        isDisabled: productCategory ? true : false,
    });

    const [createProductCategory, { loading, error, data }] = useMutation(CREATE_PRODUCT_CATEGORY);

    const onCreateExtraCategory = () => {
        if (state.name === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            createProductCategory({
                variables: {
                    name: state.name,
                    description: state.description,
                    productCategory: state.productCategory ? { connect: state.productCategory.value } : null,
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['product.category.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <ProductCategoryForm state={state} setState={setState} />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onCreateExtraCategory}
                    />
                </>
            )}
        </>
    );
};

export default CreateProductCategory;
