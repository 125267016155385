import React from 'react';

// Views
import ServiceProductForm from './service-product-form';
import { productIsOffSeason } from '../off-season';

// Components
import SelectProduct from 'components/autocomplete/select-product';
import Button from 'components/button';

// ---------------------------------------- SEARCH PRODUCT BY NAME --------------------------------------- \\
const SearchProductByName = ({ date, state, setState }) => {
    return (
        <>
            {/* -------------------- GO BACK -------------------- */}
            <div className="mb-4">
                <Button
                    label="Retour"
                    onClick={() => setState({ ...state, page: 'research-choice' })}
                />
            </div>
            {/* -------------------- SEARCH PRODUCT -------------------- */}
            <div className="mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Produit
                    <span className="text-base text-indigo-700 ml-1">*</span>
                </label>
                {(state.product && productIsOffSeason(date, state.product)) && (
                    <span className="text-blueGray-600 text-xs font-bold ml-2 mb-2">
                        (Produit hors période)
                    </span>  
                )}
                <SelectProduct
                    product={state.product}
                    setProduct={product => setState({ ...state, product })}
                    offSeason={(state.product && productIsOffSeason(date, state.product))}
                />
            </div>
            {/* -------------------- SERVICE PRODUCT FORM -------------------- */}
            <ServiceProductForm
                state={state}
                setState={setState}
            />
        </>
    );
};

export default SearchProductByName;
