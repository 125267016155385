import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Views
import Menu from './menu';
import Profile from './profile';
import Header from './header';
import Services from './prestations';
import Bundles from './bundles';
import Clients from './clients';
import Products from './products';
import ProductCategories from './product-categories';
import ProductOrigins from './product-origins';
import ProductOriginCategories from './product-origin-categories';
import Complements from './complements';
import Allergens from './allergens';
import ProductionAreas from './production-areas';
import Extras from './extras';
import ExtraCategories from './extra-categories';
import ServiceCategories from './service-categories';
import DescriptionModels from './description-models';
import Users from './users';

// ------------------------------------------- LOGGED APP --------------------------------------------------- \\
const LoggedApp = () => {
    return (
        <>
            {/* -------------------- MENU -------------------- */}
            <Menu />
            {/* -------------------- CONTAINER -------------------- */}
            <div id="main-container" className="flex-1 margin-container z-0 bg-gradient-to-t from-indigo-200 bg-white">
                <Header />
                <Switch>
                    <Route path="/app/profile">
                        <Profile />
                    </Route>
                    <Route path="/app/services">
                        <Services />
                    </Route>
                    <Route path="/app/bundles">
                        <Bundles />
                    </Route>
                    <Route path="/app/clients">
                        <Clients />
                    </Route>
                    <Route path="/app/products">
                        <Products />
                    </Route>
                    <Route path="/app/product-categories">
                        <ProductCategories />
                    </Route>
                    <Route path="/app/product-origins">
                        <ProductOrigins />
                    </Route>
                    <Route path="/app/product-origin-categories">
                        <ProductOriginCategories />
                    </Route>
                    <Route path="/app/complements">
                        <Complements />
                    </Route>
                    <Route path="/app/allergens">
                        <Allergens />
                    </Route>
                    <Route path="/app/extras">
                        <Extras />
                    </Route>
                    <Route path="/app/extra-categories">
                        <ExtraCategories />
                    </Route>
                    <Route path="/app/production-areas">
                        <ProductionAreas />
                    </Route>
                    <Route path="/app/description-models">
                        <DescriptionModels />
                    </Route>
                    <Route path="/app/service-categories">
                        <ServiceCategories />
                    </Route>
                    <Route path="/app/users">
                        <Users />
                    </Route>
                    <Redirect from="*" to="/app/services" />
                </Switch>
            </div>
        </>
    );
};

export default LoggedApp;
