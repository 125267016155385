import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Moment
import moment from 'moment';

// Toast
import { useToasts } from 'react-toast-notifications';

// Views
import ServiceForm from './service-form';
import ServiceProductForm from './service-product-form';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const CREATE_SERVICE = gql`
    mutation CreateService(
        $date: Date!
        $type: ServiceType!
        $start_time: String
        $note: String
        $guests_number: Int
        $place: String
        $status: ServiceStatus
        $client: ConnectClientBelongsTo!
        $serviceCategory: ConnectServiceCategoryBelongsTo!
        $serviceProducts: CreateServiceProductHasMany
        $extras: SyncExtraBelongsToMany
    ) {
        createService(input: {
            date: $date
            type: $type
            start_time: $start_time
            note: $note
            guests_number: $guests_number
            place: $place
            status: $status
            client: $client
            serviceCategory: $serviceCategory
            serviceProducts: $serviceProducts
            extras: $extras
        }) {
            id
        }
    }
`;

// ----------------------------------------- CREATE SERVICE ----------------------------------------------- \\
const CreateService = () => {
    const history = useHistory();
    const { addToast } = useToasts();
    const [createService, { loading, error, data }] = useMutation(CREATE_SERVICE);

    const [state, setState] = useState({
        actionType: 'CREATE',
        page: 1,
        openAddServiceProduct: false,
        openUpdateProductService: false,

        // Service Product ID
        serviceProductId: 'SP1',
        serviceProduct: null,

        // Service Fields
        date: new Date(),
        type: null,
        start_time: '',
        note: '',
        guests_number: '',
        place: '',
        status: null,
        client: null,
        serviceCategory: null,
        serviceProducts: [],
        extras: [],
    });

    const onCreate = () => {
        if (state.date == null
            || state.client == null
            || state.type == null
            || state.serviceCategory == null
        ) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {

            let finalServiceProducts = state.serviceProducts.map(serviceProduct => ({
                quantity: Number(serviceProduct.quantity),
                unit: serviceProduct.unit?.value,
                note: serviceProduct.note,
                quantity_modality: serviceProduct.quantity_modality ? serviceProduct.quantity_modality.value : null,
                product: { connect: serviceProduct.product?.value },
            }));

            createService({
                variables: {
                    date: moment(state.date).format('YYYY-MM-DD'),
                    client: { connect: state.client.value },
                    type: state.type.value,
                    serviceCategory: { connect: state.serviceCategory.value },
                    status: state.status ? state.status.value : null,
                    start_time: state.start_time !== '' ? state.start_time : null,
                    guests_number: state.guests_number ? Number(state.guests_number) : null,
                    place: state.place,
                    serviceProducts: { create: finalServiceProducts },
                    extras: { sync: state.extras ? state.extras.map(e => e.value) : [] },
                    note: state.note,
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['service.saved'], { appearance: 'success' });
            history.push('/app/services');
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <Card>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center py-3 px-6">
                <div className="w-1/3">
                    <Button
                        label="Retour"
                        onClick={() => history.push('/app/services')}
                    />
                </div>
                <div className="w-1/3 text-center">
                    <h3 className="font-semibold text-base text-blueGray-700">
                        Ajouter une prestation
                    </h3>
                </div>
                <div className="w-1/3 text-right">
                    <span className="text-sm text-indigo-300 font-semibold">
                        <i className="fas fa-star-of-life text-xs mr-2" />
                        Requis
                    </span>
                </div>
            </div>
            {/* -------------------- NAV -------------------- */}
            <div className="flex w-full">
                <div
                    onClick={() => setState({ ...state, page: 1 })}
                    className={(state.page === 1 && 'bg-blueGray-100') + ' w-1/2 px-3 py-2 border border-blueGray-100 cursor-pointer text-center hover:bg-blueGray-200'}
                >
                    <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                        Informations
                    </label>
                </div>
                <div
                    onClick={() => setState({ ...state, page: 2 })}
                    className={(state.page === 2 && 'bg-blueGray-100') + ' w-1/2 px-3 py-2 border border-blueGray-100 cursor-pointer text-center hover:bg-blueGray-200'}
                >
                    <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                        Produits
                    </label>
                </div>
            </div>
            {/* -------------------- FORM -------------------- */}
            <div className="flex flex-col w-full px-6 py-3">
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {state.page === 1 && (
                            <ServiceForm
                                state={state}
                                setState={setState}
                            />
                        )}
                        {state.page === 2 && (
                            <ServiceProductForm
                                state={state}
                                setState={setState}
                            />
                        )}
                    </>
                )}
            </div>
            {/* -------------------- SAVE -------------------- */}
            <div className="w-full px-6 py-3 text-center border-t border-blueGray-100">
                <button
                    onClick={onCreate}
                    type="button"
                    className="bg-indigo-500 hover:bg-green-600 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150
                        text-white text-xs"
                >
                    Sauvegarder
                </button>
            </div>
        </Card>
    );
};

export default CreateService;
