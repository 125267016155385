import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Services
import { getLabel, getColor } from 'services/helpers';

// Assets
import { productStatuses } from 'assets/constants';

// Views
import DeleteProduct from '../delete-product';
import AddPhotoToProduct from './upload-product-photo';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';
import Modal from 'components/modal';
import PhotoDisplayer from 'components/photo-displayer';
import PhotoSelector from 'components/photo-selector';
import UpdateDeleteButtons from 'components/update-delete-buttons';

const PRODUCT = gql`
    query Product($id: ID!) {
        product(id: $id) {
            id
            name
            status
            description
            photo
            productCategory {
                id
                name
            }
            allergens {
                id
                name
            }
            serviceCategories {
                id
                name
            }
            productOrigins {
                id
                name
            }
            complements {
                id
                name
            }
            productionAreaProducts {
                name
                productionArea {
                    id
                    name
                }
            }
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
        }
    }
`;

// ------------------------------------------- PRODUCT --------------------------------------------------- \\
const Product = () => {
    const history = useHistory();
    const { productId } = useParams();

    const [state, setState] = useState({
        openDelete: false,
        openPhoto: false,
    });

    const { loading, error, data, refetch } = useQuery(PRODUCT, {
        variables: {
            id: productId,
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({ ...state, openPhoto: false });
        refetch();
    };

    if (loading) return <Loading />;
    if (error || data.product == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex items-center p-3">
                    <div className="w-1/3">
                        <Button
                            label="Retour"
                            onClick={() => history.goBack()}
                        />
                    </div>
                    <div className="w-1/3 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            {data.product.name}
                        </h3>
                    </div>
                    <div className="w-1/3 text-right">
                        <UpdateDeleteButtons
                            onClickUpdate={() => history.push('/app/products/update/' + productId)}
                            onClickDelete={() => setState({ ...state, openDelete: true })}
                            tooltipPlace="bottom"
                        />
                    </div>
                </div>
                {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
                <div className="flex items-center justify-between bg-blueGray-100 p-3">
                    {data.product.creator && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Créateur : {data.product.creator.fullName}
                        </div>
                    )}
                    {data.product.lastModifier && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Dernière modification : {data.product.lastModifier.fullName}
                        </div>
                    )}
                </div>
                <div className="flex flex-col lg:flex-row items-center p-6">
                    {/* -------------------- INFOS -------------------- */}
                    <div className="w-12/12 lg:w-9/12">
                        {/* -------------------- STATUS -------------------- */}
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-500">Statut</b>
                            </div>
                            <div className="w-8/12">
                                <div
                                    className="flex w-max rounded-full px-2 py-1"
                                    style={{ backgroundColor: getColor(productStatuses, data.product.status) }}
                                >
                                    <b className="text-xs text-white uppercase">
                                        {getLabel(productStatuses, data.product.status)}
                                    </b>
                                </div>
                            </div>
                        </div>
                        {/* -------------------- PRODUCT CATEGORY -------------------- */}
                        <div className="border border-t border-blueGray-100" />
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-500">Catégorie du produit</b>
                            </div>
                            <div className="w-8/12">
                                <b className="text-sm text-blueGray-700">
                                    {data.product.productCategory?.name}
                                </b>
                            </div>
                        </div>
                        {/* -------------------- PRODUCT ORIGINS -------------------- */}
                        {(data.product.productOrigins && data.product.productOrigins.length > 0) && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Origines du produit</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.product.productOrigins.map((o, index) => {
                                                if (index === 0) return o.name;
                                                return ', ' + o.name;
                                            })}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* -------------------- COMPLEMENTS -------------------- */}
                        {(data.product.complements && data.product.complements.length > 0) && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Compléments</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.product.complements.map((c, index) => {
                                                if (index === 0) return c.name;
                                                return ', ' + c.name;
                                            })}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* -------------------- ALLERGENS -------------------- */}
                        {(data.product.allergens && data.product.allergens.length > 0) && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Allergènes</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.product.allergens.map((a, index) => {
                                                if (index === 0) return a.name;
                                                return ', ' + a.name;
                                            })}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* -------------------- SERVICE CATEGORIES -------------------- */}
                        {(data.product.serviceCategories && data.product.serviceCategories.length > 0) && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Catégories de prestation</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.product.serviceCategories.map((c, index) => {
                                                if (index === 0) return c.name;
                                                return ', ' + c.name;
                                            })}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* -------------------- PRODUCTION AREA PRODUCTS -------------------- */}
                        {(data.product.productionAreaProducts && data.product.productionAreaProducts.length > 0) && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Zones de production</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.product.productionAreaProducts.map((pap, index) => {
                                                if (index === 0) return pap.productionArea?.name + ' (' + pap.name + ')';
                                                return ', ' + pap.productionArea?.name + ' (' + pap.name + ')';
                                            })}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* -------------------- DESCRIPTION -------------------- */}
                        {data.product.description && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Description</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.product.description}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* -------------------- PHOTO -------------------- */}
                    <div className="w-12/12 lg:w-3/12 flex items-center justify-center">
                        {(data.product.photo == null || data.product.photo === '') ? (
                            <PhotoSelector
                                onClick={() => setState({ ...state, openPhoto: true })}
                            />
                        ) : (
                            <PhotoDisplayer
                                onClick={() => setState({ ...state, openPhoto: true })}
                                fileUrl={data.product.photo}
                            />
                        )}
                    </div>
                </div>
            </Card>
            {/* --------------------------- PHOTO --------------------------- */}
            {state.openPhoto && (
                <Modal
                    title="Ajouter une photo"
                    required
                >
                    <AddPhotoToProduct
                        onClose={() => setState({ ...state, openPhoto: false })}
                        refetch={onRefetch}
                        productId={productId}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer un produit"
                >
                    <DeleteProduct
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={() => history.push('/app/products')}
                        product={data.product}
                    />
                </Modal>
            )}
        </>
    );
};

export default Product;
