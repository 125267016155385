import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Images
import LogoAVS from 'assets/img/logo-avs.png';

// --------------------------------------- MENU CONTAINER ------------------------------------------------- \\
const MenuContainer = () => {
    const mediaLG = useMediaQuery({ minWidth: 1024 });
    const [open, setOpen] = useState(false);

    return (
        <div className={`fixed top-0 z-50 bg-white width-menu lg:bottom-0 ${open ? 'bottom-0': null} p-6 shadow-xl overflow-auto`}>
            <div className="flex items-center justify-between">
                {/* -------------------- LOGO AVS -------------------- */}
                <div className="flex items-center">
                    <b className="uppercase text-gray-600">
                        Portail AVS
                    </b>
                    <img alt='logo-avs' src={LogoAVS} className='h-8 w-8' />
                </div>
                {/* -------------------- OPEN MENU BURGER -------------------- */}
                {!open && (
                    <button
                        onClick={() => setOpen(true)}
                        type="button"
                        className="lg:hidden cursor-pointer text-black opacity-50 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    >
                        <i className="fas fa-bars" />
                    </button>
                )}
                {/* -------------------- CLOSE MENU BURGER -------------------- */}
                {open && (
                    <button
                        onClick={() => setOpen(false)}
                        type="button"
                        className="lg:hidden cursor-pointer text-black opacity-50 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    >
                        <i className="fas fa-times" />
                    </button>
                )}
            </div>
            {/* -------------------- MENU -------------------- */}
            {(mediaLG || open) && (
                <Menu setOpen={setOpen} />
            )}
        </div>
    );
};

// -------------------------------------------- MENU ------------------------------------------------------ \\
const Menu = ({ setOpen }) => {
    const location = useLocation();
    const [path, setPath] = useState('');

    useEffect(() => {
        setPath(location.pathname.split('/')[2]);
    }, [location]);

    return (
        <div>
            <hr className="my-6 min-w-full" />
            {/* -------------------- GLOBAL -------------------- */}
            <div className="text-blueGray-500 text-xs uppercase font-bold pb-6">
                Général
            </div>
            {/* -------------------- SERVICES -------------------- */}
            <CustomizedLink
                pathname="services"
                title="Prestations"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-table"
            />
            {/* -------------------- BUNDLES -------------------- */}
            <CustomizedLink
                pathname="bundles"
                title="Liasses"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-book"
            />
            {/* -------------------- CLIENTS -------------------- */}
            <CustomizedLink
                title="Clients"
                pathname="clients"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-user-tie"
            />
            <hr className="mt-3 mb-6 min-w-full" />
            {/* -------------------- MANAGEMENT -------------------- */}
            <div className="text-blueGray-500 text-xs uppercase font-bold pb-6">
                Gestion
            </div>
            {/* -------------------- PRODUCTS -------------------- */}
            <CustomizedLink
                title="Produits"
                pathname="products"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-apple-alt"
            />
            {/* -------------------- PRODUCT CATEGORIES -------------------- */}
            <CustomizedLink
                title="Catégories de produit"
                pathname="product-categories"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-box-open"
            />
            {/* -------------------- PRODUCT ORIGINS -------------------- */}
            <CustomizedLink
                title="Origines de produit"
                pathname="product-origins"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-map-marker-alt"
            />
            {/* -------------------- PRODUCT ORIGIN CATEGORIES -------------------- */}
            <CustomizedLink
                title="Catégories des origines de produit"
                pathname="product-origin-categories"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-box-open"
            />
            {/* -------------------- COMPLEMENTS -------------------- */}
            <CustomizedLink
                title="Compléments"
                pathname="complements"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-plus-circle"
            />
            {/* -------------------- ALLERGENS -------------------- */}
            <CustomizedLink
                title="Allergènes"
                pathname="allergens"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-exclamation-circle"
            />
            {/* -------------------- EXTRAS -------------------- */}
            <CustomizedLink
                title="Extras"
                pathname="extras"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-cocktail"
            />
            {/* -------------------- EXTRA CATEGORIES -------------------- */}
            <CustomizedLink
                title="Catégories d'extra"
                pathname="extra-categories"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-box-open"
            />
            {/* -------------------- DESCRIPTION MODELS -------------------- */}
            <CustomizedLink
                title="Modèles de descriptif"
                pathname="description-models"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-book-reader"
            />
            {/* -------------------- PRODUCION AREAS -------------------- */}
            <CustomizedLink
                title="Zones de production"
                pathname="production-areas"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-shapes"
            />
            {/* -------------------- SERVICE CATEGORIES -------------------- */}
            <CustomizedLink
                title="Catégories de prestation"
                pathname="service-categories"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-box-open"
            />
            {/* -------------------- USERS -------------------- */}
            <CustomizedLink
                title="Utilisateurs"
                pathname="users"
                selectedPath={path}
                setOpen={setOpen}
                icon="fa-user-astronaut"
            />
        </div>
    );
};

// ---------------------------------------- CUSTOMIZED LINK ----------------------------------------------- \\
const CustomizedLink = ({ selectedPath, setOpen, pathname, title, icon }) => {
    const history = useHistory();
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    const onNavigate = () => {
        if (!mediaLG) {
            setOpen(false);
        }
        history.push('/app/' + pathname);
    };

    return (
        <div
            onClick={onNavigate}
            className={'cursor-pointer text-xs uppercase mb-5 font-bold block flex items-center hover:text-indigo-500 ' + (
                selectedPath === pathname
                    ? 'text-indigo-500'
                    : 'text-blueGray-500'
            )}>
            <div className="w-6 h-6 flex items-center justify-center mr-2">
                <i className={`fas ${icon} text-xl opacity-75`} />
            </div>
            {title}
        </div>
    );
};

export default MenuContainer;
