// --------------------------------------------------------------- \\
// --------------------------- TOKEN ----------------------------- \\
// --------------------------------------------------------------- \\

export const setToken = (data, remember) => {
    if (remember) {
        localStorage.setItem('token', data.login.token);
        localStorage.setItem('user', JSON.stringify(data.login.user));
    }
    else {
        sessionStorage.setItem('token', data.login.token);
        sessionStorage.setItem('user', JSON.stringify(data.login.user));
    }
};

export const getToken = () => {
    return sessionStorage.getItem('token') || localStorage.getItem('token');
};

export const clearToken = () => {
    sessionStorage.clear();
    localStorage.clear();
};

// --------------------------------------------------------------- \\
// ------------------------- AUTHENTICATION ---------------------- \\
// --------------------------------------------------------------- \\

export const isAuthenticated = () => {
    if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
        return true;
    }
    return false;
};

// --------------------------------------------------------------- \\
// ------------------------------ USER --------------------------- \\
// --------------------------------------------------------------- \\

export const getStoredUser = () => {
    return JSON.parse(sessionStorage.getItem('user')) || JSON.parse(localStorage.getItem('user'));
};
