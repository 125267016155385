import React, { useState, createRef } from 'react';

// Popper
import { createPopper } from '@popperjs/core';

// ------------------------------------------- POPOVER BUTTON --------------------------------------------------- \\
const PopoverButton = ({ icon, title, text }) => {
    const [popoverShow, setPopoverShow] = useState(false);
    const btnRef = createRef();
    const popoverRef = createRef();

    const openPopover = () => {
        createPopper(btnRef.current, popoverRef.current, {
            placement: 'top'
        });
        setPopoverShow(true);
    };

    const closePopover = () => {
        setPopoverShow(false);
    };

    return (
        <>
            <button
                ref={btnRef}
                onMouseOver={() => { popoverShow ? closePopover() : openPopover() }}
                onMouseOut={() => { popoverShow ? closePopover() : openPopover() }}
                type="button"
                className="h-5 w-5 rounded-full border border-solid ml-2 outline-none focus:outline-none ease-linear transition-all duration-150
                    bg-transparent border-blueGray-400 hover:border-blueGray-600 hover:bg-blueGray-600
                    font-bold uppercase text-xxs text-blueGray-400 hover:text-white text-center"
            >
                <i className={`${icon}`} />
            </button>
            <div
                ref={popoverRef}
                className={(popoverShow ? '' : 'hidden') + ' flex flex-col flex-wrap bg-indigo-100 text-white font-semibold text-sm rounded max-w-sm shadow-lg'}
            >
                <div>
                    <div className="bg-indigo-200 text-blueGray-600 opacity-55 p-3 font-semibold mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t w-full">
                        {title}
                    </div>
                    <div className="text-blueGray-600 p-3 break-words whitespace-normal w-full">
                        {text}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PopoverButton;
