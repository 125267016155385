// --------------------------------------------------------------- \\
// --------------------- PRODUCT STATUSES ------------------------ \\
// --------------------------------------------------------------- \\

export const productStatuses = [{
    value: 'DRAFT',
    label: 'Brouillon',
    color: 'orange',
}, {
    value: 'AVAILABLE',
    label: 'Disponible',
    color: 'green',
}, {
    value: 'UNAVAILABLE',
    label: 'Indisponible',
    color: 'red',
}, {
    value: 'REMOVED',
    label: 'Retiré de la carte',
    color: 'red',
}];

// --------------------------------------------------------------- \\
// ------------------- PRODUCTION AREA TYPES --------------------- \\
// --------------------------------------------------------------- \\

export const productionAreaTypes = [{
    value: 'INTERNAL_PRODUCTION',
    label: 'Production interne',
}, {
    value: 'ORDER',
    label: 'Commande',
}];

// --------------------------------------------------------------- \\
// ----------------- SERVICE CATEGORY STATUSES ------------------- \\
// --------------------------------------------------------------- \\

export const serviceCategoryStatuses = [{
    value: 'DRAFT',
    label: 'Brouillon',
    color: 'orange',
}, {
    value: 'AVAILABLE',
    label: 'Disponible',
    color: 'green',
}, {
    value: 'UNAVAILABLE',
    label: 'Indisponible',
    color: 'red',
}, {
    value: 'REMOVED',
    label: 'Retiré de la carte',
    color: 'red',
}];

// --------------------------------------------------------------- \\
// ------------------- PRODUCT SERVICE UNITS --------------------- \\
// --------------------------------------------------------------- \\

export const serviceProductUnits = [{
    value: 'PIECES_NUMBER',
    label: 'Nombre de pièces',
}, {
    value: 'GRAMS',
    label: 'Grammes',
}, {
    value: 'PARTS_NUMBER',
    label: 'Nombre de parts',
}];

// --------------------------------------------------------------- \\
// ------------ PRODUCT SERVICE QUANTITIY MODALITIES ------------- \\
// --------------------------------------------------------------- \\

export const serviceProductQuantityModalities = [{
    value: 'GLOBAL',
    label: 'Global',
}, {
    value: 'PER_PERSON',
    label: 'Par personne',
}];

// --------------------------------------------------------------- \\
// ----------------------- SERVICE TYPES ------------------------- \\
// --------------------------------------------------------------- \\

export const serviceTypes = [{
    value: 'DELIVERY',
    label: 'Livraison',
}, {
    value: 'SERVICE',
    label: 'Traiteur',
}, {
    value: 'REMOVAL',
    label: 'Enlèvement',
}];

// --------------------------------------------------------------- \\
// ---------------------- SERVICE STATUSES ----------------------- \\
// --------------------------------------------------------------- \\

export const serviceStatuses = [{
    value: 'DRAFT',
    label: 'Brouillon',
    color: 'orange',
}, {
    value: 'CONFIRMED',
    label: 'Confirmé',
    color: 'green',
}, {
    value: 'CANCELLED',
    label: 'Annulé',
    color: 'red',
}];

// --------------------------------------------------------------- \\
// ----------------------- CLIENT STATUSES ----------------------- \\
// --------------------------------------------------------------- \\

export const clientStatuses = [{
    value: 'ACTIVE',
    label: 'Actif',
    color: 'green',
}, {
    value: 'INACTIVE',
    label: 'Inactif',
    color: 'red',
}];
