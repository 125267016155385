import React from 'react';

// Assets
import { serviceProductUnits, serviceProductQuantityModalities } from 'assets/constants';

// Components
import Input from 'components/input';
import Select from 'components/select';

// ------------------------------------------- SERVICE PRODUCT FORM ------------------------------------------- \\
const ServiceProductForm = ({ state, setState }) => {
    return (
        <>
            {/* --------------------- QUANTITY --------------------- */}
            <Input
                value={state.quantity}
                onChange={e => setState({ ...state, quantity: e.target.value })}
                placeholder="Quantité"
                type="number"
                required
            />
            {/* --------------------- UNIT --------------------- */}
            <Select
                placeholder="Unité"
                value={state.unit}
                onChange={unit => setState({ ...state, unit })}
                options={serviceProductUnits}
                required
            />
            {/* --------------------- QUANTITY MODALITY --------------------- */}
            <Select
                placeholder="Modalité de la quantité"
                value={state.quantity_modality}
                onChange={quantity_modality => setState({ ...state, quantity_modality })}
                options={serviceProductQuantityModalities}
            />
            {/* --------------------- NOTE --------------------- */}
            <Input
                value={state.note}
                onChange={e => setState({ ...state, note: e.target.value })}
                placeholder="Note"
                multiline
            />
        </>
    );
};

export default ServiceProductForm;
