import React, { useState, useEffect } from 'react';

// Services
import { getLabel } from 'services/helpers';

// Assets
import { serviceProductQuantityModalities, serviceProductUnits } from 'assets/constants';

// Components
import PopoverButton from 'components/popover-button';

// ---------------------------------------- SERVICE PRODUCTS ------------------------------------------------ \\
const ServiceProducts = ({ serviceProducts }) => {
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        if (serviceProducts != null && serviceProducts.length > 0) {

            let newProductCategories = [];
            serviceProducts.forEach(serviceProduct => {
                newProductCategories.push(serviceProduct.product.productCategory);
            });

            newProductCategories = [...new Set(newProductCategories)];
            setProductCategories(newProductCategories);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="border border-t border-blueGray-100" />
            <div className="p-6">
                <div className="py-2 text-center">
                    <b className="text-sm text-blueGray-500 uppercase">
                        Produits
                    </b>
                </div>
                {productCategories.map((productCategory, index) => (
                    <div
                        key={productCategory.id}
                        style={{ marginTop: index !== 0 ? 40 : 0 }}
                    >
                        <div className="text-xs font-bold uppercase mb-2">
                            {productCategory.name}
                        </div>
                        {/* -------------------- HEADERS -------------------- */}
                        <div className="flex items-center bg-blueGray-100">
                            <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                                Nom
                            </div>
                            <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                                Quantité
                            </div>
                            <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                                Unité
                            </div>
                            <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                                Modalité
                            </div>
                            <div className="w-4/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                                Note
                            </div>
                        </div>
                        {/* -------------------- LIST -------------------- */}
                        {serviceProducts
                            .filter(sp => sp.product.productCategory.id === productCategory.id)
                            .map((serviceProduct, index) => (
                                <div
                                    key={index}
                                    className="flex items-center border-b border-solid border-blueGray-100"
                                >
                                    {/* -------------------- PRODUCT -------------------- */}
                                    <div className="w-2/12 p-3">
                                        <i className="fas fa-circle text-xxs text-blueGray-400 align-middle mr-2" />
                                        <b className="text-sm text-blueGray-600">
                                            {serviceProduct.product.name}
                                        </b>
                                    </div>
                                    {/* -------------------- QUANTITY -------------------- */}
                                    <div className="w-2/12 p-3">
                                        <b className="text-sm text-blueGray-600">
                                            {serviceProduct.quantity}
                                        </b>
                                    </div>
                                    {/* -------------------- UNIT -------------------- */}
                                    <div className="w-2/12 p-3">
                                        <b className="text-sm text-blueGray-600">
                                            {getLabel(serviceProductUnits, serviceProduct.unit)}
                                        </b>
                                    </div>
                                    {/* -------------------- QUANTITY MODALITY -------------------- */}
                                    <div className="w-2/12 p-3">
                                        <b className="text-sm text-blueGray-600">
                                            {serviceProduct.quantity_modality ? getLabel(serviceProductQuantityModalities, serviceProduct.quantity_modality) : ''}
                                        </b>
                                    </div>
                                    {/* -------------------- NOTE -------------------- */}
                                    <div className="w-4/12 p-3">
                                        <span className="font-bold block text-sm text-blueGray-600">
                                            {serviceProduct.note !== '' && (
                                                <>
                                                    {(serviceProduct.note).substring(0, 40)}...
                                                    <PopoverButton
                                                        icon="fas fa-info"
                                                        title="Note"
                                                        text={serviceProduct.note}
                                                    />
                                                </>
                                            )}
                                        </span>
                                    </div>
                                </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    );
};

export default ServiceProducts
