import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import Input from 'components/input';
import Loading from 'components/loading';
import ModalButtons from 'components/modal-buttons';

// Messages
import messages from 'messages.json';

const CREATE_ALLERGEN = gql`
    mutation CreateAllergen($name: String!) {
        createAllergen(name: $name) {
            name
            id
        }
    }
`;

// ------------------------------------------- CREATE ALLERGEN ------------------------------------------- \\
const CreateAllergen = ({ onClose, refetch }) => {
    const { addToast } = useToasts();
    const [name, setName] = useState('')
    const [createAllergen, { loading, error, data }] = useMutation(CREATE_ALLERGEN);

    const onCreateAllergen = () => {
        if (name === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            createAllergen({ variables: { name: name } });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['allergen.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <Input
                            placeholder="Nom"
                            required
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onCreateAllergen}
                    />
                </>
            )}
        </>
    );
};

export default CreateAllergen;
