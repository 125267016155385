import React from 'react';

// ------------------------------------------- BUTTON --------------------------------------------------- \\
const CustomizedButton = ({ onClick, label, disabled }) => {
    return (
        <button
            onClick={onClick}
            type="button"
            disabled={disabled}
            className={(disabled ? 'bg-gray-400 text-black cursor-not-allowed opacity-50' : 'bg-indigo-500 text-white cursor-pointer hover:bg-indigo-600') + ' text-xs font-bold uppercase px-3 py-3 rounded outline-none focus:outline-none ease-linear transition-all duration-150'}
        >
            {label}
        </button>
    );
};

CustomizedButton.defaultProps = {
    disabled: false,
};

export default CustomizedButton;
