import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Views
import ExtraForm from './extra-form';

// Components
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const CREATE_EXTRA = gql`
    mutation createExtra($name: String!, $description: String, $extraCategoryId: ID!) {
        createExtra(input: { name: $name, description: $description, extraCategory: { connect: $extraCategoryId }}) {
            id
        }
    }
`;

// ------------------------------------------- CREATE EXTRA ------------------------------------------- \\
const CreateExtra = ({ onClose, refetch }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        name: '',
        description: '',
        extraCategory: null,
    });

    const [createExtra, { loading, error, data }] = useMutation(CREATE_EXTRA);

    const onCreateExtra = () => {
        if (state.name === '' || state.extraCategory == null) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            createExtra({
                variables: {
                    name: state.name,
                    description: state.description,
                    extraCategoryId: state.extraCategory.value,
                }
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['extra.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <ExtraForm state={state} setState={setState} />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onCreateExtra}
                    />
                </>
            )}
        </>
    );
};

export default CreateExtra;
