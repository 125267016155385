import React, { Fragment, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import ExtraCategory from './extra-category';
import CreateExtraCategory from './create-extra-category';
import UpdateExtraCategory from './update-extra-category';
import DeleteExtraCategory from './delete-extra-category';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Error from 'components/error';
import Pagination from 'components/pagination';
import Modal from 'components/modal';
import UpdateDeleteButtons from 'components/update-delete-buttons';
import InputSearch from 'components/input-search';

const EXTRA_CATEGORIES = gql`
    query ExtraCategories($name: String, $first: Int!, $page: Int!) {
        extraCategories(name: $name, first: $first, page: $page, orderBy: [{ column: NAME, order: ASC }]) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                description
            }
        }
    }
`;

// -------------------------------------- EXTRA CATEGORY ROUTER ------------------------------------------ \\
const ExtraCategoryRouter = () => {
    return (
        <Switch>
            <Route exact path="/app/extra-categories">
                <ExtraCategories />
            </Route>
            <Route exact path="/app/extra-categories/:extraCategoryId">
                <ExtraCategory />
            </Route>
        </Switch>
    );
};

// ---------------------------------------- EXTRA CATEGORIES --------------------------------------------- \\
const ExtraCategories = () => {
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    const [state, setState] = useState({
        page: 1,
        first: 20,
        searchName: '',
        extraCategory: null,
        openCreate: false,
        openUpdate: false,
        openDelete: false,
    });

    const { loading, error, data, refetch } = useQuery(EXTRA_CATEGORIES, {
        variables: {
            first: state.first,
            page: state.page,
            name: '%' + state.searchName + '%',
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({
            ...state,
            openCreate: false,
            openUpdate: false,
            openDelete: false,
        });
        refetch();
    };

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex flex-col lg:flex-row items-center justify-between p-3">
                    <InputSearch
                        placeholder="Chercher une catégorie"
                        onSearch={searchName => setState({ ...state, page: 1, searchName })}
                    />
                    <div className="mt-2 lg:mt-0">
                        <Button
                            label="Ajouter une catégorie"
                            onClick={() => setState({ ...state, openCreate: true })}
                        />
                    </div>
                </div>
                {/* -------------------- HEADERS -------------------- */}
                {mediaLG ? (
                    <div className="flex items-center bg-blueGray-100 text-xs uppercase font-semibold text-blueGray-500">
                        <div className="w-10/12 p-3">
                            Nom
                        </div>
                        <div className="w-2/12 p-3 text-center">
                            Action
                        </div>
                    </div>
                ) : (
                    <div className="border-t border-blueGray-100" />
                )}
                {/* -------------------- LIST -------------------- */}
                <ExtraCategoryList
                    loading={loading}
                    error={error}
                    data={data}
                    onRefetch={onRefetch}
                    state={state}
                    setState={setState}
                />
                {/* -------------------- PAGINATION -------------------- */}
                <Pagination
                    currentPage={state.page}
                    pageSize={state.first}
                    totalCount={data ? data.extraCategories.paginatorInfo.total : 0}
                    onChangePage={value => setState({ ...state, page: value })}
                />
            </Card>
            {/* --------------------------- CREATE --------------------------- */}
            {state.openCreate && (
                <Modal
                    title="Ajouter une catégorie d'extra"
                    required
                >
                    <CreateExtraCategory
                        onClose={() => setState({ ...state, openCreate: false })}
                        refetch={onRefetch}
                    />
                </Modal>
            )}
        </>
    );
};

// --------------------------------------- EXTRA CATEGORY LIST ------------------------------------------- \\
const ExtraCategoryList = ({ loading, error, data, onRefetch, state, setState }) => {
    const history = useHistory();

    const onClick = (event, open, extraCategory) => {
        event.stopPropagation();
        setState({ ...state, [open]: true, extraCategory });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {data.extraCategories.data.map((extraCategory, index) => (
                <Fragment key={extraCategory.id}>
                    {index !== 0 && (
                        <div className="border-t border-solid border-blueGray-100" />
                    )}
                    <div
                        onClick={() => history.push('/app/extra-categories/' + extraCategory.id)}
                        className="flex flex-wrap text-center lg:text-left items-center cursor-pointer hover:bg-gray-100"
                    >
                        <div className="w-full lg:w-10/12 px-3 py-2 lg:py-3">
                            <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                            <b className="text-sm text-blueGray-600">
                                {extraCategory.name}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3 text-center">
                            <UpdateDeleteButtons
                                onClickUpdate={e => onClick(e, 'openUpdate', extraCategory)}
                                onClickDelete={e => onClick(e, 'openDelete', extraCategory)}
                            />
                        </div>
                    </div>
                </Fragment>
            ))}
            {/* --------------------------- UPDATE --------------------------- */}
            {state.openUpdate && (
                <Modal
                    title="Modifier une catégorie d'extra"
                    required
                >
                    <UpdateExtraCategory
                        onClose={() => setState({ ...state, openUpdate: false })}
                        refetch={onRefetch}
                        extraCategory={state.extraCategory}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer une catégorie d'extra"
                >
                    <DeleteExtraCategory
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={onRefetch}
                        extraCategory={state.extraCategory}
                    />
                </Modal>
            )}
        </>
    );
};

export default ExtraCategoryRouter;
