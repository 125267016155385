import React from 'react';

// ---------------------------------------- CARD ------------------------------------------------ \\
const Card = ({ children }) => {
    return (
        <div className="mx-6 xl:mx-12 -mt-20 mb-12 bg-white shadow-xl rounded">
            {children}
        </div>
    );
};

export default Card;
