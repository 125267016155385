import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Views
import UserForm from './user-form';

// Components
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const UPDATE_USER = gql`
    mutation updateUser(
        $id: ID!,
        $last_name: String!,
        $first_name: String!,
        $email: String!,
        $phone: String,
        $mobile_phone: String,
    ) {
        updateUser(input: {
            id: $id,
            last_name: $last_name,
            first_name: $first_name,
            email: $email,
            phone: $phone,
            mobile_phone: $mobile_phone,
        }) {
            id
        }
    }
`;

// ------------------------------------------- UPDATE USER ------------------------------------------- \\
const UpdateUser = ({ onClose, refetch, user }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        email: user.email || '',
        phone: user.phone || '',
        mobile_phone: user.mobile_phone || '',
    });

    const [updateUser, { loading, error, data }] = useMutation(UPDATE_USER);

    const onUpdateUser = () => {
        if (state.first_name === '' || state.last_name === '' || state.email === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            updateUser({
                variables: {
                    id: user.id,
                    last_name: state.last_name,
                    first_name: state.first_name,
                    email: state.email,
                    phone: state.phone,
                    mobile_phone: state.mobile_phone,
                }
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['user.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <UserForm state={state} setState={setState} />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onUpdateUser}
                        labelRight="Enregistrer"
                    />
                </>
            )}
        </>
    );
};

export default UpdateUser;
