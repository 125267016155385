import React, { Fragment, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';


// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import Extra from './extra';
import CreateExtra from './create-extra';
import UpdateExtra from './update-extra';
import DeleteExtra from './delete-extra';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Error from 'components/error';
import Pagination from 'components/pagination';
import Modal from 'components/modal';
import UpdateDeleteButtons from 'components/update-delete-buttons';
import InputSearch from 'components/input-search';

const EXTRAS = gql`
    query Extras($name: String, $first: Int!, $page: Int!) {
        extras (name: $name, first: $first, page: $page, orderBy: [{ column: NAME, order: ASC }]) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                description
                extraCategory {
                    id
                    name
                }
            }
        }
    }
`;

// ----------------------------------------- EXTRA ROUTER ----------------------------------------------- \\
const ExtraRouter = () => {
    return (
        <Switch>
            <Route exact path="/app/extras">
                <Extras />
            </Route>
            <Route exact path="/app/extras/:extraId">
                <Extra />
            </Route>
        </Switch>
    );
};

// ------------------------------------------- EXTRAS --------------------------------------------------- \\
const Extras = () => {
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    const [state, setState] = useState({
        page: 1,
        first: 20,
        searchName: '',
        extra: null,
        openCreate: false,
        openUpdate: false,
        openDelete: false,
    });

    const { loading, error, data, refetch } = useQuery(EXTRAS, {
        variables: {
            first: state.first,
            page: state.page,
            name: '%' + state.searchName + '%',
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({
            ...state,
            openCreate: false,
            openUpdate: false,
            openDelete: false,
        });
        refetch();
    };

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex flex-col lg:flex-row items-center justify-between p-3">
                    <InputSearch
                        placeholder="Chercher un extra"
                        onSearch={searchName => setState({ ...state, page: 1, searchName })}
                    />
                    <div className="mt-2 lg:mt-0">
                        <Button
                            label="Ajouter un extra"
                            onClick={() => setState({ ...state, openCreate: true })}
                        />
                    </div>
                </div>
                {/* -------------------- HEADERS -------------------- */}
                {mediaLG ? (
                    <div className="flex items-center bg-blueGray-100 text-xs uppercase font-semibold text-blueGray-500">
                        <div className="w-5/12 p-3">
                            Nom
                        </div>
                        <div className="w-5/12 p-3">
                            Catégorie
                        </div>
                        <div className="w-2/12 p-3 text-center">
                            Action
                        </div>
                    </div>
                ) : (
                    <div className="border-t border-blueGray-100" />
                )}
                {/* -------------------- LIST -------------------- */}
                <ExtraList
                    loading={loading}
                    error={error}
                    data={data}
                    onRefetch={onRefetch}
                    state={state}
                    setState={setState}
                />
                {/* -------------------- PAGINATION -------------------- */}
                <Pagination
                    currentPage={state.page}
                    pageSize={state.first}
                    totalCount={data ? data.extras.paginatorInfo.total : 0}
                    onChangePage={value => setState({ ...state, page: value })}
                />
            </Card>
            {/* --------------------------- CREATE --------------------------- */}
            {state.openCreate && (
                <Modal
                    title="Ajouter un extra"
                    required
                >
                    <CreateExtra
                        onClose={() => setState({ ...state, openCreate: false })}
                        refetch={onRefetch}
                    />
                </Modal>
            )}
        </>
    );
};

// ------------------------------------------ EXTRA LIST ------------------------------------------------ \\
const ExtraList = ({ loading, error, data, onRefetch, state, setState }) => {
    const history = useHistory();

    const onClick = (event, open, extra) => {
        event.stopPropagation();
        setState({ ...state, [open]: true, extra });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {data.extras.data.map((extra, index) => (
                <Fragment key={extra.id}>
                    {index !== 0 && (
                        <div className="border-t border-solid border-blueGray-100" />
                    )}
                    <div
                        onClick={() => history.push('/app/extras/' + extra.id)}
                        className="flex flex-wrap text-center lg:text-left items-center cursor-pointer hover:bg-gray-100"
                    >
                        <div className="w-full lg:w-5/12 px-3 py-2 lg:py-3">
                            <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                            <b className="text-sm text-blueGray-600">
                                {extra.name}
                            </b>
                        </div>
                        <div className="w-full lg:w-5/12 px-3 py-2 lg:py-3">
                            <b className="text-sm text-blueGray-600">
                                {extra.extraCategory.name}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3 text-center">
                            <UpdateDeleteButtons
                                onClickUpdate={e => onClick(e, 'openUpdate', extra)}
                                onClickDelete={e => onClick(e, 'openDelete', extra)}
                            />
                        </div>
                    </div>
                </Fragment>
            ))}
            {/* --------------------------- UPDATE --------------------------- */}
            {state.openUpdate && (
                <Modal
                    title="Modifier un extra"
                    required
                >
                    <UpdateExtra
                        onClose={() => setState({ ...state, openUpdate: false })}
                        refetch={onRefetch}
                        extra={state.extra}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer un extra"
                >
                    <DeleteExtra
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={onRefetch}
                        extra={state.extra}
                    />
                </Modal>
            )}
        </>
    );
};

export default ExtraRouter;
