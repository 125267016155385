import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Views
import UpdateProductCategory from './update-product-category';
import DeleteProductCategory from './delete-product-category';

// Components
import Loading from 'components/loading';
import Error from 'components/error';
import Pagination from 'components/pagination';
import Modal from 'components/modal';
import UpdateDeleteButtons from 'components/update-delete-buttons';

// ---------------------------------------- PRODUCT CATEGORIES --------------------------------------------- \\
const ProductCategories = ({ loading, error, data, onRefetch, state, setState }) => {
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    return (
        <>
            {/* -------------------- HEADERS -------------------- */}
            {mediaLG ? (
                <div className="flex items-center bg-blueGray-100 text-xs uppercase font-semibold text-blueGray-500">
                    <div className="w-10/12 p-3">
                        Nom
                    </div>
                    <div className="w-2/12 p-3 text-center">
                        Action
                    </div>
                </div>
            ) : (
                <div className="border-t border-blueGray-100" />
            )}
            {/* -------------------- LIST -------------------- */}
            <ProductCategoryList
                loading={loading}
                error={error}
                data={data}
                onRefetch={onRefetch}
                state={state}
                setState={setState}
            />
            {/* -------------------- PAGINATION -------------------- */}
            <Pagination
                currentPage={state.page}
                pageSize={state.first}
                totalCount={data ? data.productCategories.paginatorInfo.total : 0}
                onChangePage={value => setState({ ...state, page: value })}
            />
        </>
    );
};

// --------------------------------------- PRODUCT CATEGORY LIST ------------------------------------------- \\
const ProductCategoryList = ({ loading, error, data, onRefetch, state, setState }) => {
    const history = useHistory();

    const onClick = (event, open, productCategory) => {
        event.stopPropagation();
        setState({ ...state, [open]: true, productCategory });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {data.productCategories.data.map((productCategory, index) => (
                <Fragment key={productCategory.id}>
                    {index !== 0 && (
                        <div className="border-t border-solid border-blueGray-100" />
                    )}
                    <div
                        onClick={() => history.push('/app/product-categories/' + productCategory.id)}
                        className="flex flex-wrap text-center lg:text-left items-center cursor-pointer hover:bg-gray-100"
                    >
                        <div className="w-full lg:w-10/12 px-3 py-2 lg:py-3">
                            <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                            <b className="text-sm text-blueGray-600">
                                {productCategory.name}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3 text-center">
                            <UpdateDeleteButtons
                                onClickUpdate={e => onClick(e, 'openUpdate', productCategory)}
                                onClickDelete={e => onClick(e, 'openDelete', productCategory)}
                            />
                        </div>
                    </div>
                </Fragment>
            ))}
            {/* --------------------------- UPDATE --------------------------- */}
            {state.openUpdate && (
                <Modal
                    title="Modifier une catégorie de produit"
                    required
                >
                    <UpdateProductCategory
                        onClose={() => setState({ ...state, openUpdate: false })}
                        refetch={onRefetch}
                        productCategory={state.productCategory}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer une catégorie de produit"
                >
                    <DeleteProductCategory
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={onRefetch}
                        productCategory={state.productCategory}
                    />
                </Modal>
            )}
        </>
    );
};

export default ProductCategories;
