import React, { useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import Button from 'components/button';

const SYNC_CLIENTS = gql`
    mutation {
        syncClients
    }
`;

// --------------------------------------- SYNC CLIENTS ----------------------------------------------- \\
const SyncClients = ({ refetch }) => {
    const { addToast } = useToasts();
    const [syncClients, { loading, error, data }] = useMutation(SYNC_CLIENTS);

    useEffect(() => {
        if (data) {
            addToast('Synchronisation réussie', { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast('Erreur lors de la synchronisation', { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Button
            onClick={syncClients}
            label={loading ? 'Chargement...' : 'Synchroniser'}
            disabled={loading}
        />
    );
};

export default SyncClients;
