import axios from 'axios';
import { getToken, clearToken } from './storage';

// ------------------------------------------------------------------------- \\
// ------------------------------- API URL --------------------------------- \\
// ------------------------------------------------------------------------- \\

// const apiUrl = 'http://localhost:8000/api/';
const apiUrl = 'https://portail.traiteur-avs.com/api/';

// ------------------------------------------------------------------------- \\
// ------------------------ AXIOS CONFIGURATION ---------------------------- \\
// ------------------------------------------------------------------------- \\

const axiosConfig = fileType => {

    const config = { headers: { 'X-Requested-With': 'XMLHttpRequest' }};

    const token = getToken();
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    if (fileType === 'file') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else {
        config.headers['Content-Type'] = 'application/json';
    }

    if (fileType === 'blob') {
        config.responseType = 'blob';
    }
    else {
        config.responseType = 'json';
    }

    return config;
};

// ------------------------------------------------------------------------- \\
// --------------------------- ERRORS MANAGEMENT --------------------------- \\
// ------------------------------------------------------------------------- \\

const errorsManagement = error => {
    if (error.response) {

        let status = error.response.status;

        switch (status) {
            
            // Default
            case 400:
                // Invalid token
                if (error.response.data.error === 'token_not_provided' || error.response.data.error === 'token_invalid') {
                    clearToken();
                    window.location.reload();
                    break;
                }
                return error.response.data.error;
            
            // Unauthenticated
            case 401:
                if (error.response.data.error === 'Unauthenticated') {
                    clearToken();
                    window.location.reload();
                    break;
                }
                return error.response.data.error;

            // Unauthorized
            case 403:
                return error.response.data.error;
            
            // Not found
            case 404:
                return error.response.data.error;
            
            case 422:
                let errors = '';
                Object.values(error.response.data.errors).forEach(value => {
                    errors += value + ' ';
                });
                return error.response.data.message + ' ' + errors;
            
            default:
                return status + ' : Un problème est survenu.';
        };
    }
    else {
        return 'Une erreur serveur est survenue.';
    }
};

// ------------------------------------------------------------------------- \\
// -------------------------- AXIOS REQUESTS ------------------------------- \\
// ------------------------------------------------------------------------- \\

export const Get = (url, fileType) => {
    return (
        axios.get(
            apiUrl + url,
            axiosConfig(fileType)
        )
        .then(res => {
            return res;
        })
        .catch(error => {
            return errorsManagement(error);
        })
    );
};

export const Post = (url, data, fileType) => {
    return (
        axios.post(
            apiUrl + url,
            data,
            axiosConfig(fileType)
        )
        .then(res => {
            return res;
        })
        .catch(error => {
            return errorsManagement(error);
        })
    );
};

export const Delete = url => {
    return (
        axios.delete(
            apiUrl + url,
            axiosConfig()
        )
        .then(res => {
            return res;
        })
        .catch(error => {
            return errorsManagement(error);
        })
    );
};
