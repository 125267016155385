import React, { useState, useRef } from 'react';
import Select from 'react-select'

// Apollo
import { gql, useQuery } from '@apollo/client';

// Lodash Debounce
import debounce from 'lodash.debounce';

const COMPLEMENTS = gql`
    query Complements($name: String, $first: Int!, $page: Int!) {
        complements(name: $name, first: $first, page: $page, orderBy: [{ column: NAME, order: ASC }]) {
            data {
                id
                name
            }
        }
    }
`;

// -------------------------------------- SELECT COMPLEMENTS --------------------------------------- \\
const SelectComplements = ({ complements, setComplements }) => {
    const [state, setState] = useState({
        inputValue: '',
    });

    const { loading, data } = useQuery(COMPLEMENTS, {
        variables: {
            page: 1,
            first: 10,
            name: '%' + state.inputValue + '%',
        },
        fetchPolicy: 'network-only',
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, inputValue: value == null ? '' : value });
    };
    const delayedOnSearch = useRef(debounce(inputValue => onSearch(inputValue), 500)).current;

    const handleDelayedOnSearch = (value, reason) => {
        if (reason.action === 'input-change') {
            delayedOnSearch(value);
        }
    };

    return (
        <Select
            placeholder="Chercher un complément..."
            value={complements}
            onChange={value => setComplements(value)}
            onInputChange={(inputValue, reason) => handleDelayedOnSearch(inputValue, reason)}
            options={data != null ? data.complements.data.map(a => ({ value: a.id, label: a.name })) : []}
            isLoading={loading}
            isMulti
            isClearable={true}
            noOptionsMessage={() => 'Aucun'}
            menuPortalTarget={document.body}
            maxMenuHeight={200}
            styles={{
                control: base => ({ ...base, fontSize: 14 }),
                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                container: base => ({ ...base, minWidth: 200, width: '100%' }),
            }}
        />
    );
};

export default SelectComplements;
