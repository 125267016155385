import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';

const USER = gql`
    query User($id: ID!) {
        user(id: $id) {
            id
            fullName
            last_name
            first_name
            email
            mobile_phone
            phone
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
        }
    }
`;

// ------------------------------------------- USER --------------------------------------------------- \\
const User = () => {
    const history = useHistory();
    const { userId } = useParams();

    const { loading, error, data } = useQuery(USER, {
        variables: {
            id: userId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) return <Loading />;
    if (error || data.user == null) return <Error />;

    return (
        <Card>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center p-3">
                <div className="w-1/3">
                    <Button
                        label="Retour"
                        onClick={() => history.push('/app/users')}
                    />
                </div>
                <div className="w-1/3 text-center">
                    <h3 className="font-semibold text-base text-blueGray-700">
                        {data.user.fullName}
                    </h3>
                </div>
            </div>
            {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
            <div className="flex items-center justify-between bg-blueGray-100 p-3">
                {data.user.creator && (
                    <div className="text-xs uppercase font-semibold text-blueGray-500">
                        Créateur : {data.user.creator.fullName}
                    </div>
                )}
                {data.user.lastModifier && (
                    <div className="text-xs uppercase font-semibold text-blueGray-500">
                        Dernière modification : {data.user.lastModifier.fullName}
                    </div>
                )}
            </div>
            <div className="p-6">
                {/* -------------------- LAST NAME -------------------- */}
                <div className="flex items-center w-full py-2">
                    <div className="w-4/12">
                        <b className="text-sm text-blueGray-500">Nom</b>
                    </div>
                    <div className="w-8/12">
                        <b className="text-sm text-blueGray-700">
                            {data.user.last_name}
                        </b>
                    </div>
                </div>
                {/* -------------------- FIRST NAME -------------------- */}
                <div className="border border-t border-blueGray-100" />
                <div className="flex items-center w-full py-2">
                    <div className="w-4/12">
                        <b className="text-sm text-blueGray-500">Prénom</b>
                    </div>
                    <div className="w-8/12">
                        <b className="text-sm text-blueGray-700">
                            {data.user.first_name}
                        </b>
                    </div>
                </div>
                {/* -------------------- EMAIL -------------------- */}
                <div className="border border-t border-blueGray-100" />
                <div className="flex items-center w-full py-2">
                    <div className="w-4/12">
                        <b className="text-sm text-blueGray-500">Email</b>
                    </div>
                    <div className="w-8/12">
                        <b className="text-sm text-blueGray-700">
                            {data.user.email}
                        </b>
                    </div>
                </div>
                {/* -------------------- PHONE -------------------- */}
                {data.user.phone && (
                    <>
                        <div className="border border-t border-blueGray-100" />
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-500">Téléphone</b>
                            </div>
                            <div className="w-8/12">
                                <b className="text-sm text-blueGray-700">
                                    {data.user.phone}
                                </b>
                            </div>
                        </div>
                    </>
                )}
                {/* -------------------- MOBILE PHONE -------------------- */}
                {data.user.mobile_phone && (
                    <>
                        <div className="border border-t border-blueGray-100" />
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-500">Téléphone portable</b>
                            </div>
                            <div className="w-8/12">
                                <b className="text-sm text-blueGray-700">
                                    {data.user.mobile_phone}
                                </b>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Card>
    );
};

export default User;
