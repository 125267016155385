import React from 'react';

// Components
import SelectExtraCategory from 'components/autocomplete/select-extra-category';
import Input from 'components/input';

// ------------------------------------------- EXTRA FORM ------------------------------------------- \\
const ExtraForm = ({ state, setState }) => {
    return (
        <>
            {/* --------------------- NAME --------------------- */}
            <Input
                value={state.name}
                onChange={e => setState({ ...state, name: e.target.value })}
                placeholder="Nom"
                required
            />
            {/* --------------------- EXTRA CATEGORY --------------------- */}
            <div className="w-full mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Catégorie de l'extra
                    <span className="text-base text-indigo-700 ml-1">*</span>
                </label>
                <SelectExtraCategory
                    extraCategory={state.extraCategory}
                    setExtraCategory={extraCategory => setState({ ...state, extraCategory })}
                />
            </div>
            {/* --------------------- DESCRIPTION --------------------- */}
            <Input
                value={state.description}
                onChange={e => setState({ ...state, description: e.target.value })}
                placeholder="Description"
                multiline
            />
        </>
    );
};

export default ExtraForm;
