import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

// Views
import Service from './service';
import ServiceCalendar from './service-calendar';
import ServiceList from './service-list';
import CreateService from './create-service';
import UpdateService from './update-service';
import DuplicateService from './duplicate-service';

// Components
import Card from 'components/card';
import Button from 'components/button';

// ------------------------------------- SERVICE ROUTER ------------------------------------------ \\
const ServiceRouter = () => {
    return (
        <Switch>
            <Route exact path="/app/services">
                <Services />
            </Route>
            <Route exact path="/app/services/create">
                <CreateService />
            </Route>
            <Route exact path="/app/services/update/:serviceId">
                <UpdateService />
            </Route>
            <Route exact path="/app/services/duplicate/:serviceId">
                <DuplicateService />
            </Route>
            <Route exact path="/app/services/:serviceId">
                <Service />
            </Route>
        </Switch>
    );
};

// ---------------------------------------- SERVICES --------------------------------------------- \\
const Services = () => {
    const history = useHistory();
    const [menu, setMenu] = useState('calendar');

    return (
        <Card>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex flex-col lg:flex-row items-center justify-between p-3">
                <div>
                    <button
                        onClick={() => setMenu('calendar')}
                        type="button"
                        className={(menu === 'calendar' ? 'bg-indigo-500' : 'bg-blueGray-300') + ' text-white hover:bg-indigo-600 text-xs font-bold uppercase px-6 py-2 rounded-l-lg outline-none focus:outline-none ease-linear transition-all duration-150'}
                    >
                        <i className="fas fa-table text-xl opacity-75" />
                    </button>
                    <button
                        onClick={() => setMenu('list')}
                        type="button"
                        className={(menu === 'list' ? 'bg-indigo-500' : 'bg-blueGray-300') + '  text-white hover:bg-indigo-600 text-xs font-bold uppercase px-6 py-2 rounded-r-lg outline-none focus:outline-none ease-linear transition-all duration-150'}
                    >
                        <i className="fas fa-list text-xl opacity-75" />
                    </button>
                </div>
                <div className="mt-2 lg:mt-0">
                    <Button
                        label="Ajouter une prestation"
                        onClick={() => history.push('/app/services/create')}
                    />
                </div>
            </div>
            <div className="border-t border-solid border-blueGray-100" />
            {/* -------------------- CALENDAR -------------------- */}
            {menu === 'calendar' && (
                <ServiceCalendar />
            )}
            {/* -------------------- LIST -------------------- */}
            {menu === 'list' && (
                <ServiceList />
            )}
        </Card>
    );
};

export default ServiceRouter;
