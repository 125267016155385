import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

// File Saver
import { saveAs } from 'file-saver';

// Moment
import moment from 'moment';

// Services
import { getFormattedDate } from 'services/helpers';
import { downloadProductionSheetPDF } from 'services/requests';

// Views
import Services from './services';

// Components
import Modal from 'components/modal';

require('moment/locale/fr.js');

// ------------------------------------------- BUNDLE DAYS ------------------------------------------- \\
const BundleDays = ({ bundleDays }) => {

    const [state, setState] = useState({
        openServices: false,
        services: [],
        downloading: true,
    });

    const getDownloadProductionSheetPDF = (productionSheetId, date, productionAreaName) => {
        downloadProductionSheetPDF(productionSheetId).then(res => {
            if (res.status === 200) {
                saveAs(res.data,  (date + '_' + productionAreaName + '.pdf'));
            }
            else {
                console.log(res);
            }
        });
    };

    return (
        <>
            <div className="p-6">
                {bundleDays.map((bundleDay, index) => (
                    <div
                        key={bundleDay.id}
                        className="border-2 border-blueGray-200 rounded"
                        style={{ marginTop: index !== 0 ? 24 : 0 }}
                    >
                        {/* -------------------- DATE -------------------- */}
                        <div className="border-b-2 border-blueGray-200 px-3 py-1">
                            <b className="text-sm capitalize">
                                {moment(bundleDay.date).format('dddd')} {getFormattedDate(bundleDay.date)}
                            </b>
                        </div>
                        {/* -------------------- PRODUCTION SHEETS -------------------- */}
                        <div className="p-3">
                            {bundleDay.productionSheets.length > 0 ? (
                                bundleDay.productionSheets.map((productionSheet, index) => (
                                    <div
                                        key={productionSheet.id}
                                        className="bg-blueGray-100 rounded"
                                        style={{ marginTop: index !== 0 ? 24 : 0 }}
                                    >
                                        {/* -------------------- PRODUCTION AREA -------------------- */}
                                        <div className="flex items-center p-3 uppercase text-blueGray-600 text-xs font-bold">
                                            <div className="w-4/12">
                                                Zone de production
                                            </div>
                                            <div className="w-8/12">
                                                {productionSheet.productionArea.name}
                                            </div>
                                        </div>
                                        <div className="border-t border-blueGray-400" />
                                        {/* -------------------- PRODUCTION SHEET -------------------- */}
                                        <div className="flex items-center p-3 text-blueGray-600 text-xs font-bold">
                                            <div className="w-4/12 uppercase">
                                                Fiche de production
                                            </div>
                                            <div className="w-8/12 flex items-center">
                                                <button
                                                    onClick={() => getDownloadProductionSheetPDF(productionSheet.id, bundleDay.date, productionSheet.productionArea.name)}
                                                    type="button"
                                                    className="border border-pink-600 uppercase text-pink-600 px-3 py-1 rounded text-sm font-semibold uppercase
                                                        hover:text-white hover:bg-pink-600"
                                                >
                                                    Télécharger PDF
                                                </button>
                                                {!productionSheet.upToDate && (
                                                    <div style={{ width: 40, textAlign: 'center' }}>
                                                        <i
                                                            className="fas fa-exclamation-triangle"
                                                            data-tip="La version n'est plus à jour"
                                                            data-for="btn-up-to-date"
                                                            style={{ fontSize: 20 }}
                                                        />
                                                        <ReactTooltip
                                                            id="btn-up-to-date"
                                                            delayShow={1000}
                                                            delayHide={100}
                                                            textColor="#fff"
                                                            backgroundColor="#3730A3"
                                                            effect="solid"
                                                            place="top"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="border-t border-blueGray-400" />
                                        {/* -------------------- SERVICES -------------------- */}
                                        <div className="flex items-center p-3 uppercase text-blueGray-600 text-xs font-bold">
                                            <div className="w-4/12">
                                                Prestations liées
                                            </div>
                                            <div className="w-8/12">
                                                <button
                                                    onClick={() => setState({ ...state, openServices: true, services: productionSheet.services })}
                                                    type="button"
                                                    className="border border-blueGray-400 px-3 py-1 rounded text-blueGray-400 text-sm font-semibold uppercase
                                                        hover:text-white hover:bg-blueGray-400"
                                                >
                                                    Voir la liste
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                                    Aucune fiche de production
                                </label>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {/* --------------------------- SERVICES --------------------------- */}
            {state.openServices && (
                <Modal title="Prestations">
                    <Services
                        onClose={() => setState({ ...state, openServices: false })}
                        services={state.services}
                    />
                </Modal>
            )}
        </>
    );
};

export default BundleDays;
