import React from 'react';

// Assets
import { serviceCategoryStatuses } from 'assets/constants';

// Components
import Input from 'components/input';
import Select from 'components/select';
import SelectColor from 'components/select-color';

// ---------------------------------------- SERVICE CATEGORY FORM --------------------------------------- \\
const ServiceCategoryForm = ({ state, setState }) => {
    return (
        <>
            {/* --------------------- NAME --------------------- */}
            <Input
                value={state.name}
                onChange={e => setState({ ...state, name: e.target.value })}
                placeholder="Nom"
                required
            />
            {/* --------------------- START & END DATES --------------------- */}
            <div className="flex">
                <div className="w-1/2 pr-2">
                    <Input
                        value={state.start_date}
                        onChange={e => setState({ ...state, start_date: e.target.value })}
                        placeholder="Date de début (JJ/MM)"
                        required
                    />
                </div>
                <div className="w-1/2 pl-2">
                    <Input
                        value={state.end_date}
                        onChange={e => setState({ ...state, end_date: e.target.value })}
                        placeholder="Date de fin (JJ/MM)"
                        required
                    />
                </div>
            </div>
            {/* --------------------- PRIORITY --------------------- */}
            <Input
                value={state.priority}
                onChange={e => setState({ ...state, priority: e.target.value })}
                placeholder="Priorité"
                type="number"
                required
            />
            {/* --------------------- STATUS --------------------- */}
            <Select
                placeholder="Statut"
                value={state.status}
                onChange={status => setState({ ...state, status })}
                options={serviceCategoryStatuses}
            />
            {/* --------------------- COLOR --------------------- */}
            <SelectColor
                placeholder="Couleur"
                color={state.color}
                setColor={color => setState({ ...state, color })}
            />
            {/* --------------------- NUMBER --------------------- */}
            <Input
                value={state.number}
                onChange={e => setState({ ...state, number: e.target.value })}
                placeholder="Nombre de produit par personne"
                type="number"
            />
            {/* --------------------- DESCRIPTION --------------------- */}
            <Input
                value={state.description}
                onChange={e => setState({ ...state, description: e.target.value })}
                placeholder="Description"
                multiline
            />
        </>
    );
};

export default ServiceCategoryForm;
