import React from 'react';

// SimpleBar
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

// ---------------------------------------- SCROLLBAR ---------------------------------------- \\
const Scrollbar = ({ children, maxHeight }) => {
    return (
        <SimpleBar forceVisible={true} autoHide={false} style={{ maxHeight: maxHeight }}>
            {children}
        </SimpleBar>
    );
};

export default Scrollbar;
