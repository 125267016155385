import React from 'react';
import ReactTooltip from 'react-tooltip';

// File-Saver
import { saveAs } from 'file-saver';

// Services
import { downloadClientDescriptionPDF } from 'services/requests';

// --------------------------------------- CLIENT DESCRIPTIONS --------------------------------------- \\
const ClientDescriptions = ({ clientDescriptions }) => {

    const getDownloadClientDescriptionPDF = clientDescription => {
        downloadClientDescriptionPDF(clientDescription.id).then(res => {
            if (res.status === 200) {
                saveAs(res.data, clientDescription?.file_url.split('clientDescriptions/')[1]);
            }
            else {
                console.log(res);
            }
        });
    };

    return (
        <div className="flex items-center justify-center p-6">
            <div className="w-full lg:w-6/12">
                {clientDescriptions.map((clientDescription, index) => (
                    <button   
                        key={clientDescription.id}
                        onClick={() => getDownloadClientDescriptionPDF(clientDescription)}
                        className={(index === 0 ? 'mt-0' : 'mt-3') + ' w-full p-3 rounded text-white bg-pink-700 hover:bg-pink-600'}
                    >
                        {clientDescription?.file_url.split('clientDescriptions/')[1]}
                        {!clientDescription.upToDate && (
                            <>
                                <i
                                    className="fas fa-exclamation-triangle"
                                    data-tip="La version n'est plus à jour"
                                    data-for="btn-up-to-date"
                                    style={{ fontSize: 20, marginLeft: 10 }}
                                />
                                <ReactTooltip
                                    id="btn-up-to-date"
                                    delayShow={1000}
                                    delayHide={100}
                                    textColor="#fff"
                                    backgroundColor="#3730A3"
                                    effect="solid"
                                    place="top"
                                />
                            </>
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ClientDescriptions;
