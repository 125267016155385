import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Views
import ProductOriginForm from './product-origin-form';

// Components
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const UPDATE_PRODUCT_ORIGIN = gql`
    mutation updateProductOrigin($id: ID!, $name: String!, $productOriginCategory: ConnectProductOriginCategoryBelongsTo!) {
        updateProductOrigin(id: $id, name: $name, productOriginCategory: $productOriginCategory) {
            id
        }
    }
`;

// ------------------------------------------- UPDATE PRODUCT ORIGIN ------------------------------------------- \\
const UpdateProductOrigin = ({ onClose, refetch, productOrigin }) => {
    const { addToast } = useToasts();
    const [updateProductOrigin, { loading, error, data }] = useMutation(UPDATE_PRODUCT_ORIGIN);

    const [state, setState] = useState({
        name: productOrigin.name || '',
        productOriginCategory: productOrigin.productOriginCategory ? ({ value: productOrigin.productOriginCategory.id, label: productOrigin.productOriginCategory.name }) : null,
    });

    const onUpdateProductOrigin = () => {
        if (state.name === '' || state.productOriginCategory == null) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            updateProductOrigin({
                variables: {
                    id: productOrigin.id,
                    name: state.name,
                    productOriginCategory: { connect: state.productOriginCategory.value },
                }
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['product.origin.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <ProductOriginForm
                            state={state}
                            setState={setState}
                        />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onUpdateProductOrigin}
                        labelRight="Enregistrer"
                    />
                </>
            )}
        </>
    );
};

export default UpdateProductOrigin;
