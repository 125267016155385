import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import Services from './services';

// Assets
import { clientStatuses } from 'assets/constants';

// Services
import { getColor, getLabel } from 'services/helpers';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';
import Modal from 'components/modal';

const CLIENT = gql`
    query Client($id: ID!) {
        client(id: $id) {
            id
            name
            address
            additional_address
            postal_code
            city
            status
            services {
                id
                status
                date
                type
                serviceCategory {
                    id
                    name
                }
                client {
                    id
                    name
                }
            }
        }
    }
`;

// ------------------------------------------- CLIENT --------------------------------------------------- \\
const Client = () => {
    const history = useHistory();
    const { clientId } = useParams();
    const [open, setOpen] = useState(false);

    const { loading, error, data } = useQuery(CLIENT, {
        variables: {
            id: clientId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) return <Loading />;
    if (error || data.client == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex flex-wrap items-center p-3">
                    <div className="w-full text-center lg:text-left lg:w-1/3">
                        <Button
                            label="Retour"
                            onClick={() => history.push('/app/clients')}
                        />
                    </div>
                    <div className="w-full lg:w-1/3 mt-2 lg:mt-0 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            {data.client.name}
                        </h3>
                    </div>
                </div>
                {/* -------------------- SEPARATOR -------------------- */}
                <div className="border-2 border-t border-blueGray-100" />
                <div className="p-6">
                    {/* -------------------- STATUS -------------------- */}
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-600">Statut</b>
                        </div>
                        <div className="w-8/12">
                            <div
                                className="flex w-max rounded-full px-2 py-1"
                                style={{ backgroundColor: getColor(clientStatuses, data.client.status) }}
                            >
                                <b className="text-xs text-white uppercase">
                                    {getLabel(clientStatuses, data.client.status)}
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="border border-t border-blueGray-100" />
                    {/* -------------------- ADDRESS -------------------- */}
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-600">Adresse</b>
                        </div>
                        <div className="w-8/12">
                            <b className="text-sm text-blueGray-600">{data.client.address}</b>
                        </div>
                    </div>
                    <div className="border border-t border-blueGray-100" />
                    {/* -------------------- ADDITIONAL ADDRESS -------------------- */}
                    {data.client.additional_address && (
                        <>
                            <div className="flex items-center w-full py-2">
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-600">Complément d'adresse</b>
                                </div>
                                <div className="w-8/12">
                                    <b className="text-sm text-blueGray-600">{data.client.additional_address}</b>
                                </div>
                            </div>
                            <div className="border border-t border-blueGray-100" />
                        </>
                    )}
                    {/* -------------------- POSTAL CODE -------------------- */}
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-600">Code postal</b>
                        </div>
                        <div className="w-8/12">
                            <b className="text-sm text-blueGray-600">{data.client.postal_code}</b>
                        </div>
                    </div>
                    <div className="border border-t border-blueGray-100" />
                    {/* -------------------- CITY -------------------- */}
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-600">Ville</b>
                        </div>
                        <div className="w-8/12">
                            <b className="text-sm text-blueGray-600">{data.client.city}</b>
                        </div>
                    </div>
                    <div className="border border-t border-blueGray-100" />
                    {/* -------------------- SERVICES -------------------- */}
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-600">Prestations liés</b>
                        </div>
                        <div className="w-8/12">
                            {data.client.services && data.client.services.length > 0 ? (
                                <button
                                    onClick={() => setOpen(true)}
                                    type="button"
                                    className="border border-blueGray-400 px-3 py-1 rounded text-blueGray-400 text-sm font-semibold uppercase
                                        hover:text-white hover:bg-blueGray-400"
                                >
                                    Voir la liste
                                </button>
                            ) : (
                                <b className="text-sm text-blueGray-600">Aucune</b>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
            {/* --------------------------- SERVICES --------------------------- */}
            {open && (
                <Modal title="Prestations">
                    <Services
                        onClose={() => setOpen(false)}
                        services={data.client.services}
                    />
                </Modal>
            )}
        </>
    );
};

export default Client;
