import React from 'react';

// Components
import Input from 'components/input';
import SelectProductCategory from 'components/autocomplete/select-product-category';

// --------------------------------------- PRODUCT CATEGORY FORM ---------------------------------------- \\
const ProductCategoryForm = ({ state, setState, currentProdutCategoryId }) => {
    return (
        <>
            {/* --------------------- NAME --------------------- */}
            <Input
                value={state.name}
                onChange={e => setState({ ...state, name: e.target.value })}
                placeholder="Nom"
                required
            />
            {/* --------------------- DESCRIPTION --------------------- */}
            <Input
                value={state.description}
                onChange={e => setState({ ...state, description: e.target.value })}
                placeholder="Description"
                multiline
            />
            {/* --------------------- PRODUT CATEGORY --------------------- */}
            <div>
                <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Sélectionner une catégorie parent
                </label>
                <SelectProductCategory
                    productCategory={state.productCategory}
                    setProductCategory={productCategory => setState({ ...state, productCategory })}
                    currentProdutCategoryId={currentProdutCategoryId}
                    isDisabled={state.isDisabled}
                />
            </div>
        </>
    );
};

export default ProductCategoryForm;
