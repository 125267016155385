import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ReactTooltip from 'react-tooltip';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Services
import { getFormattedDate, getLabel, getColor } from 'services/helpers';

// Assets
import { serviceTypes, serviceStatuses } from 'assets/constants';

// Components
import Loading from 'components/loading';
import Error from 'components/error';
import Badge from 'components/badge';
import Pagination from 'components/pagination';
import InputFiler from 'components/input-filter';
import SelectFilter from 'components/select-filter';

const SERVICES = gql`
    query Services(
        $lastVersionId: Int,
        $first: Int!,
        $page: Int!,
        $searchDate: String,
        $type: String,
        $status: String,
        $clientName: String,
    ) {
        services(
            lastVersionId: $lastVersionId,
            first: $first,
            page: $page,
            searchDate: $searchDate,
            type: $type,
            status: $status,
            clientName: $clientName,
        ) {
            paginatorInfo {
                total
            }
            data {
                id
                date
                type
                status
                client {
                    id
                    name
                }
                serviceCategory {
                    id
                    name
                }
            }
        }
    }
`;

// ---------------------------------------- SERVICES --------------------------------------------- \\
const Services = () => {
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    const [state, setState] = useState({
        page: 1,
        first: 20,
        service: null,
        openDelete: false,

        // Filter
        status: '',
        clientName: '',
        searchDate: '',
        type: '',

        // Input
        inputStatus: '',
        inputClient: '',
        inputDate: '',
        inputType: '',
    });

    const { loading, error, data } = useQuery(SERVICES, {
        variables: {
            lastVersionId: 0,
            first: state.first,
            page: state.page,
            status: '%' + state.status + '%',
            clientName: '%' + state.clientName + '%',
            searchDate: '%' + state.searchDate + '%',
            type: '%' + state.type + '%',
        },
        fetchPolicy: 'network-only',
    });

    const onSearch = (label, value) => {
        setState({ ...state, page: 1, [label]: value });
    };

    const onGlobalSearch = () => {
        setState({
            ...state,
            page: 1,
            clientName: state.inputClient,
            searchDate: state.inputDate,
        });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            page: 1,
            status: '',
            clientName: '',
            searchDate: '',
            type: '',
            inputStatus: '',
            inputClient: '',
            inputDate: '',
            inputType: '',
        });
    };

    return (
        <>
            {/* -------------------- SEARCH -------------------- */}
            <div className="flex items-center p-3">
                <button
                    onClick={onGlobalSearch}
                    data-for="btn-global-search"
                    data-tip="Rechercher"
                    className="px-3 py-1 rounded-l-lg bg-blueGray-400 hover:bg-blueGray-500"
                >
                    <i className="fas fa-search text-white" style={{ height: 16, width: 16, fontSize: 16 }} />
                </button>
                <ReactTooltip
                    id="btn-global-search"
                    delayShow={1000}
                    delayHide={100}
                    textColor="#fff"
                    backgroundColor="#3730A3"
                    effect="solid"
                    place="bottom"
                />
                <div className="border-l border-blueGray-100" />
                <button
                    onClick={onCancelSearch}
                    data-for="btn-cancel-search"
                    data-tip="Annuler la recherche"
                    className="px-3 py-1 rounded-r-lg bg-blueGray-400 hover:bg-blueGray-500"
                >
                    <i className="fas fa-times text-white" style={{ height: 16, width: 16, fontSize: 16 }} />
                </button>
                <ReactTooltip
                    id="btn-cancel-search"
                    delayShow={1000}
                    delayHide={100}
                    textColor="#fff"
                    backgroundColor="#3730A3"
                    effect="solid"
                    place="bottom"
                />
            </div>
            {/* -------------------- HEADERS -------------------- */}
            {mediaLG ? (
                <div className="flex items-center bg-blueGray-100 text-xs uppercase font-semibold text-blueGray-500">
                    <div className="w-2/12 p-3">
                        Statut
                    </div>
                    <div className="w-3/12 p-3">
                        Client
                    </div>
                    <div className="w-2/12 p-3">
                        Date
                    </div>
                    <div className="w-2/12 p-3">
                        Type
                    </div>
                    <div className="w-3/12 p-3">
                        Catégorie
                    </div>
                </div>
            ) : (
                <div className="border-t border-blueGray-100" />
            )}
            {/* -------------------- FILTERS -------------------- */}
            <div className="flex flex-wrap items-center border-b border-blueGray-100">
                <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                    <SelectFilter
                        placeholder="Statut"
                        value={state.inputStatus}
                        onChange={item => setState({ ...state, page: 1, inputStatus: item, status: item ? item.value : '' })}
                        options={serviceStatuses.map(item => ({ ...item, value: item.value.toLowerCase() }))}
                        isClearable
                        minWidth={0}
                    />
                </div>
                <div className="w-full lg:w-3/12 px-3 py-2 lg:py-3">
                    <InputFiler
                        placeholder="Client"
                        value={state.inputClient}
                        onChange={e => setState({ ...state, inputClient: e.target.value })}
                        onKeyPress={() => onSearch('clientName', state.inputClient)}
                    />
                </div>
                <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                    <InputFiler
                        placeholder="Date"
                        value={state.inputDate}
                        onChange={e => setState({ ...state, inputDate: e.target.value })}
                        onKeyPress={() => onSearch('searchDate', state.inputDate)}
                    />
                </div>
                <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                    <SelectFilter
                        placeholder="Type"
                        value={state.inputType}
                        onChange={item => setState({ ...state, page: 1, inputType: item, type: item ? item.value : '' })}
                        options={serviceTypes.map(item => ({ ...item, value: item.value.toLowerCase() }))}
                        isClearable
                        minWidth={0}
                    />
                </div>
            </div>
            {/* -------------------- LIST -------------------- */}
            <ServiceList
                loading={loading}
                error={error}
                data={data}
            />
            {/* -------------------- PAGINATION -------------------- */}
            <Pagination
                currentPage={state.page}
                pageSize={state.first}
                totalCount={data ? data.services.paginatorInfo.total : 0}
                onChangePage={value => setState({ ...state, page: value })}
            />
        </>
    );
};

// -------------------------------------- SERVICE LIST ------------------------------------------- \\
const ServiceList = ({ loading, error, data }) => {
    const history = useHistory();

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {data.services.data.map((service, index) => (
                <Fragment key={service.id}>
                    {index !== 0 && (
                        <div className="border-t border-solid border-blueGray-100" />
                    )}
                    <div
                        onClick={() => history.push('/app/services/' + service.id)}
                        className="flex flex-wrap text-center lg:text-left items-center cursor-pointer hover:bg-gray-100"
                    >
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                            <Badge
                                label={getLabel(serviceStatuses, service.status)}
                                color={getColor(serviceStatuses, service.status)}
                            />
                        </div>
                        <div className="w-full lg:w-3/12 px-3 py-2 lg:py-3">
                            <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                            <b className="text-sm text-blueGray-600">
                                {service.client?.name}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                            <b className="text-sm text-blueGray-600">
                                {getFormattedDate(service.date)}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                            <b className="text-sm text-blueGray-600">
                                {getLabel(serviceTypes, service.type)}
                            </b>
                        </div>
                        <div className="w-full lg:w-3/12 px-3 py-2 lg:py-3">
                            <b className="text-sm text-blueGray-600">
                                {service.serviceCategory?.name}
                            </b>
                        </div>
                    </div>
                </Fragment>
            ))}
        </>
    );
};

export default Services;
