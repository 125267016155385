import React, { useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Services
import { getFormattedDate } from 'services/helpers';

// Components
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const DELETE_SERVICE = gql`
    mutation DeleteService($id: ID!) {
        deleteService(id: $id) {
            id
        }
    }
`;

// ------------------------------------------- DELETE SERVICE ------------------------------------------- \\
const DeleteService = ({ onClose, refetch, service }) => {
    const { addToast } = useToasts();
    const [deleteService, { loading, error, data }] = useMutation(DELETE_SERVICE);

    const onDeleteService = () => {
        deleteService({ variables: { id: service.id } });
    };

    useEffect(() => {
        if (data) {
            addToast('Prestation supprimée', { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="text-center font-semibold text-base py-6">
                        <span>Êtes-vous sûr de vouloir supprimer cette prestation ?</span>
                        <br />
                        <strong>{getFormattedDate(service.date)} : {service.client.name}</strong>
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onDeleteService}
                        labelRight="Supprimer"
                    />
                </>
            )}
        </>
    );
};

export default DeleteService;
