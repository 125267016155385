// --------------------------------------------------------------- \\
// ---------------------------- DATE ----------------------------- \\
// --------------------------------------------------------------- \\

export const getFormattedDate = date => {
    if (date != null) {
        const data = date.split('-');
        return data[2] + '/' + data[1] + '/' + data[0];
    }
    return '';
};

export const checkValidDateWithDayAndMonth = date => {
    var expression = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])$/;
    return expression.test(date?.toString()?.toLowerCase());
};

// --------------------------------------------------------------- \\
// ---------------------------- TIME ----------------------------- \\
// --------------------------------------------------------------- \\

export const getFormattedTime = time => {
    if (time != null) {
        return time.slice(0, 5).replace(':', 'h');
    }
    return '';
};

// --------------------------------------------------------------- \\
// -------------------------- GET LABEL -------------------------- \\
// --------------------------------------------------------------- \\

export const getLabel = (list, value) => {
    const item = list.find(s => s.value === value);
    if (item != null) {
        return item.label;
    }
    return '';
};

// --------------------------------------------------------------- \\
// -------------------------- GET COLOR -------------------------- \\
// --------------------------------------------------------------- \\

export const getColor = (list, value) => {
    const item = list.find(s => s.value === value);
    if (item != null) {
        return item.color;
    }
    return '';
};
