import React from 'react';

// Assets
import { productionAreaTypes } from 'assets/constants';

// Components
import Input from 'components/input';
import Select from 'components/select';

// ------------------------------------------- PRODUCTION AREA FORM ------------------------------------------- \\
const ProductionAreaForm = ({ state, setState }) => {
    return (
        <>
            {/* --------------------- NAME --------------------- */}
            <Input
                value={state.name}
                onChange={e => setState({ ...state, name: e.target.value })}
                placeholder="Nom"
                required
            />
            {/* --------------------- TYPE --------------------- */}
            <Select
                placeholder="Type"
                required
                value={state.type}
                onChange={type => setState({ ...state, type })}
                options={productionAreaTypes}
            />
            {/* --------------------- DESCRIPTION --------------------- */}
            <Input
                value={state.description}
                onChange={e => setState({ ...state, description: e.target.value })}
                placeholder="Description"
                multiline
            />
        </>
    );
};

export default ProductionAreaForm;
