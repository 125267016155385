import React, { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Services
import { getFormattedDate, getFormattedTime, getLabel, getColor } from 'services/helpers';

// Assets
import { serviceTypes, serviceStatuses } from 'assets/constants';

// Views
import DeleteService from '../delete-service';
import RestoreService from './restore-service';
import Extras from './extras';
import ServiceProducts from './service-products';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';
import Modal from 'components/modal';
import UpdateDeleteButtons from 'components/update-delete-buttons';
import OldVersions from './old-versions';
import ClientDescription from './client-description';

const SERVICE = gql`
    query Service($id: ID!) {
        service(id: $id) {
            id
            date
            type
            status
            start_time
            guests_number
            place
            note
            serviceCategory {
                id
                name
            }
            client {
                id
                name
            }
            clientDescription {
                id
                file_url
                upToDate
            }
            extras {
                id
                name
            }
            serviceProducts {
                id
                quantity
                unit
                note
                quantity_modality
                product {
                    id
                    name
                    productCategory {
                        id
                        name
                    }
                }
            }
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
        }
    }
`;

// ------------------------------------------- SERVICE --------------------------------------------------- \\
const Service = () => {
    const history = useHistory();
    const { serviceId } = useParams();
    const { search } = useLocation();
    const isLastVersion = !search.includes('isLastVersion=false');

    const [state, setState] = useState({
        openExtras: false,
        openDelete: false,
        openOldVersions: false,
        openRestore: false,
    });

    const { loading, error, data } = useQuery(SERVICE, {
        variables: {
            id: serviceId,
        },
        fetchPolicy: 'network-only',
    });

    const goBack = () => {
        if (isLastVersion) {
            history.push('/app/services');
        }
        else {
            history.goBack();
        }
    };

    if (loading) return <Loading />;
    if (error || data.service == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex items-center p-3">
                    <div className="w-1/3">
                        <Button
                            label="Retour"
                            onClick={goBack}
                        />
                    </div>
                    <div className="w-1/3 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            {isLastVersion ? (
                                <>{getFormattedDate(data.service.date)} : {data.service.client.name}</>
                            ) : (
                                'Ancienne version'
                            )}
                        </h3>
                    </div>
                    <div className="w-1/3 text-right">
                        {isLastVersion ? (
                            <>
                                <button
                                    onClick={() => history.push('/app/services/duplicate/' + serviceId)}
                                    data-tip="Dupliquer"
                                    data-for="btn-round-duplicate"
                                    type="button"
                                    className="h-7 w-7 rounded-full border border-solid outline-none focus:outline-none ease-linear transition-all duration-150
                                        bg-transparent border-blueGray-400 hover:border-blueGray-600 hover:bg-blueGray-600
                                        font-bold uppercase text-xs text-blueGray-400 hover:text-white mr-2"
                                >
                                    <i className="fas fa-copy" />
                                </button>
                                <ReactTooltip
                                    id='btn-round-duplicate'
                                    delayShow={1000}
                                    delayHide={100}
                                    textColor='#fff'
                                    backgroundColor='#3730A3'
                                    effect='solid'
                                    place="bottom"
                                />
                                <UpdateDeleteButtons
                                    tooltipPlace="bottom"
                                    onClickUpdate={() => history.push('/app/services/update/' + serviceId)}
                                    onClickDelete={() => setState({ ...state, openDelete: true })}
                                />
                            </>
                        ) : (
                            <Button
                                label="Restaurer"
                                onClick={() => setState({ ...state, openRestore: true })}
                            />
                        )}
                    </div>
                </div>
                {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
                <div className="flex items-center justify-between bg-blueGray-100 p-3">
                    {data.service.creator && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Créateur : {data.service.creator.fullName}
                        </div>
                    )}
                    {data.service.lastModifier && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Dernière modification : {data.service.lastModifier.fullName}
                        </div>
                    )}
                </div>
                <div className="p-6">
                    {/* -------------------- STATUS -------------------- */}
                    {data.service.status && (
                        <>
                            <div className="flex items-center w-full py-2">
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-500">Statut</b>
                                </div>
                                <div className="w-8/12">
                                    <div
                                        className="flex w-max rounded-full px-2 py-1"
                                        style={{ backgroundColor: getColor(serviceStatuses, data.service.status) }}
                                    >
                                        <b className="text-xs text-white uppercase">
                                            {getLabel(serviceStatuses, data.service.status)}
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-t border-blueGray-100" />
                        </>
                    )}
                    {/* -------------------- CLIENT -------------------- */}
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-500">Client</b>
                        </div>
                        <div className="w-8/12">
                            <b className="text-sm text-blueGray-700">
                                {data.service.client?.name}
                            </b>
                        </div>
                    </div>
                    {/* -------------------- CLIENT DESCRIPTION -------------------- */}
                    <div className="border border-t border-blueGray-100" />
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-500">Descriptif client</b>
                        </div>
                        <div className="w-8/12">
                            <ClientDescription
                                service={data.service}
                                clientDescription={data.service.clientDescription}
                            />
                        </div>
                    </div>
                    {/* -------------------- TYPE -------------------- */}
                    <div className="border border-t border-blueGray-100" />
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-500">Type</b>
                        </div>
                        <div className="w-8/12">
                            <b className="text-sm text-blueGray-700">
                                {getLabel(serviceTypes, data.service.type)}
                            </b>
                        </div>
                    </div>
                    {/* -------------------- SERVICE CATEGORY -------------------- */}
                    <div className="border border-t border-blueGray-100" />
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-500">Catégorie</b>
                        </div>
                        <div className="w-8/12">
                            <b className="text-sm text-blueGray-700">
                                {data.service.serviceCategory?.name}
                            </b>
                        </div>
                    </div>
                    {/* -------------------- DATE -------------------- */}
                    <div className="border border-t border-blueGray-100" />
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-500">Date</b>
                        </div>
                        <div className="w-8/12">
                            <b className="text-sm text-blueGray-700">
                                {getFormattedDate(data.service.date)}
                            </b>
                        </div>
                    </div>
                    {/* -------------------- START TIME -------------------- */}
                    {data.service.start_time && (
                        <>
                            <div className="border border-t border-blueGray-100" />
                            <div className="flex items-center w-full py-2">
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-500">Horaire de début</b>
                                </div>
                                <div className="w-8/12">
                                    <b className="text-sm text-blueGray-700">
                                        {getFormattedTime(data.service.start_time)}
                                    </b>
                                </div>
                            </div>
                        </>
                    )}
                    {/* -------------------- GUESTS NUMBER -------------------- */}
                    {data.service.guests_number && (
                        <>
                            <div className="border border-t border-blueGray-100" />
                            <div className="flex items-center w-full py-2">
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-500">Nombre de convives</b>
                                </div>
                                <div className="w-8/12">
                                    <b className="text-sm text-blueGray-700">
                                        {data.service.guests_number}
                                    </b>
                                </div>
                            </div>
                        </>
                    )}
                    {/* -------------------- PLACE -------------------- */}
                    {data.service.place && (
                        <>
                            <div className="border border-t border-blueGray-100" />
                            <div className="flex items-center w-full py-2">
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-500">Lieu</b>
                                </div>
                                <div className="w-8/12">
                                    <b className="text-sm text-blueGray-700">
                                        {data.service.place}
                                    </b>
                                </div>
                            </div>
                        </>
                    )}
                    {/* -------------------- EXTRAS -------------------- */}
                    <div className="border border-t border-blueGray-100" />
                    <div className="flex items-center w-full py-2">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-500">Extras liés</b>
                        </div>
                        <div className="w-8/12">
                            {data.service.extras && data.service.extras.length > 0 ? (
                                <button
                                    onClick={() => setState({ ...state, openExtras: true })}
                                    type="button"
                                    className="border border-blueGray-400 px-3 py-1 rounded text-blueGray-400 text-sm font-semibold uppercase
                                        hover:text-white hover:bg-blueGray-400"
                                >
                                    Voir la liste
                                </button>
                            ) : (
                                <b className="text-sm text-blueGray-600">Aucun</b>
                            )}
                        </div>
                    </div>
                    {/* -------------------- SERVICE PRODUCTS -------------------- */}
                    {(data.service.serviceProducts == null || data.service.serviceProducts.length === 0) && (
                        <>
                            <div className="border border-t border-blueGray-100" />
                            <div className="flex items-center w-full py-2">
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-500">Produits liés</b>
                                </div>
                                <div className="w-8/12">
                                    <b className="text-sm text-blueGray-700">
                                        Aucun
                                    </b>
                                </div>
                            </div>
                        </>
                    )}
                    {/* -------------------- OLD VERSIONS -------------------- */}
                    {isLastVersion && (
                        <>
                            <div className="border border-t border-blueGray-100" />
                            <div className="flex items-center w-full py-2">
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-500">Anciennes versions</b>
                                </div>
                                <div className="w-8/12">
                                    <button
                                        onClick={() => setState({ ...state, openOldVersions: true })}
                                        type="button"
                                        className="border border-blueGray-400 px-3 py-1 rounded text-blueGray-400 text-sm font-semibold uppercase
                                            hover:text-white hover:bg-blueGray-400"
                                    >
                                        Voir la liste
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    {/* -------------------- NOTE -------------------- */}
                    {data.service.note && (
                        <>
                            <div className="border border-t border-blueGray-100" />
                            <div className="flex items-center w-full py-2">
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-500">Note</b>
                                </div>
                                <div className="w-8/12">
                                    <b className="text-sm text-blueGray-700">
                                        {data.service.note}
                                    </b>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {/* -------------------- SERVICE PRODUCTS -------------------- */}
                {(data.service.serviceProducts != null && data.service.serviceProducts.length > 0) && (
                    <ServiceProducts
                        serviceProducts={data.service.serviceProducts}
                    />
                )}
            </Card>
            {/* --------------------------- EXTRAS --------------------------- */}
            {state.openExtras && (
                <Modal title="Extras liés à la prestation">
                    <Extras
                        onClose={() => setState({ ...state, openExtras: false })}
                        extras={data.service.extras}
                    />
                </Modal>
            )}
            {/* --------------------------- OLD VERSIONS --------------------------- */}
            {state.openOldVersions && (
                <Modal title="Anciennes versions">
                    <OldVersions
                        onClose={() => setState({ ...state, openOldVersions: false })}
                        serviceId={data.service.id}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer une prestation"
                >
                    <DeleteService
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={() => history.push('/app/services')}
                        service={data.service}
                    />
                </Modal>
            )}
            {/* --------------------------- RESTORE --------------------------- */}
            {state.openRestore && (
                <Modal title="Restaurer une version">
                    <RestoreService
                        onClose={() => setState({ ...state, openRestore: false })}
                        serviceId={data.service.id}
                    />
                </Modal>
            )}
        </>
    );
};

export default Service;
