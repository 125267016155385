import React, { useState } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Components
import SelectProductionArea from 'components/autocomplete/select-production-area';
import Input from 'components/input';
import ModalButtons from 'components/modal-buttons';

// Messages
import messages from 'messages.json';

// ----------------------------------------- ADD PRODUCTION AREA TO PRODUCT ----------------------------------------- \\
const AddProductionAreaToProduct = ({ onClose, onAdd, productionAreaProducts }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        name: '',
        productionArea: null,
    });

    const onAddProductionAreaToProduct = () => {
        if (state.name === '' || (state.productionArea == null || state.productionArea === '')) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            const updatedProductionAreaProducts = productionAreaProducts;
            updatedProductionAreaProducts.push({ name: state.name, productionAreaId: state.productionArea?.value, title: state.productionArea?.label + ' (' + state.name + ')' });
            onAdd(updatedProductionAreaProducts);
        }
    };

    return (
        <>
            <div className="p-6 w-full">
                {/* -------------------- PRODUCTION AREA -------------------- */}
                <div className="mb-4">
                    <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Zone de production
                        <span className="text-base text-indigo-700 ml-1">*</span>
                    </label>
                    <SelectProductionArea
                        productionArea={state.productionArea}
                        setProductionArea={productionArea => setState({ ...state, productionArea })}
                        productionAreaIds={productionAreaProducts.map(pap => pap.productionAreaId)}
                    />
                </div>
                {/* -------------------- NAME -------------------- */}
                <Input
                    placeholder="Nom du produit pour la zone"
                    required
                    value={state.name}
                    onChange={e => setState({ ...state, name: e.target.value })}
                />
            </div>
            <ModalButtons
                onClose={onClose}
                save={onAddProductionAreaToProduct}
                labelRight="Ajouter"
            />
        </>
    );
};

export default AddProductionAreaToProduct;
