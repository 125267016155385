import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Services
import { getFormattedDate } from 'services/helpers';

// Views
import DeleteBundle from '../delete-bundle';
import BundleDays from './bundle-days';
import ClientDescriptions from './client-descriptions';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Error from 'components/error';
import Modal from 'components/modal';

const BUNDLE = gql`
    query Bundle($id: ID!) {
        bundle(id: $id) {
            id
            startDate
            endDate
            bundleDays {
                id
                date
                productionSheets {
                    id
                    file_url
                    upToDate
                    productionArea {
                        id
                        name
                    }
                    services {
                        id
                        status
                        date
                        type
                        serviceCategory {
                            id
                            name
                        }
                        client {
                            id
                            name
                        }
                    }
                }
            }
            clientDescriptions {
                id
                file_url
                upToDate
            }
        }
    }
`;

// ------------------------------------------- BUNDLE ------------------------------------------- \\
const Bundle = () => {
    const history = useHistory();
    const { bundleId } = useParams();

    const [state, setState] = useState({
        page: 1,
        openDelete: false,
    });

    const { loading, error, data } = useQuery(BUNDLE, {
        variables: {
            id: bundleId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) return <Loading />;
    if (error || data.bundle == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex items-center p-3">
                    <div className="w-1/3">
                        <Button
                            label="Retour"
                            onClick={() => history.push('/app/bundles')}
                        />
                    </div>
                    <div className="w-1/3 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            Liasse du {getFormattedDate(data.bundle.startDate)} au {getFormattedDate(data.bundle.endDate)}
                        </h3>
                    </div>
                    <div className="w-1/3 text-right">
                        <button
                            onClick={() => setState({ ...state, openDelete: true })}
                            data-tip="Supprimer"
                            data-for="btn-round-delete"
                            type="button"
                            className="h-7 w-7 rounded-full border border-solid outline-none focus:outline-none ease-linear transition-all duration-150
                                bg-transparent border-blueGray-400 hover:border-blueGray-600 hover:bg-blueGray-600
                                font-bold uppercase text-xs text-blueGray-400 hover:text-white ml-2"
                        >
                            <i className="fas fa-trash" />
                        </button>
                        <ReactTooltip
                            id="btn-round-delete"
                            delayShow={1000}
                            delayHide={100}
                            textColor="#fff"
                            backgroundColor="#3730A3"
                            effect="solid"
                            place="bottom"
                        />
                    </div>
                </div>
                {/* -------------------- NAV -------------------- */}
                <div className="flex w-full">
                    <div
                        onClick={() => setState({ ...state, page: 1 })}
                        className={(state.page === 1 && 'bg-blueGray-100') + ' w-1/2 px-3 py-2 border border-blueGray-100 cursor-pointer text-center hover:bg-blueGray-200'}
                    >
                        <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                            Fiches de production
                        </label>
                    </div>
                    <div
                        onClick={() => setState({ ...state, page: 2 })}
                        className={(state.page === 2 && 'bg-blueGray-100') + ' w-1/2 px-3 py-2 border border-blueGray-100 cursor-pointer text-center hover:bg-blueGray-200'}
                    >
                        <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                            Descriptifs client
                        </label>
                    </div>
                </div>
                {state.page === 1 && (
                    <BundleDays
                        bundleDays={data.bundle.bundleDays}
                    />
                )}
                {state.page === 2 && (
                    <ClientDescriptions
                        clientDescriptions={data.bundle.clientDescriptions}
                    />
                )}
            </Card>
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer une liasse"
                >
                    <DeleteBundle
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={() => history.push('/app/bundles')}
                        bundle={data.bundle}
                    />
                </Modal>
            )}
        </>
    );
};

export default Bundle;
