import React, { useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const DELETE_PRODUCT = gql`
    mutation DeleteProduct($id: ID!) {
        deleteProduct(id: $id) {
            id
        }
    }
`;

// ------------------------------------------- DELETE PRODUCT ------------------------------------------- \\
const DeleteProduct = ({ onClose, refetch, product }) => {
    const { addToast } = useToasts();
    const [deleteProduct, { loading, error, data }] = useMutation(DELETE_PRODUCT);

    const onDeleteProduct = () => {
        deleteProduct({ variables: { id: product.id } });
    };

    useEffect(() => {
        if (data) {
            addToast('Produit supprimé', { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="text-center font-semibold text-base py-6">
                        <span>Êtes-vous sûr de vouloir supprimer ce produit ?</span>
                        <br />
                        <strong>{product.name}</strong>
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onDeleteProduct}
                        labelRight="Supprimer"
                    />
                </>
            )}
        </>
    );
};

export default DeleteProduct;
