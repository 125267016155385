import React from 'react';

// Services
import { URL } from 'services/apollo-config';

// ------------------------------------------- PHOTO DISPLAYER --------------------------------------------------- \\
const PhotoDisplayer = ({ onClick, fileUrl }) => {
    return (
        <div
            onClick={onClick}
            className="photo-displayer border-blueGray-300"
            style={{ backgroundImage: `url(${URL + fileUrl}` }}
        >
            <div>
                <i className="fas fa-pen" />
            </div>
        </div>  
    );
};

export default PhotoDisplayer;
