import React, { Fragment, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Assets
import { productStatuses } from 'assets/constants';

// Services
import { getColor, getLabel } from 'services/helpers';

// Views
import Product from './product';
import CreateProduct from './create-product';
import UpdateProduct from './update-product';
import DeleteProduct from './delete-product';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Badge from 'components/badge';
import Loading from 'components/loading';
import Error from 'components/error';
import Pagination from 'components/pagination';
import Modal from 'components/modal';
import InputSearch from 'components/input-search';
import UpdateDeleteButtons from 'components/update-delete-buttons';

const PRODUCTS = gql`
    query Products($name: String, $first: Int!, $page: Int!) {
        products(name: $name, first: $first, page: $page, orderBy: [{ column: NAME, order: ASC }]) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                status
                photo
                description
            }
        }
    }
`;

// ----------------------------------------- PRODUCT ROUTER ----------------------------------------------- \\
const ProductRouter = () => {
    return (
        <Switch>
            <Route exact path="/app/products">
                <Products />
            </Route>
            <Route exact path="/app/products/create">
                <CreateProduct />
            </Route>
            <Route exact path="/app/products/update/:productId">
                <UpdateProduct />
            </Route>
            <Route exact path="/app/products/:productId">
                <Product />
            </Route>
        </Switch>
    );
};

// ------------------------------------------- PRODUCTS --------------------------------------------------- \\
const Products = () => {
    const history = useHistory();
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    const [state, setState] = useState({
        page: 1,
        first: 20,
        searchName: '',
        product: null,
        openDelete: false,
    });

    const { loading, error, data, refetch } = useQuery(PRODUCTS, {
        variables: {
            first: state.first,
            page: state.page,
            name: '%' + state.searchName + '%',
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({
            ...state,
            openDelete: false,
        });
        refetch();
    };

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex flex-col lg:flex-row items-center justify-between p-3">
                    <InputSearch
                        placeholder="Chercher un produit"
                        onSearch={searchName => setState({ ...state, page: 1, searchName })}
                    />
                    <div className="mt-2 lg:mt-0">
                        <Button
                            label="Ajouter un produit"
                            onClick={() => history.push('/app/products/create')}
                        />
                    </div>
                </div>
                {/* -------------------- HEADERS -------------------- */}
                {mediaLG ? (
                    <div className="flex items-center bg-blueGray-100 text-xs uppercase font-semibold text-blueGray-500">                    
                        <div className="w-2/12 p-3">
                            Statut
                        </div>
                        <div className="w-8/12 p-3">
                            Nom
                        </div>
                        <div className="w-2/12 p-3 text-center">
                            Action
                        </div>
                    </div>
                ) : (
                    <div className="border-t border-blueGray-100" />
                )}
                {/* -------------------- LIST -------------------- */}
                <ProductList
                    loading={loading}
                    error={error}
                    data={data}
                    onRefetch={onRefetch}
                    state={state}
                    setState={setState}
                />
                {/* -------------------- PAGINATION -------------------- */}
                <Pagination
                    currentPage={state.page}
                    pageSize={state.first}
                    totalCount={data ? data.products.paginatorInfo.total : 0}
                    onChangePage={value => setState({ ...state, page: value })}
                />
            </Card>
        </>
    );
};

// ------------------------------------------ PRODUCT LIST ------------------------------------------------ \\
const ProductList = ({ loading, error, data, onRefetch, state, setState }) => {
    const history = useHistory();

    const onClickUpdate = (event, product) => {
        event.stopPropagation();
        history.push('/app/products/update/' + product.id);
    };

    const onClickDelete = (event, product) => {
        event.stopPropagation();
        setState({ ...state, openDelete: true, product });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {data.products.data.map((product, index) => (
                <Fragment key={product.id}>
                    {index !== 0 && (
                        <div className="border-t border-solid border-blueGray-100" />
                    )}
                    <div
                        onClick={() => history.push('/app/products/' + product.id)}
                        className="flex flex-wrap items-center cursor-pointer hover:bg-gray-100 text-center lg:text-left"
                    >
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                            <Badge
                                label={getLabel(productStatuses, product.status)}
                                color={getColor(productStatuses, product.status)}
                            />
                        </div>
                        <div className="w-full lg:w-8/12 px-3 py-2 lg:py-3">
                            <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                            <b className="text-sm text-blueGray-600">
                                {product.name}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3 text-center">
                            <UpdateDeleteButtons
                                onClickUpdate={e => onClickUpdate(e, product)}
                                onClickDelete={e => onClickDelete(e, product)}
                            />
                        </div>
                    </div>
                </Fragment>
            ))}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer un produit"
                >
                    <DeleteProduct
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={onRefetch}
                        product={state.product}
                    />
                </Modal>
            )}
        </>
    );
};

export default ProductRouter;
