import React, { Fragment, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Moment
import moment from 'moment';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import ServiceCategory from './service-category';
import CreateServiceCategory from './create-service-category';
import UpdateServiceCategory from './update-service-category';
import DeleteServiceCategory from './delete-service-category';

// Services
import { getLabel, getColor } from 'services/helpers';

// Assets
import { serviceCategoryStatuses } from 'assets/constants';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Error from 'components/error';
import Badge from 'components/badge';
import Pagination from 'components/pagination';
import Modal from 'components/modal';
import UpdateDeleteButtons from 'components/update-delete-buttons';
import InputSearch from 'components/input-search';

const SERVICE_CATEGORIES = gql`
    query ServiceCategories($name: String, $first: Int!, $page: Int!) {
        serviceCategories(
            name: $name,
            first: $first,
            page: $page,
            orderBy: [
                { column: PRIORITY, order: ASC },
                { column: NAME, order: ASC },
            ],
        ) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                start_date
                end_date
                status
                color
                description
                number
                priority
            }
        }
    }
`;

// -------------------------------------- SERVICE CATEGORY ROUTER ------------------------------------------ \\
const ServiceCategoryRouter = () => {
    return (
        <Switch>
            <Route exact path="/app/service-categories">
                <ServiceCategories />
            </Route>
            <Route exact path="/app/service-categories/:serviceCategoryId">
                <ServiceCategory />
            </Route>
        </Switch>
    );
};

// ---------------------------------------- SERVICE CATEGORIES --------------------------------------------- \\
const ServiceCategories = () => {
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    const [state, setState] = useState({
        page: 1,
        first: 20,
        searchName: '',
        serviceCategory: null,
        openCreate: false,
        openUpdate: false,
        openDelete: false,
    });

    const { loading, error, data, refetch } = useQuery(SERVICE_CATEGORIES, {
        variables: {
            first: state.first,
            page: state.page,
            name: '%' + state.searchName + '%',
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({
            ...state,
            openCreate: false,
            openUpdate: false,
            openDelete: false,
        });
        refetch();
    };

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex flex-col lg:flex-row items-center justify-between p-3">
                    <InputSearch
                        placeholder="Chercher une catégorie"
                        onSearch={searchName => setState({ ...state, page: 1, searchName })}
                    />
                    <div className="mt-2 lg:mt-0">
                        <Button
                            label="Ajouter une catégorie"
                            onClick={() => setState({ ...state, openCreate: true })}
                        />
                    </div>
                </div>
                {/* -------------------- HEADERS -------------------- */}
                {mediaLG ? (
                    <div className="flex items-center bg-blueGray-100 text-xs uppercase font-semibold text-blueGray-500">
                        <div className="w-2/12 p-3">
                            Statut
                        </div>
                        <div className="w-4/12 p-3">
                            Nom
                        </div>
                        <div className="w-2/12 p-3">
                            Date de début
                        </div>
                        <div className="w-2/12 p-3">
                            Date de fin
                        </div>
                        <div className="w-2/12 p-3 text-center">
                            Action
                        </div>
                    </div>
                ) : (
                    <div className="border-t border-blueGray-100" />
                )}
                {/* -------------------- LIST -------------------- */}
                <ServiceCategoryList
                    loading={loading}
                    error={error}
                    data={data}
                    onRefetch={onRefetch}
                    state={state}
                    setState={setState}
                />
                {/* -------------------- PAGINATION -------------------- */}
                <Pagination
                    currentPage={state.page}
                    pageSize={state.first}
                    totalCount={data ? data.serviceCategories.paginatorInfo.total : 0}
                    onChangePage={value => setState({ ...state, page: value })}
                />
            </Card>
            {/* --------------------------- CREATE --------------------------- */}
            {state.openCreate && (
                <Modal
                    title="Ajouter une catégorie de prestation"
                    required
                >
                    <CreateServiceCategory
                        onClose={() => setState({ ...state, openCreate: false })}
                        refetch={onRefetch}
                    />
                </Modal>
            )}
        </>
    );
};

// --------------------------------------- SERVICE CATEGORY LIST ------------------------------------------- \\
const ServiceCategoryList = ({ loading, error, data, onRefetch, state, setState }) => {
    const history = useHistory();

    const onClick = (event, open, serviceCategory) => {
        event.stopPropagation();
        setState({ ...state, [open]: true, serviceCategory });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {data.serviceCategories.data.map((serviceCategory, index) => (
                <Fragment key={serviceCategory.id}>
                    {index !== 0 && (
                        <div className="border-t border-solid border-blueGray-100" />
                    )}
                    <div
                        onClick={() => history.push('/app/service-categories/' + serviceCategory.id)}
                        className="flex flex-wrap text-center lg:text-left items-center cursor-pointer hover:bg-gray-100"
                    >
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                            <Badge
                                label={getLabel(serviceCategoryStatuses, serviceCategory.status)}
                                color={getColor(serviceCategoryStatuses, serviceCategory.status)}
                            />
                        </div>
                        <div className="w-full lg:w-4/12 px-3 py-2 lg:py-3">
                            <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                            <b className="text-sm text-blueGray-600">
                                {serviceCategory.name}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                            <b className="text-sm text-blueGray-600">
                                {moment(serviceCategory.start_date).format('DD MMMM')}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3">
                            <b className="text-sm text-blueGray-600">
                                {moment(serviceCategory.end_date).format('DD MMMM')}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3 text-center">
                            <UpdateDeleteButtons
                                onClickUpdate={e => onClick(e, 'openUpdate', serviceCategory)}
                                onClickDelete={e => onClick(e, 'openDelete', serviceCategory)}
                            />
                        </div>
                    </div>
                </Fragment>
            ))}
            {/* --------------------------- UPDATE --------------------------- */}
            {state.openUpdate && (
                <Modal
                    title="Modifier une catégorie de prestation"
                    required
                >
                    <UpdateServiceCategory
                        onClose={() => setState({ ...state, openUpdate: false })}
                        refetch={onRefetch}
                        serviceCategory={state.serviceCategory}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer une catégorie de prestation"
                >
                    <DeleteServiceCategory
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={onRefetch}
                        serviceCategory={state.serviceCategory}
                    />
                </Modal>
            )}
        </>
    );
};

export default ServiceCategoryRouter;
