import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

// Moment
import moment from 'moment';

// File-Saver
import { saveAs } from 'file-saver';

// Services
import { downloadClientDescriptionFromService, downloadClientDescriptionPDF } from 'services/requests';

// --------------------------------------- CLIENT DESCRIPTION --------------------------------------- \\
const ClientDescription = ({ service, clientDescription }) => {
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (loading) {
            if (clientDescription) {
                downloadClientDescriptionPDF(clientDescription.id).then(res => {
                    if (res.status === 200) {
                        saveAs(res.data, clientDescription?.file_url.split('clientDescriptions/')[1]);
                    }
                    else {
                        console.log(res);
                    }
                    setLoading(false);
                });
            }
            else {
                downloadClientDescriptionFromService(service.id).then(res => {
                    if (res.status === 200) {
                        saveAs(res.data, (moment().format('YYYY-MM-DD') + '_' + service.client?.name + '.pdf'));
                    }
                    else {
                        console.log(res);
                    }
                    setLoading(false);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <div className="flex items-center">
            <button
                onClick={() => setLoading(true)}
                disabled={loading}
                className={`py-1 px-3 rounded ${loading ? 'bg-gray-300 cursor-not-allowed' : 'text-white bg-pink-700 hover:bg-pink-600'}`}
            >
                {loading ? (
                    <>Téléchargement en cours...</>
                ) : (
                    <>Télécharger <i className="fas fa-download" style={{ fontSize: 16, marginLeft: 5 }}/></>
                )}
            </button>
            {(clientDescription?.upToDate === false) && (
                <>
                    <i
                        className="fas fa-exclamation-triangle"
                        data-tip="La version n'est plus à jour"
                        data-for="btn-up-to-date"
                        style={{ fontSize: 20, marginLeft: 10 }}
                    />
                    <ReactTooltip
                        id="btn-up-to-date"
                        delayShow={1000}
                        delayHide={100}
                        textColor="#fff"
                        backgroundColor="#3730A3"
                        effect="solid"
                        place="top"
                    />
                </>
            )}
        </div>
    );
};

export default ClientDescription;
