import React from 'react';
import Select from 'react-select';

// ---------------------------------------- CUSTOMIZED SELECT ------------------------------------------------ \\
const CustomizedSelect = ({ placeholder, value, onChange, options, isMulti, required }) => {
    return (
        <div className="mb-4">
            <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                {placeholder}
                {required && (
                    <span className="text-base text-indigo-700 ml-1">*</span>
                )}
            </label>
            <Select
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                options={options}
                isMulti={isMulti}
                isClearable={true}
                noOptionsMessage={() => 'Aucun'}
                menuPortalTarget={document.body}
                maxMenuHeight={200}
                styles={{
                    control: base => ({ ...base, fontSize: 14, outline: 'none', textDecoration: 'none' }),
                    menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                    container: base => ({ ...base, minWidth: 200, width: '100%' }),
                }}
            />
        </div>
    );
};

CustomizedSelect.defaultProps = {
    placeholder: "Sélectionner...",
    isMulti: false,
    required: false,
};

export default CustomizedSelect;
