import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Moment
import moment from 'moment';

// Services
import { getLabel } from 'services/helpers';

// Assets
import { serviceTypes } from 'assets/constants';

// Components
import Error from 'components/error';
import Calendar from 'components/calendar';

const SERVICES = gql`
    query Services($lastVersionId: Int, $startDate: Date, $endDate: Date) {
        services(first: 200, lastVersionId: $lastVersionId, startDate: $startDate, endDate: $endDate) {
            data {
                id
                date
                type
                status
                start_time
                client {
                    id
                    name
                }
                serviceCategory {
                    id
                    name
                    color
                }
            }
        }
    }
`;

// ------------------------------------ SERVICE CALENDAR ----------------------------------------- \\
const ServiceCalendar = () => {
    const history = useHistory();

    const [state, setState] = useState({
        startDate: moment(new Date()).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment(new Date()).endOf('month').add(7, 'days').format('YYYY-MM-DD'),
    });

    const { error, data } = useQuery(SERVICES, {
        variables: {
            lastVersionId: 0,
            startDate: state.startDate,
            endDate: state.endDate,
        },
        fetchPolicy: 'network-only',
    });

    const getEndTime = service => {
        let endTime = new Date(service.date + ' ' +  service.start_time);
        return new Date(endTime.setHours(endTime.getHours()+1));
    };

    const onNavigate = (date, view) => {
        let startDate, endDate;
        if (view === 'month') {
            startDate = moment(date).startOf('month').subtract(7, 'days').format('YYYY-MM-DD');
            endDate = moment(date).endOf('month').add(7, 'days').format('YYYY-MM-DD');
        }
        if (view === 'week') {
            startDate = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
            endDate = moment(date).endOf('isoWeek').format('YYYY-MM-DD');
        }
        if (view === 'day') {
            startDate = moment(date).startOf('day').format('YYYY-MM-DD');
            endDate = moment(date).endOf('day').format('YYYY-MM-DD');
        }
        if (view === 'agenda') {
            startDate = moment(date).startOf('day').format('YYYY-MM-DD');
            endDate = moment(date).endOf('day').add(1, 'month').format('YYYY-MM-DD');
        }
        setState({ ...state, startDate, endDate });
    }

    const onRangeChange = (range, view) => {
        if (view === 'month' || view === 'agenda') {
            let startDate, endDate;
            startDate = moment(range.start).format('YYYY-MM-DD');
            endDate = moment(range.end).format('YYYY-MM-DD');
            setState({ ...state, startDate, endDate });
        }
        if (view === 'week' || view === 'day') {
            let startDate, endDate;
            startDate = moment(range[0]).format('YYYY-MM-DD');
            endDate = moment(range[range.length - 1]).format('YYYY-MM-DD');
            setState({ ...state, startDate, endDate });
        }
    };

    console.log(state.startDate, state.endDate, data?.services?.data);

    if (error) return <Error />;

    return (
        <div className="p-6">
            <Calendar
                events={data ? data.services.data.map(service => ({
                    serviceId: service.id,
                    color: service.serviceCategory?.color || '#4a90e2',
                    title: service.client?.name + ' (' + getLabel(serviceTypes, service.type) + ')',
                    start: service.start_time ? new Date(service.date + ' ' +  service.start_time) : new Date(service.date),
                    end: service.start_time ? getEndTime(service) : new Date(service.date),
                    allDay: service.start_time ? false : true,
                })) : []}
                eventPropGetter={event => {
                    let newStyle = { backgroundColor: event.color };
                    return { style: newStyle };
                }}
                onNavigate={(newDate, view) => onNavigate(newDate, view)}
                onRangeChange={(range, view) => onRangeChange(range, view)}
                onSelectEvent={event => history.push('/app/services/' + event.serviceId)}
            />
        </div>
    );
};

export default ServiceCalendar;
