import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select'

// Apollo
import { gql, useQuery } from '@apollo/client';

// Lodash Debounce
import debounce from 'lodash.debounce';

const PRODUCT_CATEGORIES = gql`
    query ProductCategories($name: String, $first: Int!, $page: Int!) {
        productCategories(name: $name, first: $first, page: $page, orderBy: [{ column: NAME, order: ASC }]) {
            data {
                id
                name
            }
        }
    }
`;

// -------------------------------------- SELECT PRODUCT CATEGORY --------------------------------------- \\
const SelectProductCategory = ({ productCategory, setProductCategory, currentProdutCategoryId, isDisabled }) => {
    const [state, setState] = useState({
        inputValue: '',
        options: [],
    });

    const { loading, data } = useQuery(PRODUCT_CATEGORIES, {
        variables: {
            page: 1,
            first: 10,
            name: '%' + state.inputValue + '%',
        },
        fetchPolicy: 'network-only',
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, inputValue: value == null ? '' : value });
    };
    const delayedOnSearch = useRef(debounce(inputValue => onSearch(inputValue), 500)).current;

    const handleDelayedOnSearch = (value, reason) => {
        if (reason.action === 'input-change') {
            delayedOnSearch(value);
        }
    };

    useEffect(() => {
        if (data) {
            let productCategories = data.productCategories.data;
            
            if (currentProdutCategoryId) {
                productCategories = productCategories.filter(c => c.id !== currentProdutCategoryId);
            }

            setState({
                ...state,
                options: productCategories.map(c => ({ value: c.id, label: c.name })),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Select
            placeholder="Chercher une catégorie..."
            value={productCategory}
            onChange={value => setProductCategory(value)}
            onInputChange={(inputValue, reason) => handleDelayedOnSearch(inputValue, reason)}
            options={state.options}
            isLoading={loading}
            isClearable={true}
            isDisabled={isDisabled}
            noOptionsMessage={() => 'Aucune'}
            menuPortalTarget={document.body}
            maxMenuHeight={200}
            styles={{
                control: base => ({ ...base, fontSize: 14 }),
                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                container: base => ({ ...base, minWidth: 200, width: '100%' }),
            }}
        />
    );
};

SelectProductCategory.defaultProps = {
    isDisabled: false,
};

export default SelectProductCategory;
