import React, { useState } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Views
import ServiceProductForm from '../add-service-product/service-product-form';
import ModalButtons from 'components/modal-buttons';

// Messages
import messages from 'messages.json';

// -------------------------------------- UDPDATE SERVICE PRODUCT ------------------------------------ \\
const UpdateServiceProduct = ({ serviceProduct, serviceProducts, setServiceProducts, onClose }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        quantity: serviceProduct.quantity || '',
        unit: serviceProduct.unit || null,
        quantity_modality: serviceProduct.quantity_modality || null,
        note: serviceProduct.note || '',
    });

    const onUpdate = () => {
        if (state.quantity === '' || state.unit == null) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            let index = serviceProducts.findIndex(sp => sp.id === serviceProduct.id);
            let updatedServiceProducts = serviceProducts;
            updatedServiceProducts[index].quantity = Number(state.quantity);
            updatedServiceProducts[index].unit = state.unit;
            updatedServiceProducts[index].quantity_modality = state.quantity_modality || null;
            updatedServiceProducts[index].note = state.note;
            setServiceProducts(updatedServiceProducts);
        }
    };

    return (
        <>
            <div className="p-6 w-full">
                {/* -------------------- PRODUCT -------------------- */}
                <div className="mb-4 w-full">
                    <label className="uppercase text-blueGray-600 text-xs font-bold mb-1">
                        Produit
                    </label>
                    <br />
                    <label className="text-blueGray-800 text-sm">
                        {serviceProduct?.product?.label}
                    </label>
                </div>
                {/* -------------------- SERVICE PRODUCT FORM -------------------- */}
                <ServiceProductForm
                    state={state}
                    setState={setState}
                />
            </div>
            <ModalButtons
                onClose={onClose}
                save={onUpdate}
                labelRight="Valider"
            />
        </>
    );
};

export default UpdateServiceProduct;
