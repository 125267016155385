import React from 'react';

// Services
import { URL } from 'services/apollo-config';

// ---------------------------------------- ITEM CARD ---------------------------------------- \\
const ItemCard = ({ onClick, photo, title, icon, hover, empty, offSeason }) => {
    return (
        <div
            onClick={onClick}
            className={(hover && 'item-card-container-hover') + ' item-card-container text-blueGray-600 border-blueGray-400'}
        >
            {/* -------------------- PHOTO -------------------- */}
            {photo ? (
                <div
                    className="item-card-image"
                    style={{ backgroundImage: `url(${URL + photo})` }}
                />
            ) : (
                <div className="item-card-image">
                    <i className={`fas ${empty ? 'fa-times' : icon} text-3xl text-blueGray-400`} />
                </div>
            )}
            {/* -------------------- TITLE -------------------- */}
            <div className={(offSeason ? 'bg-red-400 ' : '') + "item-card-title border-blueGray-400"}>
                <label className="text-xs">
                    {empty ? 'Aucun(e)' : title}
                </label>
                {offSeason && (
                    <label className="text-xs ml-1" style={{ textTransform: 'none' }}>
                        (Hors période)
                    </label>
                )}
            </div>
        </div>
    );
};

ItemCard.defaultProps = {
    hover: false,
    empty: false,
    offSeason: false,
};

export default ItemCard;
