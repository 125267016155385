import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Services
import { clearToken } from 'services/storage';

// Components
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

const LOGOUT = gql`
    mutation {
        logout
    }
`;

// ------------------------------------------- LOGOUT ------------------------------------------- \\
const Logout = ({ onClose }) => {
    const history = useHistory();
    const [logout, { loading, error, data }] = useMutation(LOGOUT);

    useEffect(() => {
        if (data || error) {
            clearToken();
            history.push('/auth')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="text-center font-semibold text-base py-6">
                        <span>Êtes-vous sûr de vouloir vous déconnecter ?</span>
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={logout}
                        labelRight="Se déconnecter"
                    />
                </>
            )}
        </>
    );
};

export default Logout;
