import React, { Fragment } from 'react';

// Components
import Button from 'components/button';
import Scrollbar from 'components/scrollbar';

// ------------------------------------------ PRODUCT ORIGINS ------------------------------------------------ \\
const ProductOrigins = ({ onClose, productOrigins }) => {
    return (
        <>
            <div className="px-3 py-6 w-full">
                {/* -------------------- HEADERS -------------------- */}
                <div className="flex items-center bg-blueGray-100 py-3 px-6 text-xs uppercase font-semibold text-blueGray-500">
                    Nom
                </div>
                <Scrollbar maxHeight={200}>
                    {/* -------------------- LIST -------------------- */}
                    {productOrigins.map(productOrigin => (
                        <Fragment key={productOrigin.id}>
                            <div className="flex items-center py-3 px-6">
                                <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                                <b className="text-sm text-blueGray-600">
                                    {productOrigin.name}
                                </b>
                            </div>
                            <div className="border-t border-solid border-blueGray-100" />
                        </Fragment>
                    ))}
                </Scrollbar>
            </div>
            <div className="text-center p-4 border-t border-solid border-blueGray-200">
                <Button
                    label="fermer"
                    onClick={onClose}
                />
            </div>
        </>
    );
};

export default ProductOrigins;
