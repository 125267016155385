import React, { useState } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Views
import SearchProductByName from './search-product-by-name';
import SearchProductByProductCategories from './search-product-by-product-categories';
import SearchProductByServiceCategories from './search-product-by-service-categories';

// Components
import ModalButtons from 'components/modal-buttons';

// Messages
import messages from 'messages.json';

// -------------------------------------- ADD SERVICE PRODUCT ------------------------------------ \\
const AddServiceProduct = ({ date, serviceProductId, serviceProducts, setServiceProducts, onClose }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        page: 'research-choice',
        breadcrumb: [],
        productCategoryId: null,
        serviceCategoryId: null,

        // Service Product
        product: null,
        quantity: '',
        unit: null,
        quantity_modality: null,
        note: '',
    });

    const onAdd = () => {
        if (state.product == null) {
            addToast('Veuillez sélectionner un produit', { appearance: 'warning' });
        }
        else if (state.quantity === '' || state.unit == null) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            let updatedServiceProducts = serviceProducts;
            
            updatedServiceProducts.push({
                id: serviceProductId,
                product: state.product,
                quantity: Number(state.quantity),
                unit: state.unit,
                quantity_modality: state.quantity_modality || null,
                note: state.note,
            });

            // Increase Service Product ID
            let updatedServiceProductId = 'SP' + (Number(serviceProductId.split('SP')[1]) + 1);
            setServiceProducts(updatedServiceProductId, updatedServiceProducts);
        }
    };

    return (
        <>
            <div className="p-6 w-full">
                {/* -------------------- RESEARCH CHOICE -------------------- */}
                {state.page === 'research-choice' && (
                    <ResearchChoice
                        state={state}
                        setState={setState}
                    />
                )}
                {/* -------------------- SEARCH BY NAME -------------------- */}
                {state.page === 'search-by-name' && (
                    <SearchProductByName
                        date={date}
                        state={state}
                        setState={setState}
                    />
                )}
                {/* -------------------- SEARCH BY PRODUCT CATEGORIES -------------------- */}
                {state.page === 'search-by-product-categories' && (
                    <SearchProductByProductCategories
                        date={date}
                        state={state}
                        setState={setState}
                    />
                )}
                {/* -------------------- SEARCH BY SERVICE CATEGORIES -------------------- */}
                {state.page === 'search-by-service-categories' && (
                    <SearchProductByServiceCategories
                        date={date}
                        state={state}
                        setState={setState}
                    />
                )}
            </div>
            <ModalButtons
                onClose={onClose}
                save={onAdd}
                labelRight="Ajouter"
            />
        </>
    );
};

// ---------------------------------------- RESEARCH CHOICE --------------------------------------- \\
const ResearchChoice = ({ state, setState }) => {
    return (
        <>
            <div className="mb-3">
                <label className="uppercase text-blueGray-600 text-xs font-bold">
                    Chercher un produit à partir :
                </label>
            </div>
            <div className="flex items-center justify-around">
                <div
                    onClick={() => setState({ ...state, page: 'search-by-name' })}
                    className="research-choice rounded-lg border-2 border-blueGray-100 flex items-center justify-center p-6 text-center cursor-pointer hover:bg-blueGray-100"
                >
                    <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                        de son nom
                    </label>
                </div>
                <div
                    onClick={() => setState({ ...state, page: 'search-by-product-categories' })}
                    className="research-choice rounded-lg border-2 border-blueGray-100 flex items-center justify-center p-6 text-center cursor-pointer hover:bg-blueGray-100"
                >
                    <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                        des catégories de produit
                    </label>
                </div>
                <div
                    onClick={() => setState({ ...state, page: 'search-by-service-categories' })}
                    className="research-choice rounded-lg border-2 border-blueGray-100 flex items-center justify-center p-6 text-center cursor-pointer hover:bg-blueGray-100"
                >
                    <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                        des catégories de prestation
                    </label>
                </div>
            </div>
        </>
    );
};

export default AddServiceProduct;
