import React from 'react';

// -------------------------------------- MODAL BUTTONS --------------------------------------------- \\
const ModalButtons = ({ onClose, save, labelLeft, labelRight }) => {
    return (
        <div className="flex items-center justify-end border-t border-solid border-blueGray-200 px-0">
            <div className="p-4">
                <button
                    onClick={onClose}
                    type="button"
                    className="border-indigo-900 hover:border-orange-500 border rounded background-transparent px-6 py-3 outline-none focus:outline-none mr-2 ease-linear transition-all duration-150
                        text-xs text-indigo-900 hover:text-orange-500 font-bold uppercase"
                >
                    {labelLeft}
                </button>
                <button
                    onClick={save}
                    type="submit"
                    className="bg-indigo-500 hover:bg-green-600 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150
                        text-white text-xs"
                >
                    {labelRight}
                </button>
            </div>
        </div>
    );
};

ModalButtons.defaultProps = {
    labelLeft: 'Annuler',
    labelRight: 'Sauvegarder',
};

export default ModalButtons;
