import React, { Fragment } from 'react';

// Assets
import { productStatuses } from 'assets/constants';

// Services
import { getColor, getLabel } from 'services/helpers';

// Components
import Button from 'components/button';
import Scrollbar from 'components/scrollbar';

// ------------------------------------------ PRODUCTS ------------------------------------------------ \\
const Products = ({ onClose, products }) => {
    return (
        <>
            <div className="px-3 py-6 w-full">
                {/* -------------------- HEADERS -------------------- */}
                <div className="flex items-center bg-blueGray-100 py-3 px-6">
                    <div className="w-4/12 text-xs uppercase font-semibold text-blueGray-500">
                        Statut
                    </div>
                    <div className="w-4/12 text-xs uppercase font-semibold text-blueGray-500">
                        Nom
                    </div>
                    <div className="w-4/12 text-xs uppercase font-semibold text-blueGray-500">
                        Catégorie
                    </div>
                </div>
                <Scrollbar maxHeight={200}>
                    {/* -------------------- LIST -------------------- */}
                    {products.map(product => (
                        <Fragment key={product.id}>
                            <div className="flex items-center py-3 px-6">
                                <div className="w-4/12">
                                    <div
                                        className="flex w-max rounded-full px-2 py-1"
                                        style={{ backgroundColor: getColor(productStatuses, product.status) }}
                                    >
                                        <b className="text-xs text-white uppercase">
                                            {getLabel(productStatuses, product.status)}
                                        </b>
                                    </div>
                                </div>
                                <div className="w-4/12">
                                    <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                                    <b className="text-sm text-blueGray-600">
                                        {product.name}
                                    </b>
                                </div>
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-600">
                                        {product.productCategory.name}
                                    </b>
                                </div>
                            </div>
                            <div className="border-t border-solid border-blueGray-100" />
                        </Fragment>
                    ))}
                </Scrollbar>
            </div>
            <div className="text-center p-4 border-t border-solid border-blueGray-200">
                <Button
                    label="fermer"
                    onClick={onClose}
                />
            </div>
        </>
    );
};

export default Products;
