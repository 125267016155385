import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Views
import ExtraForm from './extra-form';

// Components
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const UPDATE_EXTRA = gql`
    mutation updateExtra($id: ID!, $name: String!, $description: String, $extraCategoryId: ID!) {
        updateExtra(input: {
            id: $id,
            attributes: {
                name: $name,
                description: $description,
                extraCategory: { connect: $extraCategoryId }
            }
        }) {
            id
        }
    }
`;

// ------------------------------------------- UPDATE EXTRA ------------------------------------------- \\
const UpdateExtra = ({ onClose, refetch, extra }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        name: extra.name || '',
        description: extra.description || '',
        extraCategory: extra.extraCategory ? ({ value: extra.extraCategory.id, label: extra.extraCategory.name }) : null,
    });

    const [updateExtra, { loading, error, data }] = useMutation(UPDATE_EXTRA);

    const onUpdateExtra = () => {
        if (state.name === '' || state.extraCategory == null) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            updateExtra({
                variables: {
                    id: extra.id,
                    name: state.name,
                    description: state.description,
                    extraCategoryId: state.extraCategory.value,
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['extra.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <ExtraForm state={state} setState={setState} />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onUpdateExtra}
                        labelRight="Enregistrer"
                    />
                </>
            )}
        </>
    );
};

export default UpdateExtra;
