import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import Products from './products';
import UpdateProductCategory from '../update-product-category';
import DeleteProductCategory from '../delete-product-category';
import UploadProductCategoryPhoto from './upload-product-category-photo';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';
import Modal from 'components/modal';
import PhotoSelector from 'components/photo-selector';
import PhotoDisplayer from 'components/photo-displayer';
import UpdateDeleteButtons from 'components/update-delete-buttons';

const PRODUCT_CATEGORY = gql`
    query ProductCategory($id: ID!) {
        productCategory(id: $id) {
            id
            name
            photo
            description
            parentProductCategory {
                id
                name
            }
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
            products {
                id
                name
                status
                productCategory {
                    name
                }
            }
        }
    }
`;

// ------------------------------------------- PRODUCT CATEGORY --------------------------------------------------- \\
const ProductCategory = () => {
    const history = useHistory();
    const { productCategoryId } = useParams();

    const [state, setState] = useState({
        openUpdate: false,
        openDelete: false,
        openPhoto: false,
        openProducts: false,
    });

    const { loading, error, data, refetch } = useQuery(PRODUCT_CATEGORY, {
        variables: {
            id: productCategoryId,
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({
            ...state,
            openUpdate: false,
            openPhoto: false,
            openProducts: false,
        });
        refetch();
    };

    if (loading) return <Loading />;
    if (error || data.productCategory == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex items-center p-3">
                    <div className="w-1/3">
                        <Button
                            label="Retour"
                            onClick={() => history.push('/app/product-categories')}
                        />
                    </div>
                    <div className="w-1/3 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            {data.productCategory.name}
                        </h3>
                    </div>
                    <div className="w-1/3 text-right">
                        <UpdateDeleteButtons
                            onClickUpdate={e => setState({ ...state, openUpdate: true })}
                            onClickDelete={e => setState({ ...state, openDelete: true })}
                        />
                    </div>
                </div>
                {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
                <div className="flex items-center justify-between bg-blueGray-100 p-3">
                    {data.productCategory.creator && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Créateur : {data.productCategory.creator.fullName}
                        </div>
                    )}
                    {data.productCategory.lastModifier && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Dernière modification : {data.productCategory.lastModifier.fullName}
                        </div>
                    )}
                </div>
                <div className="flex flex-col lg:flex-row items-center p-6">
                    {/* -------------------- INFOS -------------------- */}
                    <div className="w-12/12 lg:w-9/12">
                        {/* -------------------- PRODUCTS -------------------- */}
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-500">Produits liés</b>
                            </div>
                            <div className="w-8/12">
                                {data.productCategory.products && data.productCategory.products.length > 0 ? (
                                    <button
                                        onClick={() => setState({ ...state, openProducts: true })}
                                        type="button"
                                        className="border border-blueGray-400 px-3 py-1 rounded text-blueGray-400 text-sm font-semibold uppercase
                                            hover:text-white hover:bg-blueGray-400"
                                    >
                                        Voir la liste
                                    </button>
                                ) : (
                                    <b className="text-sm text-blueGray-600">Aucun</b>
                                )}
                            </div>
                        </div>
                        {/* -------------------- PARENT PRODUCT CATEGORY -------------------- */}
                        {data.productCategory.parentProductCategory && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Catégorie parent</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.productCategory.parentProductCategory.name}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* -------------------- DESCRIPTION -------------------- */}
                        {data.productCategory.description && (
                            <>
                                <div className="border border-t border-blueGray-100" />
                                <div className="flex items-center w-full py-2">
                                    <div className="w-4/12">
                                        <b className="text-sm text-blueGray-500">Description</b>
                                    </div>
                                    <div className="w-8/12">
                                        <b className="text-sm text-blueGray-700">
                                            {data.productCategory.description}
                                        </b>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* -------------------- PHOTO -------------------- */}
                    <div className="w-12/12 lg:w-3/12 flex items-center justify-center">
                        {(data.productCategory.photo == null || data.productCategory.photo === '') ? (
                            <PhotoSelector
                                onClick={() => setState({ ...state, openPhoto: true })}
                            />
                        ) : (
                            <PhotoDisplayer
                                onClick={() => setState({ ...state, openPhoto: true })}
                                fileUrl={data.productCategory.photo}
                            />
                        )}
                    </div>
                </div>
            </Card>
            {/* --------------------------- PRODUCTS --------------------------- */}
            {state.openProducts && (
                <Modal title={'Produits liés à la catégorie : ' + data.productCategory.name}>
                    <Products
                        onClose={() => setState({ ...state, openProducts: false })}
                        products={data.productCategory.products}
                    />
                </Modal>
            )}  
            {/* --------------------------- PHOTO --------------------------- */}
            {state.openPhoto && (
                <Modal
                    title="Ajouter une photo"
                    required
                >
                    <UploadProductCategoryPhoto
                        onClose={() => setState({ ...state, openPhoto: false })}
                        refetch={onRefetch}
                        productCategoryId={productCategoryId}
                    />
                </Modal>
            )}
            {/* --------------------------- UPDATE --------------------------- */}
            {state.openUpdate && (
                <Modal
                    title="Modifier une catégorie de produit"
                    required
                >
                    <UpdateProductCategory
                        onClose={() => setState({ ...state, openUpdate: false })}
                        refetch={onRefetch}
                        productCategory={data.productCategory}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer une catégorie de produit"
                >
                    <DeleteProductCategory
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={() => history.push('/app/product-categories')}
                        productCategory={data.productCategory}
                    />
                </Modal>
            )}
        </>
    );
};

export default ProductCategory;
