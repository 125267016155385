import React from 'react';
import { useLocation } from 'react-router-dom';

// Views
import ProfileMenu from '../profile-menu';

// ------------------------------------------- HEADER --------------------------------------------------- \\
const Header = () => {
    const location = useLocation();

    return (
        <div className="w-full py-6 px-6 xl:px-12 bg-indigo-900 height-header flex justify-between">
            {/* -------------------- TITLE -------------------- */}
            <div className="text-white text-sm uppercase font-semibold">
                {location.pathname.includes('services') && (
                    <span>Prestations</span>
                )}
                {location.pathname.includes('bundles') && (
                    <span>Liasses</span>
                )}
                {location.pathname.includes('clients') && (
                    <span>Clients</span>
                )}
                {location.pathname.includes('products') && (
                    <span>Produits</span>
                )}
                {location.pathname.includes('product-categories') && (
                    <span>Catégories de produit</span>
                )}
                {location.pathname.includes('product-origins') && (
                    <span>Origine des produits</span>
                )}
                {location.pathname.includes('product-origin-categories') && (
                    <span>Catégories des origines de produit</span>
                )}
                {location.pathname.includes('complements') && (
                    <span>Compléments</span>
                )}
                {location.pathname.includes('allergens') && (
                    <span>Allergènes</span>
                )}
                {location.pathname.includes('extras') && (
                    <span>Extras</span>
                )}
                {location.pathname.includes('extra-categories') && (
                    <span>Catégories d'extra</span>
                )}
                {location.pathname.includes('description-models') && (
                    <span>Modèles de descriptif</span>
                )}
                {location.pathname.includes('production-areas') && (
                    <span>Zones de production</span>
                )}
                {location.pathname.includes('service-categories') && (
                    <span>Catégorie de prestation</span>
                )}
                {location.pathname.includes('users') && (
                    <span>Utilisateurs</span>
                )}
            </div>
            {/* -------------------- PROFILE -------------------- */}
            <ProfileMenu />
        </div>
    );
};

export default Header;
