import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Services
import { getLabel } from 'services/helpers';

// Assets
import { productionAreaTypes } from 'assets/constants';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';

const PRODUCTION_AREA = gql`
    query ProductionArea($id: ID!) {
        productionArea(id: $id) {
            id
            name
            type
            description
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
        }
    }
`;

// ------------------------------------------- PRODUCTION AREA --------------------------------------------------- \\
const ProductionArea = () => {
    const history = useHistory();
    const { productionAreaId } = useParams();

    const { loading, error, data } = useQuery(PRODUCTION_AREA, {
        variables: {
            id: productionAreaId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) return <Loading />;
    if (error || data.productionArea == null) return <Error />;

    return (
        <Card>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center p-3">
                <div className="w-1/3">
                    <Button
                        label="Retour"
                        onClick={() => history.push('/app/production-areas')}
                    />
                </div>
                <div className="w-1/3 text-center">
                    <h3 className="font-semibold text-base text-blueGray-700">
                        {data.productionArea.name}
                    </h3>
                </div>
            </div>
            {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
            <div className="flex items-center justify-between bg-blueGray-100 p-3">
                {data.productionArea.creator && (
                    <div className="text-xs uppercase font-semibold text-blueGray-500">
                        Créateur : {data.productionArea.creator.fullName}
                    </div>
                )}
                {data.productionArea.lastModifier && (
                    <div className="text-xs uppercase font-semibold text-blueGray-500">
                        Dernière modification : {data.productionArea.lastModifier.fullName}
                    </div>
                )}
            </div>
            <div className="p-6">
                {/* -------------------- TYPE -------------------- */}
                <div className="flex items-center w-full py-2">
                    <div className="w-4/12">
                        <b className="text-sm text-blueGray-600">Type</b>
                    </div>
                    <div className="w-8/12">
                        <b className="text-sm text-blueGray-600">
                            {getLabel(productionAreaTypes, data.productionArea.type)}
                        </b>
                    </div>
                </div>
                {/* -------------------- DESCRIPTION -------------------- */}
                {data.productionArea.description && (
                    <>
                        <div className="border border-t border-blueGray-100" />
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-600">Description</b>
                            </div>
                            <div className="w-8/12">
                                <b className="text-sm text-blueGray-600">
                                    {data.productionArea.description}
                                </b>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Card>
    );
};

export default ProductionArea;
