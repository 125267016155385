import React from 'react';
import ReactTooltip from 'react-tooltip';

// -------------------------------------- UPDATE DELETE BUTTONS --------------------------------------------- \\
const UpdateDeleteButtons = ({ onClickUpdate, onClickDelete, tooltipUpdate, tooltipDelete, tooltipPlace }) => {
    return (
        <>
            <button
                onClick={onClickUpdate}
                data-tip={tooltipUpdate}
                data-for="btn-round-update"
                type="button"
                className="h-7 w-7 rounded-full border border-solid outline-none focus:outline-none ease-linear transition-all duration-150
                    bg-transparent border-blueGray-400 hover:border-blueGray-600 hover:bg-blueGray-600
                    font-bold uppercase text-xs text-blueGray-400 hover:text-white"
            >
                <i className="fas fa-pen" />
            </button>
            <button
                onClick={onClickDelete}
                data-tip={tooltipDelete}
                data-for="btn-round-delete"
                type="button"
                className="h-7 w-7 rounded-full border border-solid outline-none focus:outline-none ease-linear transition-all duration-150
                    bg-transparent border-blueGray-400 hover:border-blueGray-600 hover:bg-blueGray-600
                    font-bold uppercase text-xs text-blueGray-400 hover:text-white ml-2"
            >
                <i className="fas fa-trash" />
            </button>
            <ReactTooltip
                id="btn-round-update"
                delayShow={1000}
                delayHide={100}
                textColor="#fff"
                backgroundColor="#3730A3"
                effect="solid"
                place={tooltipPlace}
            />
            <ReactTooltip
                id="btn-round-delete"
                delayShow={1000}
                delayHide={100}
                textColor="#fff"
                backgroundColor="#3730A3"
                effect="solid"
                place={tooltipPlace}
            />
        </>
    );
};

UpdateDeleteButtons.defaultProps = {
    tooltipUpdate: 'Modifier',
    tooltipDelete: 'Supprimer',
    tooltipPlace: 'top',
};

export default UpdateDeleteButtons;
