import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import ProductOrigins from './product-origins';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';
import Modal from 'components/modal';

const PRODUCT_ORIGIN_CATEGORY = gql`
    query ProductOriginCategory($id: ID!) {
        productOriginCategory(id: $id) {
            id
            name
            productOrigins {
                id
                name
            }
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
        }
    }
`;

// ------------------------------------------- PRODUCT ORIGIN CATEGORY --------------------------------------------------- \\
const ProductOriginCategory = () => {
    const history = useHistory();
    const { productOriginCategoryId } = useParams();
    const [open, setOpen] = useState(false);

    const { loading, error, data } = useQuery(PRODUCT_ORIGIN_CATEGORY, {
        variables: {
            id: productOriginCategoryId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) return <Loading />;
    if (error || data.productOriginCategory == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex items-center p-3">
                    <div className="w-1/3">
                        <Button
                            label="Retour"
                            onClick={() => history.push('/app/product-origin-categories')}
                        />
                    </div>
                    <div className="w-1/3 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            {data.productOriginCategory.name}
                        </h3>
                    </div>
                </div>
                {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
                <div className="flex items-center justify-between bg-blueGray-100 p-3">
                    {data.productOriginCategory.creator && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Créateur : {data.productOriginCategory.creator.fullName}
                        </div>
                    )}
                    {data.productOriginCategory.lastModifier && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Dernière modification : {data.productOriginCategory.lastModifier.fullName}
                        </div>
                    )}
                </div>
                <div className="p-6">
                    {/* -------------------- PRODUCT ORIGINS -------------------- */}
                    <div className="flex items-center w-full py-1">
                        <div className="w-4/12">
                            <b className="text-sm text-blueGray-600">Origines de produit liées</b>
                        </div>
                        <div className="w-8/12">
                            {data.productOriginCategory.productOrigins && data.productOriginCategory.productOrigins.length > 0 ? (
                                <button
                                    onClick={() => setOpen(true)}
                                    type="button"
                                    className="border border-blueGray-400 px-3 py-1 rounded text-blueGray-400 text-sm font-semibold uppercase
                                        hover:text-white hover:bg-blueGray-400"
                                >
                                    Voir la liste
                                </button>
                            ) : (
                                <b className="text-sm text-blueGray-600">Aucune</b>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
            {/* --------------------------- PRODUCT ORIGINS --------------------------- */}
            {open && (
                <Modal title={'Origines de produit liées à la catégorie : ' + data.productOriginCategory.name}>
                    <ProductOrigins
                        onClose={() => setOpen(false)}
                        productOrigins={data.productOriginCategory.productOrigins}
                    />
                </Modal>
            )}
        </>
    );
};

export default ProductOriginCategory;
