import React from 'react';

// Components
import Input from 'components/input';
import SelectProductOriginCategory from 'components/autocomplete/select-product-origin-category';

// ------------------------------------------- PRODUCT ORIGIN FORM ------------------------------------------- \\
const ProductOriginForm = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <Input
                value={state.name}
                onChange={e => setState({ ...state, name: e.target.value })}
                placeholder="Nom"
                required
            />
            {/* -------------------- PRODUCT ORIGIN CATEGORY -------------------- */}
            <div>
                <label className="uppercase text-blueGray-600 text-xs font-bold">
                    Catégorie de l'origine
                    <span className="text-base text-indigo-700 ml-1">*</span>
                </label>
                <SelectProductOriginCategory
                    productOriginCategory={state.productOriginCategory}
                    setProductOriginCategory={productOriginCategory => setState({ ...state, productOriginCategory })}
                />
            </div>
        </>
    );
};

export default ProductOriginForm;
