import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

// CSS
import 'react-datepicker/dist/react-datepicker.css';

// Locale
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

// -------------------------------------- CUSTOMIZED DATE PICKER ---------------------------------------------- \\
const CustomizedDatePicker = ({ placeholder, value, onChange, required }) => {
    return (
        <div className="w-full mb-4">
            {/* -------------------- TITLE -------------------- */}
            <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                {placeholder}
                {required && (
                    <span className="text-base text-indigo-700 ml-1">*</span>
                )}
            </label>
            {/* -------------------- DATE PICKER -------------------- */}
            <DatePicker
                locale="fr"
                selected={value}
                onChange={onChange}
                wrapperClassName="w-full"
                dateFormat="dd/MM/yyyy"
                showPopperArrow={false}
                placeholderText={placeholder}
                customInput={
                    <input
                        className="w-full p-3 rounded shadow border-0 text-sm"
                    />
                }
            />
        </div>
    );
};

CustomizedDatePicker.defaultProps = {
    placeholder: 'Date',
    required: false,
};

export default CustomizedDatePicker;
