import React, { Fragment, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ReactTooltip from 'react-tooltip';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import User from './user';
import CreateUser from './create-user';
import UpdateUser from './update-user';
import DeleteUser from './delete-user';
import UpdateUserPassword from './update-user-password';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Error from 'components/error';
import Pagination from 'components/pagination';
import Modal from 'components/modal';
import InputSearch from 'components/input-search';
import UpdateDeleteButtons from 'components/update-delete-buttons';

const USERS = gql`
    query Users($search: String, $first: Int!, $page: Int!) {
        users (search: $search, first: $first, page: $page, orderBy: [{ column: LAST_NAME, order: ASC }]) {
            paginatorInfo {
                total
            }
            data {
                id
                fullName
                last_name
                first_name
                email
                phone
                mobile_phone
            }
        }
    }
`;

// ----------------------------------------- USER ROUTER ----------------------------------------------- \\
const UserRouter = () => {
    return (
        <Switch>
            <Route exact path="/app/users">
                <Users />
            </Route>
            <Route exact path="/app/users/:userId">
                <User />
            </Route>
        </Switch>
    );
};

// ------------------------------------------- USERS --------------------------------------------------- \\
const Users = () => {
    const mediaLG = useMediaQuery({ minWidth: 1024 });

    const [state, setState] = useState({
        page: 1,
        first: 20,
        searchName: '',
        user: null,
        openCreate: false,
        openUpdate: false,
        openDelete: false,
        openPassword: false,
    });

    const { loading, error, data, refetch } = useQuery(USERS, {
        variables: {
            first: state.first,
            page: state.page,
            search: '%' + state.searchName + '%',
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({
            ...state,
            openCreate: false,
            openUpdate: false,
            openDelete: false,
            openPassword: false,
        });
        refetch();
    };

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex flex-col lg:flex-row items-center justify-between p-3">
                    <InputSearch
                        placeholder="Chercher un utilisateur"
                        onSearch={searchName => setState({ ...state, page: 1, searchName })}
                    />
                    <div className="mt-2 lg:mt-0">
                        <Button
                            label="Ajouter un utilisateur"
                            onClick={() => setState({ ...state, openCreate: true })}
                        />
                    </div>
                </div>
                {/* -------------------- HEADERS -------------------- */}
                {mediaLG ? (
                    <div className="flex items-center bg-blueGray-100 text-xs uppercase font-semibold text-blueGray-500">
                        <div className="w-3/12 p-3">
                            Nom
                        </div>
                        <div className="w-3/12 p-3">
                            Prénom
                        </div>
                        <div className="w-4/12 p-3">
                            Email
                        </div>
                        <div className="w-2/12 p-3 text-center">
                            Action
                        </div>
                    </div>
                ) : (
                    <div className="border-t border-blueGray-100" />
                )}
                {/* -------------------- LIST -------------------- */}
                <UserList
                    loading={loading}
                    error={error}
                    data={data}
                    onRefetch={onRefetch}
                    state={state}
                    setState={setState}
                />
                {/* -------------------- PAGINATION -------------------- */}
                <Pagination
                    currentPage={state.page}
                    pageSize={state.first}
                    totalCount={data ? data.users.paginatorInfo.total : 0}
                    onChangePage={value => setState({ ...state, page: value })}
                />
            </Card>
            {/* --------------------------- CREATE --------------------------- */}
            {state.openCreate && (
                <Modal
                    title="Ajouter un utilisateur"
                    required
                >
                    <CreateUser
                        onClose={() => setState({ ...state, openCreate: false })}
                        refetch={onRefetch}
                    />
                </Modal>
            )}
        </>
    );
};

// ------------------------------------------ USER LIST ------------------------------------------------ \\
const UserList = ({ loading, error, data, onRefetch, state, setState }) => {
    const history = useHistory();

    const onClick = (event, open, user) => {
        event.stopPropagation();
        setState({ ...state, [open]: true, user });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {data.users.data.map((user, index) => (
                <Fragment key={user.id}>
                    {index !== 0 && (
                        <div className="border-t border-solid border-blueGray-100" />
                    )}
                    <div
                        onClick={() => history.push('/app/users/' + user.id)}
                        className="flex flex-wrap text-center lg:text-left items-center cursor-pointer hover:bg-gray-100"
                    >
                        <div className="w-full lg:w-3/12 px-3 py-2 lg:py-3">
                            <i className="fas fa-circle text-xxs text-blueGray-400 mr-2 align-middle" />
                            <b className="text-sm text-blueGray-600">
                                {user.last_name}
                            </b>
                        </div>
                        <div className="w-full lg:w-3/12 px-3 py-2 lg:py-3">
                            <b className="text-sm text-blueGray-600">
                                {user.first_name}
                            </b>
                        </div>
                        <div className="w-full lg:w-4/12 px-3 py-2 lg:py-3 break-words">
                            <b className="text-sm text-blueGray-600">
                                {user.email}
                            </b>
                        </div>
                        <div className="w-full lg:w-2/12 px-3 py-2 lg:py-3 text-center">
                            <button
                                onClick={e => onClick(e, 'openPassword', user)}
                                data-tip="Changer le mot de passe"
                                data-for="btn-round-password"
                                type="button"
                                className="h-7 w-7 rounded-full border border-solid outline-none focus:outline-none ease-linear transition-all duration-150
                                    bg-transparent border-blueGray-400 hover:border-blueGray-600 hover:bg-blueGray-600
                                    font-bold uppercase text-xs text-blueGray-400 hover:text-white mr-2"
                            >
                                <i className="fas fa-unlock-alt" />
                            </button>
                            <ReactTooltip
                                id='btn-round-password'
                                delayShow={1000}
                                delayHide={100}
                                textColor='#fff'
                                backgroundColor='#3730A3'
                                effect='solid'
                            />
                            <UpdateDeleteButtons
                                onClickUpdate={e => onClick(e, 'openUpdate', user)}
                                onClickDelete={e => onClick(e, 'openDelete', user)}
                            />
                        </div>
                    </div>
                </Fragment>
            ))}
            {/* --------------------------- UPDATE --------------------------- */}
            {state.openUpdate && (
                <Modal
                    title="Modifier un utilisateur"
                    required
                >
                    <UpdateUser
                        onClose={() => setState({ ...state, openUpdate: false })}
                        refetch={onRefetch}
                        user={state.user}
                    />
                </Modal>
            )}
            {/* --------------------------- UPDATE PASSWORD --------------------------- */}
            {state.openPassword && (
                <Modal
                    title={`Changer le mot de passe de : ${state.user.fullName}`}
                    required
                >
                    <UpdateUserPassword
                        onClose={() => setState({ ...state, openPassword: false })}
                        refetch={onRefetch}
                        user={state.user}
                    />
                </Modal>
            )}
            {/* --------------------------- DELETE --------------------------- */}
            {state.openDelete && (
                <Modal
                    title="Supprimer un utilisateur"
                >
                    <DeleteUser
                        onClose={() => setState({ ...state, openDelete: false })}
                        refetch={onRefetch}
                        user={state.user}
                    />
                </Modal>
            )}
        </>
    );
};

export default UserRouter;
