import React, { useState } from 'react';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import ServiceProductForm from './service-product-form';
import { productIsOffSeason } from '../off-season';

// Components
import Loading from 'components/loading';
import Error from 'components/error';
import InputSearch from 'components/input-search';
import Button from 'components/button';
import ItemCard from 'components/item-card';

// ---------------------------------------- SEARCH PRODUCT BY PRODUCT CATEGORIES --------------------------------------- \\
const SearchProductByProductCategories = ({ date, serviceCategory, state, setState }) => {
    return (
        <>
            {/* -------------------- PARENT PRODUCT CATEGORIES -------------------- */}
            {(state.product == null && state.breadcrumb.length === 0) && (
                <ParentProductCategories
                    state={state}
                    setState={setState}
                />
            )}
            {/* -------------------- CHILD PRODUCT CATEGORY -------------------- */}
            {(state.product == null && state.breadcrumb.length > 0) && (
                <ChildProductCategory
                    date={date}
                    state={state}
                    setState={setState}
                />
            )}
            {/* -------------------- SERVICE PRODUCT FORM -------------------- */}
            {state.product != null && (
                <>
                    <div className="mb-4">
                        <Button
                            label="Retour"
                            onClick={() => setState({ ...state, product: null })}
                        />
                    </div>
                    <div className="mb-4">  
                        <label className="uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Produit
                            <span className="text-base text-indigo-700 ml-1">*</span>
                        </label>
                        {productIsOffSeason(date, state.product) && (
                            <span className="text-blueGray-600 text-xs font-bold ml-2 mb-2">
                                (Produit hors période)
                            </span>  
                        )}
                        <div
                            className="px-3 py-2 border-O shadow rounded"
                            style={{ backgroundColor: productIsOffSeason(date, state.product) ? 'rgba(255, 0, 0, 0.5)' : 'unset' }}
                        >
                            <label className="text-sm">
                                {state.product.label}
                            </label>
                        </div>
                    </div>
                    <ServiceProductForm
                        state={state}
                        setState={setState}
                    />
                </>
            )}
        </>
    );
};

// ------------------------------------------ PARENT PRODUCT CATEGORIES ---------------------------------------- \\
const PRODUCT_CATEGORIES = gql`
    query ProductCategories($name: String, $parentId: Int, $first: Int!, $page: Int!) {
        productCategories(name: $name, parentId: $parentId, first: $first, page: $page) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                photo
            }
        }
    }
`;

const ParentProductCategories = ({ state, setState }) => {
    const [parentId, setParentId] = useState(0);
    const [searchName, setSearchName] = useState('');

    const { loading, error, data } = useQuery(PRODUCT_CATEGORIES, {
        variables: {
            parentId: parentId,
            first: 100,
            page: 1,
            name: '%' + searchName + '%',
        },
        fetchPolicy: 'network-only',
    });

    const onSearch = value => {
        if (value === '') {
            setParentId(0);
        }
        else {
            setParentId(-1);
        }
        setSearchName(value);
    };

    const onClickProductCategory = productCategory => {
        let breadcrumb = [];
        breadcrumb.push({ id: productCategory.id, title: productCategory.name })
        setState({ ...state, breadcrumb, productCategoryId: productCategory.id });
    };

    if (error) return <Error />;

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center mb-4">
                <Button
                    label="Retour"
                    onClick={() => setState({ ...state, page: 'research-choice' })}
                />
                <div className="ml-2">
                    <InputSearch
                        placeholder="Chercher une catégorie"
                        onSearch={value => onSearch(value)}
                    />
                </div>
            </div>
            {/* -------------------- PRODUCT CATEGORIES -------------------- */}
            {loading ? (
                <Loading />
            ) : (
                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
                    {data.productCategories.data.map(productCategory => (
                        <ItemCard
                            key={productCategory.id}
                            onClick={() => onClickProductCategory(productCategory)}
                            photo={productCategory.photo}
                            title={productCategory.name}
                            icon="fa-box-open"
                            hover
                        />
                    ))}
                </div>
            )}
        </>
    );
};

// ------------------------------------------- CHILD PRODUCT CATEGORY ------------------------------------------ \\
const PRODUCT_CATEGORY = gql`
    query ProductCategory($id: ID!) {
        productCategory(id: $id) {
            id
            name
            photo
            childProductCategories {
                id
                name
            }
            products {
                id
                name
                photo
                serviceCategories {
                    id
                    name
                    start_date
                    end_date
                }
            }
        }
    }
`;

const ChildProductCategory = ({ date, state, setState }) => {
    const { loading, error, data } = useQuery(PRODUCT_CATEGORY, {
        variables: {
            id: state.productCategoryId,
        },
        fetchPolicy: 'network-only',
    });

    const onClickProductCategory = productCategory => {
        let breadcrumb = state.breadcrumb;
        breadcrumb.push({ id: productCategory.id, title: productCategory.name })
        setState({ ...state, breadcrumb, productCategoryId: productCategory.id });
    };

    const onClickBreadcrumb = (productCategoryId, productCategoryIndex) => {
        let breadcrumb = state.breadcrumb;
        breadcrumb = breadcrumb.filter((item, index) =>  index <= productCategoryIndex);
        setState({ ...state, breadcrumb, productCategoryId });
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <div className="flex mb-4">
                <div
                    onClick={() => setState({ ...state, breadcrumb: [], productCategoryId: null })}
                    className={'cursor-pointer hover:text-indigo-500 px-2 py-1 rounded bg-indigo-100 uppercase text-xs text-blueGray-800 font-bold'}
                >
                    Tous
                </div>
                {state.breadcrumb.map((item, index) => (
                    <div key={item.id} className="flex items-center">
                        <i className="fas fa-chevron-right ml-2" />
                        <div
                            onClick={() => onClickBreadcrumb(item.id, index)}
                            className={(index + 1 !== state.breadcrumb.length && 'cursor-pointer hover:text-indigo-500') + ' px-2 py-1 rounded bg-indigo-100 uppercase text-xs text-blueGray-800 font-bold ml-2'}
                        >
                            {item.title}
                        </div>
                    </div>
                ))}
            </div>
            {/* -------------------- PRODUCT CATEGORIES -------------------- */}
            <div className="mb-2">
                Sous-catégories
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
                {data.productCategory.childProductCategories.length > 0 ? (
                    data.productCategory.childProductCategories.map(productCategory => (
                        <ItemCard
                            key={productCategory.id}
                            onClick={() => onClickProductCategory(productCategory)}
                            photo={productCategory.photo}
                            title={productCategory.name}
                            icon="fa-box-open"
                            hover
                        />
                    ))
                ) : (
                    <ItemCard empty />
                )}
            </div>
            {/* -------------------- PRODUCTS -------------------- */}
            <div className="mt-4 mb-2">
                Produits
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
                {data.productCategory.products.length > 0 ? (
                    data.productCategory.products.map(product => (
                        <ItemCard
                            key={product.id}
                            onClick={() => setState({ ...state, product: { value: product.id, label: product.name, productCategory: data.productCategory, serviceCategories: product.serviceCategories }})}
                            photo={product.photo}
                            title={product.name}
                            offSeason={productIsOffSeason(date, product)}
                            icon="fa-apple-alt"
                            hover
                        />
                    ))
                ) : (
                    <ItemCard empty />
                )}
            </div>
        </>
    );
};

export default SearchProductByProductCategories;
