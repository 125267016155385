import { Get, Post } from './axios-config';

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- BUNDLE -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const createBundle = data => {
    let url = 'bundles';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------- DOWNLOADS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const downloadProductionSheetPDF = productionSheetId => {
    let url = 'downloads/productionSheets/' + productionSheetId;
    return Get(url, 'blob');
};

export const downloadClientDescriptionPDF = clientDescriptionId => {
    let url = 'downloads/clientDescriptions/' + clientDescriptionId;
    return Get(url, 'blob');
};

export const downloadClientDescriptionFromService = serviceId => {
    let url = 'downloads/services/' + serviceId + '/clientDescription';
    return Get(url, 'blob');
};
