import React from 'react';

// ---------------------------------------- MODAL ---------------------------------------- \\
const Modal = ({ onClose, title, children, required, width }) => {
    return (
        <>
            <div
                onClick={onClose}
                className="fixed inset-0 z-40 margin-container flex items-center justify-center"
            >
                <div className={`h-full w-full xl:${width} p-6 flex items-center justify-center`}>
                    <div className="w-full flex flex-col bg-white rounded max-h-full overflow-auto">
                        {/* -------------------- TITLE -------------------- */}
                        <div className="flex items-center justify-between w-full p-5 border-b border-solid border-blueGray-200">
                            <h3 className="text-base font-semibold">
                                {title}
                            </h3>
                            {required && (
                                <span className="text-sm text-indigo-300 font-semibold">
                                    <i className="fas fa-star-of-life text-xs mr-2" />
                                    Requis
                                </span>
                            )}
                        </div>
                        {/* -------------------- CONTENT -------------------- */}
                        {children}
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-30 bg-black opacity-50" />
        </>
    );
}

Modal.defaultProps = {
    required: false,
    width: 'w-10/12',
};

export default Modal;
