import React, { useState, useEffect } from 'react';

// Views
import AddServiceProduct from './add-service-product';
import UpdateServiceProduct from './update-service-product';
import { productIsOffSeason } from './off-season';

// Components
import Modal from 'components/modal';
import Button from 'components/button';
import UpdateDeleteButtons from 'components/update-delete-buttons';
import PopoverButton from 'components/popover-button';

// ----------------------------------------- SERVICE PRODUCT FORM ----------------------------------------------- \\
const ServiceProductForm = ({ state, setState }) => {
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        if (state.serviceProducts != null && state.serviceProducts.length > 0) {

            let newProductCategories = [];
            state.serviceProducts.forEach(serviceProduct => {
                newProductCategories.push(serviceProduct.product?.productCategory);
            });

            let productCategoryIds = [], filteredProductCategories = [];
            newProductCategories.forEach(productCategory => {
                if (!productCategoryIds.includes(productCategory.id)) {
                    productCategoryIds.push(productCategory.id);
                    filteredProductCategories.push(productCategory);
                }
            });

            setProductCategories(filteredProductCategories);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const onRemoveServiceProduct = serviceProductId => {
        let serviceProducts = state.serviceProducts;
        let serviceProductsDelete = state.serviceProductsDelete;

        let index = serviceProducts.findIndex(sp => sp.id === serviceProductId);
        serviceProducts.splice(index, 1);

        if (state.actionType === 'UPDATE' && !serviceProductId.includes('SP')) {
            serviceProductsDelete.push(serviceProductId);
        }
        
        setState({ ...state, serviceProducts, serviceProductsDelete });
    };

    return (
        <>
            {/* --------------------- PRODUCTS --------------------- */}
            <div className="mb-4 text-right">
                <Button
                    label="Ajouter un produit"
                    onClick={() => setState({ ...state, openAddServiceProduct: true })}
                />
            </div>
            {productCategories.map((productCategory, productCategoryIndex) => (
                <div
                    key={productCategory.id}
                    style={{ marginTop: productCategoryIndex !== 0 ? 40 : 0 }}
                >
                    <div className="text-xs font-bold uppercase mb-2">
                        {productCategory.name}
                    </div>
                    {/* -------------------- HEADERS -------------------- */}
                    <div className="flex items-center bg-blueGray-100">
                        <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                            Nom
                        </div>
                        <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                            Quantité
                        </div>
                        <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                            Unité
                        </div>
                        <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                            Modalité
                        </div>
                        <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500">
                            Note
                        </div>
                        <div className="w-2/12 p-3 text-xs uppercase font-semibold text-blueGray-500 text-center">
                            Action
                        </div>
                    </div>
                    {/* -------------------- LIST -------------------- */}
                    {state.serviceProducts.length > 0 ? (
                        state.serviceProducts
                        .filter(sp => sp.product?.productCategory?.id === productCategory.id)
                        .map(serviceProduct => (
                            <div
                                key={serviceProduct.id}
                                className="flex items-center border-b border-solid border-blueGray-100"
                                style={{ backgroundColor: productIsOffSeason(state.date, serviceProduct.product) ? 'rgba(255, 0, 0, 0.2)' : 'unset' }}
                            >
                                {/* -------------------- PRODUCT -------------------- */}
                                <div className="w-2/12 p-3">
                                    <i className="fas fa-circle text-xxs text-blueGray-400 align-middle mr-2" />
                                    <b className="text-sm text-blueGray-600">
                                        {serviceProduct.product?.label}
                                    </b>
                                </div>
                                {/* -------------------- QUANTITY -------------------- */}
                                <div className="w-2/12 p-3">
                                    <b className="text-sm text-blueGray-600">
                                        {serviceProduct.quantity}
                                    </b>
                                </div>
                                {/* -------------------- UNIT -------------------- */}
                                <div className="w-2/12 p-3">
                                    <b className="text-sm text-blueGray-600">
                                        {serviceProduct.unit?.label}
                                    </b>
                                </div>
                                {/* -------------------- QUANTITY MODALITY -------------------- */}
                                <div className="w-2/12 p-3">
                                    <b className="text-sm text-blueGray-600">
                                        {serviceProduct.quantity_modality?.label}
                                    </b>
                                </div>
                                {/* -------------------- NOTE -------------------- */}
                                <div className="w-2/12 p-3">
                                    <span className="font-bold block text-sm text-blueGray-600">
                                        {serviceProduct.note !== '' && (
                                            <>
                                                {(serviceProduct.note).substring(0, 20)}...
                                                <PopoverButton
                                                    icon="fas fa-info"
                                                    title="Note"
                                                    text={serviceProduct.note}
                                                />
                                            </>
                                        )}
                                    </span>
                                </div>
                                {/* -------------------- ACTION -------------------- */}
                                <div className="w-2/12 p-3 text-center">
                                    <b className="text-sm text-blueGray-600">
                                        <UpdateDeleteButtons
                                            onClickDelete={() => onRemoveServiceProduct(serviceProduct.id)}
                                            onClickUpdate={() => setState({ ...state, openUpdateProductService: true, serviceProduct })}
                                        />
                                    </b>
                                </div>
                            </div>
                        ))
                    ) : (
                        <>
                            <div className="flex items-center py-3 px-6">
                                <i className="fas fa-circle text-xxs text-blueGray-400 align-middle mr-2" />
                                <b className="text-sm text-blueGray-600">
                                    Aucun
                                </b>
                            </div>
                            <div className="border-t border-solid border-blueGray-100" />
                        </>
                    )}
                </div>
            ))}
            {/* --------------------------- ADD SERVICE PRODUCT --------------------------- */}
            {state.openAddServiceProduct && (
                <Modal
                    title="Ajouter un produit"
                    required
                >
                    <AddServiceProduct
                        date={state.date}
                        serviceProductId={state.serviceProductId}
                        serviceProducts={state.serviceProducts}
                        setServiceProducts={(serviceProductId, serviceProducts) => setState({ ...state, openAddServiceProduct: false, serviceProductId, serviceProducts })}
                        onClose={() => setState({ ...state, openAddServiceProduct: false })}
                    />
                </Modal>
            )}
            {/* --------------------------- UPDATE SERVICE PRODUT --------------------------- */}
            {state.openUpdateProductService && (
                <Modal
                    title="Modifier un produit"
                    required
                >
                    <UpdateServiceProduct
                        serviceProduct={state.serviceProduct}
                        serviceProducts={state.serviceProducts}
                        setServiceProducts={serviceProducts => setState({ ...state, openUpdateProductService: false, serviceProducts })}
                        onClose={() => setState({ ...state, openUpdateProductService: false })}
                    />
                </Modal>
            )}
        </>
    );
};

export default ServiceProductForm;
