import React from 'react';

// ----------------------------------------- INPUT FILTER ------------------------------------------------- \\
const InputFilter = ({ value, onChange, onKeyPress, placeholder, type, disabled }) => {
    return (
        <input
            value={value}
            onChange={onChange}
            onKeyPress={e => e.key === 'Enter' ? onKeyPress() : null}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            min={type === 'number' ? 0 : null}
            className="input-filter w-full p-3 border-0 border-blueGray-300 placeholder-blueGray-300 bg-white rounded shadow outline-none
                text-blueGray-600 text-sm font-semibold"
        />
    );
};

InputFilter.defaultProps = {
    type: 'text',
    disabled: false,
};

export default InputFilter;
