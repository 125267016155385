import React, { Fragment } from 'react';

// Assets
import { productStatuses } from 'assets/constants';

// Views
import AddProductionAreaToProduct from './add-production-area-to-product';

// Components
import SelectProductCategory from 'components/autocomplete/select-product-category';
import SelectProductOrigins from 'components/autocomplete/select-product-origins';
import SelectComplements from 'components/autocomplete/select-complements';
import SelectAllergens from 'components/autocomplete/select-allergens';
import SelectServiceCategories from 'components/autocomplete/select-service-categories';
import Input from 'components/input';
import Select from 'components/select';
import Modal from 'components/modal';

// ----------------------------------------- PRODUCT FORM ----------------------------------------------- \\
const ProductForm = ({ state, setState }) => {
    
    const onRemoveProductionAreaProduct = index => {
        const productionAreaProducts = state.productionAreaProducts;
        productionAreaProducts.splice(index, 1);
        setState({ ...state, productionAreaProducts });
    };

    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <Input
                    placeholder="Nom"
                    required
                    value={state.name}
                    onChange={e => setState({ ...state, name: e.target.value })}
                />
            </div>
            {/* -------------------- STATUS -------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <Select
                    placeholder="Statut"
                    required
                    value={state.status}
                    onChange={status => setState({ ...state, status })}
                    options={productStatuses}
                />
            </div>
            {/* -------------------- PRODUCT CATEGORY -------------------- */}
            <div className="w-full lg:w-1/2 mx-auto mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold">
                    Catégorie du produit
                    <span className="text-base text-indigo-700 ml-1">*</span>
                </label>
                <SelectProductCategory
                    productCategory={state.productCategory}
                    setProductCategory={productCategory => setState({ ...state, productCategory })}
                />
            </div>
            {/* -------------------- PRODUCT ORIGINS -------------------- */}
            <div className="w-full lg:w-1/2 mx-auto mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold">
                    Origines du produit
                </label>
                <SelectProductOrigins
                    productOrigins={state.productOrigins}
                    setProductOrigins={productOrigins => setState({ ...state, productOrigins })}
                />
            </div>
            {/* -------------------- COMPLEMENTS -------------------- */}
            <div className="w-full lg:w-1/2 mx-auto mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold">
                    Compléments
                </label>
                <SelectComplements
                    complements={state.complements}
                    setComplements={complements => setState({ ...state, complements })}
                />
            </div>
            {/* -------------------- ALLERGENS -------------------- */}
            <div className="w-full lg:w-1/2 mx-auto mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold">
                    Allergènes
                </label>
                <SelectAllergens
                    allergens={state.allergens}
                    setAllergens={allergens => setState({ ...state, allergens })}
                />
            </div>
            {/* -------------------- SERVICE CATEGORIES -------------------- */}
            <div className="w-full lg:w-1/2 mx-auto mb-4">
                <label className="uppercase text-blueGray-600 text-xs font-bold">
                    Catégorie de prestation
                </label>
                <SelectServiceCategories
                    serviceCategories={state.serviceCategories}
                    setServiceCategories={serviceCategories => setState({ ...state, serviceCategories })}
                />
            </div>
            {/* -------------------- PRODUCTION AREA PRODUCTS -------------------- */}
            <div className="w-full lg:w-1/2 mx-auto mb-4">
                <div className="uppercase text-blueGray-600 text-xs font-bold mb-1">
                    Zones de production
                </div>
                {(state.productionAreaProducts != null && state.productionAreaProducts.length > 0) && (
                    <div className="rounded shadow mb-2">
                        {state.productionAreaProducts.map((productionAreaProduct, index) => (
                            <Fragment key={index}>
                                {index !== 0 && (
                                    <div className="border-t border-solid border-blueGray-200" />
                                )}
                                <div className="flex items-center justify-between px-3 py-2">
                                    <div className="text-blueGray-600 text-sm font-semibold">
                                        {productionAreaProduct.title}
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => onRemoveProductionAreaProduct(index)}
                                            type="button"
                                            className="border border-blueGray-300 px-1 rounded-full text-blueGray-300 text-sm font-semibold
                                                hover:text-white hover:bg-blueGray-400"
                                        >
                                            <i className="fas fa-minus" />
                                        </button>
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                )}
                <div>
                    <button
                        onClick={() => setState({ ...state, openProductionAreaProduct: true })}
                        type="button"
                        className="px-3 py-2 rounded border border-blueGray-300 text-blueGray-300 text-sm font-semibold
                            hover:text-white hover:bg-blueGray-400"
                    >
                        Ajouter une zone
                        <i className="fas fa-plus-circle text-xl ml-2 align-middle" />
                    </button>
                </div>
            </div>
            {/* -------------------- DESCRIPTION -------------------- */}
            <div className="w-full lg:w-1/2 mx-auto">
                <Input
                    placeholder="Description"
                    multiline
                    value={state.description}
                    onChange={e => setState({ ...state, description: e.target.value })}
                />
            </div>
            {/* --------------------------- UPDATE --------------------------- */}
            {state.openProductionAreaProduct && (
                <Modal
                    title="Ajouter une zone de production"
                    required
                >
                    <AddProductionAreaToProduct
                        onClose={() => setState({ ...state, openProductionAreaProduct: false })}
                        onAdd={productionAreaProducts => setState({ ...state, openProductionAreaProduct: false, productionAreaProducts })}
                        productionAreaProducts={state.productionAreaProducts}
                    />
                </Modal>
            )}
        </>
    );
};

export default ProductForm;
