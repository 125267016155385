import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Services
import { getLabel } from 'services/helpers';
import { checkValidDateWithDayAndMonth} from 'services/helpers';

// Assets
import { serviceCategoryStatuses } from 'assets/constants';

// Components
import ServiceCategoryForm from './service-category-form';
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const UPDATE_SERVICE_CATEGORY = gql`
    mutation UpdateServiceCategory(
        $id: ID!,
        $name: String!,
        $start_date: String!,
        $end_date: String!,
        $status: ServiceCategoryStatus,
        $color: String,
        $description: String,
        $number: Int,
        $priority: Int!,
    ) {
        updateServiceCategory(input: {
            id: $id,
            attributes: {
                name: $name,
                start_date: $start_date,
                end_date: $end_date,
                status: $status,
                color: $color,
                description: $description,
                number: $number,
                priority: $priority,
            }
        }) {
            id
        }
    }
`;

// ---------------------------------------- UPDATE SERVICE CATEGORY --------------------------------------- \\
const UpdateServiceCategory = ({ onClose, refetch, serviceCategory }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        name: serviceCategory.name || '',
        start_date: serviceCategory.start_date ? (serviceCategory.start_date.split('-')[1] + '/' + serviceCategory.start_date.split('-')[0]) : '',
        end_date: serviceCategory.end_date ? (serviceCategory.end_date.split('-')[1] + '/' + serviceCategory.end_date.split('-')[0]) : '',
        status: serviceCategory.status ? ({ value: serviceCategory.status, label: getLabel(serviceCategoryStatuses, serviceCategory.status) }) : '',
        color: serviceCategory.color || '',
        description: serviceCategory.description || '',
        number: serviceCategory.number || '',
        priority: serviceCategory.priority || 1,
    });

    const [updateServiceCategory, { loading, error, data }] = useMutation(UPDATE_SERVICE_CATEGORY);

    const onUpdateServiceCategory = () => {
        if (state.name === '' || state.priority === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else if (!checkValidDateWithDayAndMonth(state.start_date) || !checkValidDateWithDayAndMonth(state.end_date)) {
            addToast('La date de début ou la date de fin n\'est pas correcte', { appearance: 'warning' });
        }
        else {
            updateServiceCategory({
                variables: {
                    id: serviceCategory.id,
                    name: state.name,
                    start_date: state.start_date.split('/')[1] + '-' + state.start_date.split('/')[0],
                    end_date: state.end_date.split('/')[1] + '-' + state.end_date.split('/')[0],
                    status: (state.status != null && state.status !== "") ? state.status.value : null,
                    color: state.color,
                    description: state.description,
                    number: (state.number != null && state.number !== "") ? Number(state.number) : null,
                    priority: Number(state.priority),
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['service.category.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <ServiceCategoryForm state={state} setState={setState} />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onUpdateServiceCategory}
                    />
                </>
            )}
        </>
    );
};

export default UpdateServiceCategory;
