import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Toast
import { useToasts } from 'react-toast-notifications';

// Services
import { createBundle } from 'services/requests';

// Components
import Loading from 'components/loading';
import ModalButtons from 'components/modal-buttons';
import DatePicker from 'components/date-picker';

// Messages
import messages from 'messages.json';

// ------------------------------------------- CREATE BUNDLE ------------------------------------------- \\
const CreateBundle = ({ onClose, refetch }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        loading: false,
        startDate: null,
        endDate: null,
    });

    const onCreateBundle = () => {
        if (state.startDate == null || state.endDate == null) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else if (state.startDate > state.endDate) {
            addToast('La date de début ne doit pas être plus récente que la date de fin', { appearance: 'warning' });
        }
        else {
            setState({ ...state, loading: true });   
        }
    };

    useEffect(() => {
        if (state.loading) {
            createBundle({
                startDate: moment(state.startDate).format('YYYY-MM-DD'),
                endDate: moment(state.endDate).format('YYYY-MM-DD'),
            }).then(res => {
                if (res.status === 200) {
                    addToast(messages['bundle.created'], { appearance: 'success' });
                    refetch();
                }
                else {
                    console.log(res);
                    addToast(res || messages['error.occured'], { appearance: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {state.loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        {/* -------------------- START DATE -------------------- */}
                        <DatePicker
                            placeholder="Date de début"
                            value={state.startDate}
                            onChange={startDate => setState({ ...state, startDate })}
                            required
                        />
                        {/* -------------------- END DATE -------------------- */}
                        <DatePicker
                            placeholder="Date de fin"
                            value={state.endDate}
                            onChange={endDate => setState({ ...state, endDate })}
                            required
                        />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onCreateBundle}
                        labelRight="Créer"
                    />
                </>
            )}
        </>
    );
};

export default CreateBundle;
