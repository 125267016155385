import React from 'react';
import { useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import CreateProductCategory from './create-product-category';

// Components
import Loading from 'components/loading';
import Error from 'components/error';
import ItemCard from 'components/item-card';
import Button from 'components/button';
import Modal from 'components/modal';

// ------------------------------------------ PRODUCT CATEGORY CARDS ------------------------------------------ \\
const ProductCategoryCards = ({ loading, error, data, state, setState }) => {
    return (
        <>
            <div className="border border-t border-blueGray-100" />
            <div className="p-3">
                {/* -------------------- PARENT PRODUCT CATEGORIES -------------------- */}
                {state.breadcrumb.length === 0 && (
                    <ParentProductCategories
                        loading={loading}
                        error={error}
                        data={data}
                        state={state}
                        setState={setState}
                    />
                )}
                {/* -------------------- CHILD PRODUCT CATEGORY -------------------- */}
                {state.breadcrumb.length > 0 && (
                    <ChildProductCategory
                        state={state}
                        setState={setState}
                    />
                )}
            </div>
        </>
    );
};

// ----------------------------------------- PARENT PRODUCT CATEGORIES ---------------------------------------- \\
const ParentProductCategories = ({ loading, error, data, state, setState }) => {

    const onClickProductCategory = productCategory => {
        let breadcrumb = [];
        breadcrumb.push({ id: productCategory.id ,title: productCategory.name })
        setState({ ...state, breadcrumb, productCategoryId: productCategory.id });
    };

    if (error) return <Error />;

    return (
        <>
            {/* -------------------- PRODUCT CATEGORIES -------------------- */}
            {loading ? (
                <Loading />
            ) : (
                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
                    {data.productCategories.data.map(productCategory => (
                        <ItemCard
                            key={productCategory.id}
                            onClick={() => onClickProductCategory(productCategory)}
                            photo={productCategory.photo}
                            title={productCategory.name}
                            icon="fa-box-open"
                            hover
                        />
                    ))}
                </div>
            )}
        </>
    );
};

// ------------------------------------------- CHILD PRODUCT CATEGORY ------------------------------------------ \\
const PRODUCT_CATEGORY = gql`
    query ProductCategory($id: ID!) {
        productCategory(id: $id) {
            id
            name
            photo
            childProductCategories {
                id
                name
            }
            products {
                id
                name
                photo
            }
        }
    }
`;

const ChildProductCategory = ({ state, setState }) => {
    const history = useHistory();

    const { loading, error, data, refetch } = useQuery(PRODUCT_CATEGORY, {
        variables: {
            id: state.productCategoryId,
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({ ...state, openAddSubProductCategory: false });
        refetch();
    };

    const onAddSubProductCategory = () => {
        let productCategory = state.breadcrumb.find(item => item.id === state.productCategoryId);
        setState({ ...state, openAddSubProductCategory: true, parentProductCategory: ({ value: productCategory.id, label: productCategory.title }) });
    };

    const onAddProduct = () => {
        let productCategory = state.breadcrumb.find(item => item.id === state.productCategoryId);
        history.push('/app/products/create?productCategoryId=' + productCategory.id + '&productCategoryName=' + productCategory.title);
    };

    const onClickProductCategory = productCategory => {
        let breadcrumb = state.breadcrumb;
        breadcrumb.push({ id: productCategory.id, title: productCategory.name })
        setState({ ...state, breadcrumb, productCategoryId: productCategory.id });
    };

    const onClickBreadcrumb = (productCategoryId, productCategoryIndex) => {
        if (productCategoryIndex + 1 === state.breadcrumb.length) {
            history.push('/app/product-categories/' + productCategoryId);
        }
        else {
            let breadcrumb = state.breadcrumb;
            breadcrumb = breadcrumb.filter((item, index) =>  index <= productCategoryIndex);
            setState({ ...state, breadcrumb, productCategoryId });
        }
    };

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <div className="flex mb-4">
                <div
                    onClick={() => setState({ ...state, breadcrumb: [], productCategoryId: null })}
                    className={'cursor-pointer hover:text-indigo-500 px-2 py-1 rounded bg-indigo-100 uppercase text-xs text-blueGray-800 font-bold'}
                >
                    Tous
                </div>
                {state.breadcrumb.map((item, index) => (
                    <div key={item.id} className="flex items-center">
                        <i className="fas fa-chevron-right ml-2" />
                        <div
                            onClick={() => onClickBreadcrumb(item.id, index)}
                            className="cursor-pointer hover:text-indigo-500 px-2 py-1 rounded bg-indigo-100 uppercase text-xs text-blueGray-800 font-bold ml-2"
                        >
                            {item.title}
                        </div>
                    </div>
                ))}
            </div>
            {/* -------------------- PRODUCT CATEGORIES -------------------- */}
            <div className="mb-4">
                Sous-catégories
            </div>
            <div className="mb-4">
                <Button
                    label="Ajouter une sous-catégorie"
                    onClick={onAddSubProductCategory}
                />
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
                {data.productCategory.childProductCategories?.length > 0 ? (
                    data.productCategory.childProductCategories?.map(productCategory => (
                        <ItemCard
                            key={productCategory.id}
                            onClick={() => onClickProductCategory(productCategory)}
                            photo={productCategory.photo}
                            title={productCategory.name}
                            icon="fa-box-open"
                            hover
                        />
                    ))
                ) : (
                    <ItemCard empty />
                )}
            </div>
            {/* -------------------- PRODUCTS -------------------- */}
            <div className="my-4">  
                Produits 
            </div>
            <div className="mb-4">
                <Button
                    label="Ajouter un produit"
                    onClick={onAddProduct}
                />
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-4">
                {data.productCategory.products?.length > 0 ? (
                    data.productCategory.products?.map(product => (
                        <ItemCard
                            key={product.id}
                            onClick={() => history.push('/app/products/' + product.id)}
                            photo={product.photo}
                            title={product.name}
                            icon="fa-apple-alt"
                            hover
                        />
                    ))
                ) : (
                    <ItemCard empty />
                )}
            </div>
            {/* --------------------------- ADD --------------------------- */}
            {state.openAddSubProductCategory && (
                <Modal
                    title="Ajouter une sous-catégorie de produit"
                    required
                >
                    <CreateProductCategory
                        onClose={() => setState({ ...state, openAddSubProductCategory: false })}
                        refetch={onRefetch}
                        productCategory={state.parentProductCategory}
                    />
                </Modal>
            )}
        </>
    );
};

export default ProductCategoryCards;
