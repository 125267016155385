import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import ModalButtons from 'components/modal-buttons';
import Input from 'components/input';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const UPDATE_ALLERGEN = gql`
    mutation UpdateAllergen($id: ID!, $name: String!) {
        updateAllergen(id: $id, name: $name) {
            id
        }
    }
`;

// ------------------------------------------- UPDATE ALLERGEN ------------------------------------------- \\
const UpdateAllergen = ({ onClose, refetch, allergen }) => {
    const { addToast } = useToasts();
    const [name, setName] = useState(allergen.name || '');
    const [updateAllergen, { loading, error, data }] = useMutation(UPDATE_ALLERGEN);

    const onUpdateAllergen = () => {
        if (name === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            updateAllergen({ variables: { id: allergen.id, name: name } });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['allergen.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <Input
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder="Nom"
                            required
                        />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onUpdateAllergen}
                        labelRight="Enregistrer"
                    />
                </>
            )}
        </>
    );
};

export default UpdateAllergen;
