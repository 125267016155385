import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Apollo
import { gql, useQuery, useMutation } from '@apollo/client';

// Moment
import moment from 'moment';

// Toast
import { useToasts } from 'react-toast-notifications';

// Services
import { getLabel } from 'services/helpers';

// Assets
import { serviceTypes, serviceStatuses, serviceProductUnits, serviceProductQuantityModalities } from 'assets/constants';

// Views
import ServiceForm from '../create-service/service-form';
import ServiceProductForm from '../create-service/service-product-form';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Error from 'components/error';

// Messages
import messages from 'messages.json';

// ------------------------------------------ GET SERVICE ------------------------------------------------- \\
const SERVICE = gql`
    query Service($id: ID!) {
        service(id: $id) {
            id
            date
            type
            status
            start_time
            guests_number
            place
            note
            serviceCategory {
                id
                name
                start_date
                end_date
            }
            client {
                id
                name
            }
            extras {
                id
                name
            }
            serviceProducts {
                id
                quantity
                unit
                note
                quantity_modality
                product {
                    id
                    name
                    productCategory {
                        id
                        name
                    }
                    serviceCategories {
                        id
                        name
                        start_date
                        end_date
                    }
                }
            }
        }
    }
`;

const GetService = () => {
    const { serviceId } = useParams();

    const { loading, error, data } = useQuery(SERVICE, {
        variables: {
            id: serviceId,
        },
        fetchPolicy: 'network-only',
    });

    if (loading) return <Loading />;
    if (error || data.service == null) return <Error />;

    return (
        <UpdateService
            service={data.service}
        />
    );
};

// ----------------------------------------- UPDATE SERVICE ----------------------------------------------- \\
const UPDATE_SERVICE = gql`
    mutation UpdateService(
        $id: ID!
        $date: Date!
        $type: ServiceType!
        $start_time: String
        $note: String
        $guests_number: Int
        $place: String
        $status: ServiceStatus
        $client: ConnectClientBelongsTo!
        $serviceCategory: ConnectServiceCategoryBelongsTo!
        $serviceProducts: CreateServiceProductHasMany
        $extras: SyncExtraBelongsToMany
    ) {
        updateService(input: {
            id: $id
            attributes: {
                date: $date
                type: $type
                start_time: $start_time
                note: $note
                guests_number: $guests_number
                place: $place
                status: $status
                client: $client
                serviceCategory: $serviceCategory
                serviceProducts: $serviceProducts
                extras: $extras
            }
        }) {
            id
        }
    }
`;

const UpdateService = ({ service }) => {
    const history = useHistory();
    const { addToast } = useToasts();
    const [updateService, { loading, error, data }] = useMutation(UPDATE_SERVICE);

    const getServiceProducts = () => {
        if (service.serviceProducts != null && service.serviceProducts.length > 0) {
            let serviceProducts = service.serviceProducts.map(serviceProduct => ({
                id: serviceProduct.id,
                quantity: serviceProduct.quantity,
                unit: ({ value: serviceProduct.unit, label: getLabel(serviceProductUnits, serviceProduct.unit) }),
                note: serviceProduct.note || '',
                quantity_modality: serviceProduct.quantity_modality ? ({ value: serviceProduct.quantity_modality, label: getLabel(serviceProductQuantityModalities, serviceProduct.quantity_modality) }) : null,
                product: ({ value: serviceProduct.product.id, label: serviceProduct.product?.name, productCategory: serviceProduct.product?.productCategory, serviceCategories: serviceProduct.product?.serviceCategories }),
            }));
            return serviceProducts;
        }
        return [];
    };

    const [state, setState] = useState({
        actionType: 'UPDATE',
        page: 1,
        openAddServiceProduct: false,
        openUpdateProductService: false,

        // Service Product ID
        serviceProductId: 'SP1',
        serviceProduct: null,

        // Service Fields
        date: new Date(service.date),
        type: ({ value: service.type, label: getLabel(serviceTypes, service.type) }),
        start_time: service.start_time ? service.start_time.substring(0, 5) : '',
        note: service.note || '',
        guests_number: service.guests_number || '',
        place: service.place || '',
        status: service.status ? ({ value: service.status, label: getLabel(serviceStatuses, service.status) }) : null,
        client: service.client ? ({ value: service.client.id, label: service.client.name }) : null,
        serviceCategory: ({ value: service.serviceCategory.id, label: service.serviceCategory?.name, start_date: service.serviceCategory?.start_date, end_date: service.serviceCategory?.end_date }),
        serviceProducts: getServiceProducts(),
        serviceProductsDelete: [],
        extras: service.extras ? service.extras.map(e => ({ value: e.id, label: e.name })) : [],
    });

    const onUpdate = () => {
        if (state.date == null
            || state.client == null
            || state.type == null
            || state.serviceCategory == null
        ) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {

            let serviceProductsCreate = state.serviceProducts.filter(sp => sp.id?.includes('SP')).map(serviceProduct => ({
                quantity: Number(serviceProduct.quantity),
                unit: serviceProduct.unit?.value,
                note: serviceProduct.note,
                quantity_modality: serviceProduct.quantity_modality ? serviceProduct.quantity_modality?.value : null,
                product: { connect: serviceProduct.product?.value },
            }));

            let serviceProductsUpdate = state.serviceProducts.filter(sp => !sp.id?.includes('SP')).map(serviceProduct => ({
                id: serviceProduct.id,
                quantity: Number(serviceProduct.quantity),
                unit: serviceProduct.unit?.value,
                note: serviceProduct.note,
                quantity_modality: serviceProduct.quantity_modality ? serviceProduct.quantity_modality?.value : null,
                product: { connect: serviceProduct.product?.value },
            }));

            updateService({
                variables: {
                    id: service.id,
                    date: moment(state.date).format('YYYY-MM-DD'),
                    client: { connect: state.client.value },
                    type: state.type.value,
                    serviceCategory: { connect: state.serviceCategory.value },
                    status: state.status ? state.status.value : null,
                    start_time: state.start_time !== '' ? state.start_time : null,
                    guests_number: state.guests_number ? Number(state.guests_number) : null,
                    place: state.place,
                    serviceProducts: { create: serviceProductsCreate, update: serviceProductsUpdate, delete: state.serviceProductsDelete },
                    extras: { sync: state.extras ? state.extras.map(e => e.value) : [] },
                    note: state.note,
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['service.saved'], { appearance: 'success' });
            history.push('/app/services');
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <Card>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center py-3 px-6">
                <div className="w-1/3">
                    <Button
                        label="Retour"
                        onClick={() => history.goBack()}
                    />
                </div>
                <div className="w-1/3 text-center">
                    <h3 className="font-semibold text-base text-blueGray-700">
                        Modifier une prestation
                    </h3>
                </div>
                <div className="w-1/3 text-right">
                    <span className="text-sm text-indigo-300 font-semibold">
                        <i className="fas fa-star-of-life text-xs mr-2" />
                        Requis
                    </span>
                </div>
            </div>
            {/* -------------------- NAV -------------------- */}
            <div className="flex w-full">
                <div
                    onClick={() => setState({ ...state, page: 1 })}
                    className={(state.page === 1 && 'bg-blueGray-100') + ' w-1/2 px-3 py-2 border border-blueGray-100 cursor-pointer text-center hover:bg-blueGray-200'}
                >
                    <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                        Informations
                    </label>
                </div>
                <div
                    onClick={() => setState({ ...state, page: 2 })}
                    className={(state.page === 2 && 'bg-blueGray-100') + ' w-1/2 px-3 py-2 border border-blueGray-100 cursor-pointer text-center hover:bg-blueGray-200'}
                >
                    <label className="uppercase text-blueGray-600 text-xs font-bold cursor-pointer">
                        Produits
                    </label>
                </div>
            </div>
            {/* -------------------- FORM -------------------- */}
            <div className="flex flex-col w-full px-6 py-3">
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {state.page === 1 && (
                            <ServiceForm
                                state={state}
                                setState={setState}
                            />
                        )}
                        {state.page === 2 && (
                            <ServiceProductForm
                                state={state}
                                setState={setState}
                            />
                        )}
                    </>
                )}
            </div>
            {/* -------------------- SAVE -------------------- */}
            <div className="w-full px-6 py-3 text-center border-t border-blueGray-100">
                <button
                    onClick={onUpdate}
                    type="button"
                    className="bg-indigo-500 hover:bg-green-600 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150
                        text-white text-xs"
                >
                    Sauvegarder
                </button>
            </div>
        </Card>
    );
};

export default GetService;
