import React from 'react';

// Components
import Input from 'components/input';

// ------------------------------------------- USER FORM ------------------------------------------- \\
const UserForm = ({ state, setState }) => {
    return (
        <>
            {/* --------------------- FIRST NAME --------------------- */}
            <Input
                value={state.first_name}
                onChange={e => setState({ ...state, first_name: e.target.value })}
                placeholder="Prénom"
                required
            />
            {/* --------------------- LAST NAME --------------------- */}
            <Input
                value={state.last_name}
                onChange={e => setState({ ...state, last_name: e.target.value })}
                placeholder="Nom"
                required
            />
            {/* --------------------- EMAIL --------------------- */}
            <Input
                value={state.email}
                onChange={e => setState({ ...state, email: e.target.value })}
                placeholder="Email"
                email
                required
            />
            {/* --------------------- PHONE --------------------- */}
            <Input
                value={state.phone}
                onChange={e => setState({ ...state, phone: e.target.value })}
                placeholder="Téléphone"
            />
            {/* --------------------- MOBILE --------------------- */}
            <Input
                value={state.mobile_phone}
                onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                placeholder="Téléphone mobile"
            />
        </>
    );
};

export default UserForm;
