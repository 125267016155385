import React from 'react';

// ----------------------------------------- CUSTOMIZED INPUT ------------------------------------------------- \\
const CustomizedInput = ({ value, onChange, placeholder, type, disabled, multiline, required }) => {
    return (
        <div className="mb-4 w-full">
            {/* -------------------- TITLE -------------------- */}
            <label className="uppercase text-blueGray-600 text-xs font-bold mb-1">
                {placeholder}
                {required && (
                    <span className="text-base text-indigo-700 ml-1">*</span>
                )}
            </label>
            {/* -------------------- INPUT -------------------- */}
            {multiline ? (
                <textarea
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    className="w-full p-3 border-0 border-blueGray-300 placeholder-blueGray-300 bg-white rounded shadow outline-none
                        text-blueGray-600 text-sm font-semibold"
                />
            ) : (
                <input
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    type={type}
                    disabled={disabled}
                    required={required}
                    min={type === 'number' ? 0 : null}
                    className="w-full p-3 border-0 border-blueGray-300 placeholder-blueGray-300 bg-white rounded shadow outline-none
                        text-blueGray-600 text-sm font-semibold"
                />
            )}
        </div>
    );
};

CustomizedInput.defaultProps = {
    type: 'text',
    disabled: false,
    multiline: false,
    required: false,
};

export default CustomizedInput;
