import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';

// Moment
import moment from 'moment';

// Calendar
import 'react-big-calendar/lib/css/react-big-calendar.css';
require('moment/locale/fr.js');

const today = new Date();
const localizer = momentLocalizer(moment);
const formats = { agendaDateFormat: 'DD/MM/YYYY' };

// ---------------------------------------- BIG CALENDAR --------------------------------------------- \\
const BigCalendar = ({ events, eventPropGetter, onNavigate, onRangeChange, onSelectEvent }) => {
    const [width, setWitdh] = useState(0);

    useEffect(() => {
        setWitdh(document.getElementById('main-container').offsetWidth);
    }, []);

    return (
        <Calendar
            defaultDate={today}
            localizer={localizer}
            formats={formats}
            events={events}
            eventPropGetter={eventPropGetter}
            onNavigate={onNavigate}
            onRangeChange={onRangeChange}
            onSelectEvent={onSelectEvent}
            messages={{
                today: "Aujourd'hui",
                allDay: "Toute la journée",
                previous: "Précédent",
                next: "Suivant",
                month: "Mois",
                week: "Semaine",
                day: "Jour",
                time: "Horaire",
                event: "Prestation",
                noEventsInRange: "Aucune prestation durant cette période."
            }}
            style={{ minHeight: 600, minWidth: 800, maxWidth: `calc(${width}px - 144px)` }}
        />
    );
};

BigCalendar.defaultProps = {
    events: [],
};

export default BigCalendar;
