import React, { useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const DELETE_ALLERGEN = gql`
    mutation DeleteAllergen($id: ID!) {
        deleteAllergen(id: $id) {
            id
        }
    }
`;

// ------------------------------------------- DELETE ALLERGEN ------------------------------------------- \\
const DeleteAllergen = ({ onClose, refetch, allergen }) => {
    const { addToast } = useToasts();
    const [deleteAllergen, { loading, error, data }] = useMutation(DELETE_ALLERGEN);

    const onDeleteAllergen = () => {
        deleteAllergen({ variables: { id: allergen.id } });
    };

    useEffect(() => {
        if (data) {
            addToast('Allergène supprimé', { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="text-center font-semibold text-base py-6">
                        <span>Êtes-vous sûr de vouloir supprimer cet allergène ?</span>
                        <br />
                        <strong>{allergen.name}</strong>
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onDeleteAllergen}
                        labelRight="Supprimer"
                    />
                </>
            )}
        </>
    );
};

export default DeleteAllergen;
