import React, { useEffect } from 'react';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Toast
import { useToasts } from 'react-toast-notifications';

// Components
import InputFile from 'components/input-file';
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const UPLOAD_PRODUCT_CATEGORY_PHOTO = gql`
    mutation UploadProductCategoryPhoto($id: ID!, $file: Upload!) {
        uploadProductCategoryPhoto(id: $id, file: $file)
    }
`;

// ----------------------------------------- UPLOAD PRODUCT CATEGORY PHOTO ----------------------------------------- \\
const UploadProductCategoryPhoto = ({ onClose, refetch, productCategoryId }) => {
    const { addToast } = useToasts();
    const [uploadProductCategoryPhoto, { loading, error, data }] = useMutation(UPLOAD_PRODUCT_CATEGORY_PHOTO);

    const onUpload = () => {
        let file = document.getElementById('upload-product-category-photo').files[0];
        if (file == null) {
            addToast(messages['image.required'], { appearance: 'warning' });
        }
        else {
            uploadProductCategoryPhoto({
                variables: {
                    id: productCategoryId,
                    file: file,
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast('Photo de la catégorie de produit enregistrée avec succès', { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        {/* -------------------- PHOTO -------------------- */}
                        <div className="mb-4">
                            <label className="uppercase text-blueGray-600 text-xs font-bold">
                                Photo
                                <span className="text-base text-indigo-700 ml-1">*</span>
                            </label>
                        </div>
                        <InputFile inputId="product-category-photo" />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onUpload}
                        labelRight="Sauvegarder"
                    />
                </>
            )}
        </>
    );
};

export default UploadProductCategoryPhoto;
