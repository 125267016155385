import moment from 'moment';

// ----------------------------------------- PRODUCT IS OFF SEASON ----------------------------------------------- \\
export const productIsOffSeason = (date, product) => {
    if (date && product?.serviceCategories) {
        let isOffSeason = true;
        let yearOfDate = moment(date).year();
        product.serviceCategories.forEach(serviceCategory => {
            if (serviceCategory.start_date <= serviceCategory.end_date) {
                if (moment(date).format('YYYY-MM-DD') >= (yearOfDate + '-' + serviceCategory.start_date)
                    && moment(date).format('YYYY-MM-DD') <= (yearOfDate + '-' + serviceCategory.end_date)
                ) {
                    isOffSeason = false;
                }
            }
            else {
                if ((
                        moment(date).format('YYYY-MM-DD') >= ((yearOfDate - 1) + '-' + serviceCategory.start_date)
                        && moment(date).format('YYYY-MM-DD') <= (yearOfDate + '-' + serviceCategory.end_date)
                    )
                    ||
                    (
                        moment(date).format('YYYY-MM-DD') >= (yearOfDate + '-' + serviceCategory.start_date)
                        && moment(date).format('YYYY-MM-DD') <= ((yearOfDate + 1) + '-' + serviceCategory.end_date)
                    )
                ) {
                    isOffSeason = false;
                }
            }
        });
        return isOffSeason;
    }
    return false;
};

// ------------------------------------ SERVICE CATEGORY IS OUT OF DATE ------------------------------------------ \\
export const serviceCategoryIsOutOfDate = (date, serviceCategory) => {
    if (date && serviceCategory) {
        let isOutOfDate = true;
        let yearOfDate = moment(date).year();
        if (serviceCategory.start_date <= serviceCategory.end_date) {
            if (moment(date).format('YYYY-MM-DD') >= (yearOfDate + '-' + serviceCategory.start_date)
                && moment(date).format('YYYY-MM-DD') <= (yearOfDate + '-' + serviceCategory.end_date)
            ) {
                isOutOfDate = false;
            }
        }
        else {
            if ((
                    moment(date).format('YYYY-MM-DD') >= ((yearOfDate - 1) + '-' + serviceCategory.start_date)
                    && moment(date).format('YYYY-MM-DD') <= (yearOfDate + '-' + serviceCategory.end_date)
                )
                ||
                (
                    moment(date).format('YYYY-MM-DD') >= (yearOfDate + '-' + serviceCategory.start_date)
                    && moment(date).format('YYYY-MM-DD') <= ((yearOfDate + 1) + '-' + serviceCategory.end_date)
                )
            ) {
                isOutOfDate = false;
            }
        }
        return isOutOfDate;
    }
    return false;
};
