import React from 'react';

// Pagination
import { usePagination, DOTS } from './use-pagination';

// ---------------------------------------- PAGINATION ---------------------------------------- \\
const Pagination = props => {
    const {
        onChangePage,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    const onNext = () => {
        onChangePage(currentPage + 1);
    };

    const onPrevious = () => {
        onChangePage(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <div className="w-full flex justify-center mx-auto">
            <ul className="flex pl-0 rounded list-none flex-wrap">
                <li>
                    <button
                        onClick={onPrevious}
                        disabled={currentPage === 1}
                        type="button"
                        className={'first:ml-0 text-sm mx-1 font-semibold flex w-8 h-8 p-0 rounded-md items-center justify-center leading-tight relative border border-solid ' +
                            (currentPage === 1
                                ? 'border-indigo-300 text-white bg-indigo-300 cursor-not-allowed'
                                : 'border-indigo-500 text-indigo-600 bg-white'
                            )
                        }
                    >
                        <i className="fas fa-chevron-left -ml-px" />
                    </button>
                </li>
                {paginationRange.map(pageNumber => {
                    if (pageNumber === DOTS) {
                        return (
                            <li key={pageNumber}>  
                                <button className="first:ml-0 text-sm font-semibold flex w-8 h-8 mx-1 p-0 rounded-md items-center justify-center leading-tight relative border border-solid bg-white text-indigo-600">
                                    &#8230;
                                </button>
                            </li>
                        );
                    }
                    return (
                        <li key={pageNumber}>
                            <button
                                onClick={() => onChangePage(pageNumber)}
                                type="button"
                                className={'first:ml-0 text-sm font-semibold flex w-8 h-8 mx-1 p-0 rounded-md items-center justify-center leading-tight relative border border-solid ' +
                                    (pageNumber === currentPage
                                        ? 'border-indigo-600 text-white bg-indigo-600'
                                        : 'border-indigo-500 text-indigo-600 bg-white'
                                    )
                                }
                            >
                                {pageNumber}
                            </button>
                        </li>
                    );
                })}
                <li>
                    <button
                        onClick={onNext}
                        disabled={currentPage === lastPage || totalCount === 0}
                        type="button"
                        className={'first:ml-0 mx-1 text-sm font-semibold flex w-8 h-8 p-0 rounded-md items-center justify-center leading-tight relative border border-solid ' +
                            (currentPage === lastPage || totalCount === 0
                                ? 'border-indigo-300 text-white bg-indigo-300 cursor-not-allowed'
                                : 'border-indigo-500 text-indigo-600 bg-white'
                            )
                        }
                    >
                        <i className="fas fa-chevron-right -ml-px" />
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
