import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

// Apollo
import { gql, useMutation, useQuery } from '@apollo/client';

// Toast
import { useToasts } from 'react-toast-notifications';

// Assets
import { productStatuses } from 'assets/constants';

// Services
import { getLabel } from 'services/helpers';

// Views
import ProductForm from './product-form';

// Components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Error from 'components/error';

// Messages
import messages from 'messages.json';

const PRODUCT = gql`
    query Product($id: ID!) {
        product(id: $id) {
            id
            name
            status
            description
            productCategory {
                id
                name
            }
            allergens {
                id
                name
            }
            serviceCategories {
                id
                name
            }
            productOrigins {
                id
                name
            }
            complements {
                id
                name
            }
            productionAreaProducts {
                name
                productionArea {
                    id
                    name
                }
            }
        }
    }
`;

const UPDATE_PRODUCT = gql`
    mutation UpdateProduct(
        $id: ID!
        $name: String!
        $status: ProductStatus!
        $description: String
        $productCategory: ConnectProductCategoryBelongsTo!
        $allergens: SyncAllergenBelongsToMany
        $serviceCategories: SyncServiceCategoryBelongsToMany
        $productOrigins: SyncProductOriginBelongsToMany
        $complements: SyncComplementBelongsToMany
        $productionAreaProducts: CreateProductionAreaProductHasMany
    ) {
        updateProduct(input: {
            id: $id
            name: $name
            status: $status
            description: $description
            productCategory: $productCategory
            allergens: $allergens
            serviceCategories: $serviceCategories
            productOrigins: $productOrigins
            complements: $complements
            productionAreaProducts: $productionAreaProducts
        }) {
            id
        }
    }
`;

// ------------------------------------------ GET PRODUCT ------------------------------------------------- \\
const GetProduct = () => {
    const { productId } = useParams();

    const [state, setState] = useState({
        loading: true,
        product: null,
    });

    const { loading, error, data } = useQuery(PRODUCT, {
        variables: {
            id:  productId,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (data) {
            let product = data.product;
            setState({
                ...state,
                loading: false,
                product: {
                    id: productId,
                    name: product.name,
                    status: ({ value: product.status, label: getLabel(productStatuses, product.status) }),
                    productCategory: ({ value: product.productCategory.id, label: product.productCategory.name }),
                    productOrigins: product.productOrigins.map(po => ({ value: po.id, label: po.name })),
                    complements: product.complements.map(c => ({ value: c.id, label: c.name })),
                    allergens: product.allergens.map(a => ({ value: a.id, label: a.name })),
                    serviceCategories: product.serviceCategories.map(sc => ({ value: sc.id, label: sc.name })),
                    productionAreaProducts: product.productionAreaProducts.map(pap => ({ name: pap.name, productionAreaId: pap.productionArea.id, title: pap.productionArea?.name + ' (' + pap.name + ')' })),
                    description: product.description || '',
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading || state.loading) {
        return (
            <Card>
                <Loading />
            </Card>
        );
    }
    if (error) {
        return (
            <Card>
                <Error />
            </Card>
        );
    }

    return <UpdateProduct product={state.product} />;
};

// ----------------------------------------- UPDATE PRODUCT ----------------------------------------------- \\
const UpdateProduct = ({ product }) => {
    const history = useHistory();
    const { addToast } = useToasts();
    const [updateProduct, { loading, error, data }] = useMutation(UPDATE_PRODUCT);

    const [state, setState] = useState({
        name: product.name,
        status: product.status,
        productCategory: product.productCategory,
        productOrigins: product.productOrigins,
        complements: product.complements,
        allergens: product.allergens,
        serviceCategories: product.serviceCategories,
        productionAreaProducts: product.productionAreaProducts,
        openProductionAreaProduct: false,
        description: product.description,
    });

    const getProductionAreaProducts = () => {
        if (state.productionAreaProducts != null && state.productionAreaProducts.length > 0) {
            let result = [];
            state.productionAreaProducts.forEach(productionAreaProduct => {
                result.push({
                    name: productionAreaProduct.name,
                    productionArea: { connect: productionAreaProduct.productionAreaId },
                });
            });
            return result;
        }
        return [];
    };

    const onCreate = () => {
        if (state.name === '' || state.status === '' || state.productCategory == null) {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            updateProduct({
                variables: {
                    id: product.id,
                    name: state.name,
                    status: state.status.value,
                    productCategory: { connect: state.productCategory.value },
                    productOrigins: { sync: state.productOrigins ? state.productOrigins.map(po => po.value) : [] },
                    complements: { sync: state.complements ? state.complements.map(c => c.value) : [] },
                    allergens: { sync: state.allergens ? state.allergens.map(a => a.value) : [] },
                    serviceCategories: { sync: state.serviceCategories.map(c => c.value) },
                    productionAreaProducts: { create: getProductionAreaProducts() },
                    description: state.description,
                }
            });
        }
    }

    useEffect(() => {
        if (data) {
            addToast(messages['product.saved'], { appearance: 'success' });
            history.push('/app/products');
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <Card>
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="flex items-center py-3 px-6">
                <div className="w-1/3">
                    <Button
                        label="Retour"
                        onClick={() => history.goBack()}
                    />
                </div>
                <div className="w-1/3 text-center">
                    <h3 className="font-semibold text-base text-blueGray-700">
                        Modifier un produit
                    </h3>
                </div>
                <div className="w-1/3 text-right">
                    <span className="text-sm text-indigo-300 font-semibold">
                        <i className="fas fa-star-of-life text-xs mr-2" />
                        Requis
                    </span>
                </div>
            </div>
            {/* -------------------- FORM -------------------- */}
            <div className="flex flex-col w-full px-6 py-3 border-t border-solid border-blueGray-100">
                {loading ? (
                    <Loading />
                ) : (
                    <ProductForm
                        state={state}
                        setState={setState}
                    />
                )}
            </div>
            {/* -------------------- SAVE -------------------- */}
            <div className="py-3 text-center border-t border-solid border-blueGray-100">
                <button
                    onClick={onCreate}
                    type="submit"
                    className="bg-indigo-500 hover:bg-green-600 font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150
                        text-white text-xs"
                >
                    Enregistrer
                </button>
            </div>
        </Card>
    );
};

export default GetProduct;
