import React, { useState } from 'react';

// ---------------------------------------- INPUT SEARCH ---------------------------------------- \\
const InputSearch = ({ onSearch, placeholder }) => {
    const [inputValue, setInputValue] = useState('');

    return (
        <div className="flex">
            <input
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onKeyPress={e => e.key === 'Enter' ? onSearch(inputValue) : null}
                placeholder={placeholder}
                type="text"
                className="input-search rounded-l-lg text-sm border-blueGray-400"
            />
            {/* -------------------- SEARCH -------------------- */}
            <button
                onClick={() => onSearch(inputValue)}
                className="px-3 rounded-r-lg bg-blueGray-400 hover:bg-blueGray-500"
            >
                <i className="fas fa-search text-white" />
            </button>
        </div>
    );
};

InputSearch.defaultProps = {
    placeholder: "Chercher...",
};

export default InputSearch;
