import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select'

// Apollo
import { gql, useQuery } from '@apollo/client';

// Lodash Debounce
import debounce from 'lodash.debounce';

const PRODUCTION_AREAS = gql`
    query ProductionAreas($name: String, $first: Int!, $page: Int!) {
        productionAreas(name: $name, first: $first, page: $page, orderBy: [{ column: NAME, order: ASC }]) {
            data {
                id
                name
            }
        }
    }
`;

// -------------------------------------- SELECT PRODUCTION AREA --------------------------------------- \\
const SelectProductionArea = ({ productionArea, setProductionArea, productionAreaIds }) => {
    const [state, setState] = useState({
        inputValue: '',
        options: [],
    });

    const { loading, data } = useQuery(PRODUCTION_AREAS, {
        variables: {
            page: 1,
            first: 10,
            name: '%' + state.inputValue + '%',
        },
        fetchPolicy: 'network-only',
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, inputValue: value == null ? '' : value });
    };
    const delayedOnSearch = useRef(debounce(inputValue => onSearch(inputValue), 500)).current;

    const handleDelayedOnSearch = (value, reason) => {
        if (reason.action === 'input-change') {
            delayedOnSearch(value);
        }
    };

    useEffect(() => {
        if (data) {
            let productionAreas = data.productionAreas.data;
            
            if (productionAreaIds != null && productionAreaIds.length > 0) {
                productionAreas = productionAreas.filter(pa => !productionAreaIds.includes(pa.id));
            }

            setState({ ...state, options: productionAreas.map(pa => ({ value: pa.id, label: pa.name })) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Select
            placeholder="Chercher une zone..."
            value={productionArea}
            onChange={value => setProductionArea(value)}
            onInputChange={(inputValue, reason) => handleDelayedOnSearch(inputValue, reason)}
            options={state.options}
            isLoading={loading}
            isClearable={true}
            noOptionsMessage={() => 'Aucune'}
            menuPortalTarget={document.body}
            maxMenuHeight={200}
            styles={{
                control: base => ({ ...base, fontSize: 14 }),
                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                container: base => ({ ...base, minWidth: 200, width: '100%' }),
            }}
        />
    );
};

export default SelectProductionArea;
