import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

// Storage
import { getToken, clearToken } from './storage';

// URL
// export const URL = 'http://127.0.0.1:8000/';
export const URL = 'https://portail.traiteur-avs.com/';

// ------------------------------------------- UPLOAD LINK ------------------------------------------------ \\
const uploadLink = createUploadLink({
    uri: URL + 'graphql',
});

// -------------------------------------------- AUTH LINK ------------------------------------------------- \\
const authLink = setContext((_, { headers }) => {
    let token = getToken();
    return {
        headers: {
            ...headers,
            authorization: token ? 'Bearer ' + token : '',
        }
    }
});

// ------------------------------------------- ERROR LINK ------------------------------------------------- \\
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            console.log(`[GraphQL error]: ${err.message}`);
            if (err.message != null && err.message.includes('Unauthenticated')) {
                clearToken();
                window.location.reload();
            }
        }
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError.message}`);
    }
});

// ---------------------------------------------- CLIENT -------------------------------------------------- \\
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, authLink.concat(uploadLink)]),
    defaultOptions: {
        watchQuery: { errorPolicy: 'all' },
        query: { errorPolicy: 'all' },
        mutate: { errorPolicy: 'all' },
    },
});

export default client;
