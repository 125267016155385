import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Components
import ModalButtons from 'components/modal-buttons';
import Input from 'components/input';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const UPDATE_PRODUCT_ORIGIN_CATEGORY = gql`
    mutation updateProductOriginCategory($id: ID!, $name: String!) {
        updateProductOriginCategory(id: $id, name: $name) {
            id
        }
    }
`;

// ------------------------------------------- UPDATE PRODUCT ORIGIN CATEGORY ------------------------------------------- \\
const UpdateProductOriginCategory = ({ onClose, refetch, productOriginCategory }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        name: productOriginCategory.name || '',
    });

    const [updateProductOriginCategory, { loading, error, data }] = useMutation(UPDATE_PRODUCT_ORIGIN_CATEGORY);

    const onUpdateProductOriginCategory = () => {
        if (state.name === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else {
            updateProductOriginCategory({ variables: { id: productOriginCategory.id, name: state.name } });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['product.origin.category.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        {/* --------------------- NAME --------------------- */}
                        <Input
                            value={state.name}
                            onChange={e => setState({ ...state, name: e.target.value })}
                            placeholder="Nom"
                            required
                        />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onUpdateProductOriginCategory}
                        labelRight="Enregistrer"
                    />
                </>
            )}
        </>
    );
};

export default UpdateProductOriginCategory;
