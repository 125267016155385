import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Popper
import { usePopper } from 'react-popper';

// Services
import { getStoredUser } from 'services/storage';

// Views
import Logout from './logout';

// Components
import Portal from 'components/portal';
import Modal from 'components/modal';

// -------------------------------------------- COLOR ---------------------------------------------------- \\
const getColor = user => {
    if (user != null) {
        const initial1 = user.first_name.charAt(0).toLowerCase();
        const initial2 = user.last_name.charAt(0).toLowerCase();
    
        const asciiCode1 = initial1.charCodeAt(0);
        const asciiCode2 = initial2.charCodeAt(0);
    
        const colorNum = asciiCode1.toString() + asciiCode2.toString() + asciiCode1.toString();
    
        let num = Math.round(0xffffff * parseInt(colorNum));
        // eslint-disable-next-line no-mixed-operators
        let r = num >> 16 & 255;
        // eslint-disable-next-line no-mixed-operators
        let g = num >> 8 & 255;
        let b = num & 255;
    
        return 'rgba(' + r + ',' + g + ',' + b + ', 0.8)';
    }
    return 'gray';
};

// ------------------------------------------- PROFILE --------------------------------------------------- \\
const Profile = () => {
    const history = useHistory();
    const user = getStoredUser();

    // Popper
    const [referenceElement, setReferenceElement] = useState();
    const [popperElement, setPopperElement] = useState();
    const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'left' });

    const [state, setState] = useState({
        initial1: user ? user.first_name.charAt(0) : '',
        initial2: user ? user.last_name.charAt(0) : '',
        color: getColor(user),
        open: false,
        openLogout: false,
        zIndex: 0,
    });

    const onClickProfile = () => {
        setState({ ...state, open: false, zIndex: -1 });
        history.push('/app/profile');
    };

    return (
        <>
            {/* -------------------- PELLET -------------------- */}
            <div
                onClick={() => setState({ ...state, open: !state.open, zIndex: 0 })}
                ref={setReferenceElement}
                className="cursor-pointer w-12 h-12 text-sm text-white flex items-center justify-center rounded-full border border-white"
                style={{ backgroundColor: state.color }}
            >
                <b className="uppercase">
                    {state.initial1}{state.initial2}
                </b>
            </div>
            {/* -------------------- POPPER -------------------- */}
            {state.open && (
                <Portal>
                    <div
                        ref={setPopperElement}
                        style={{ ...styles.popper, zIndex: state.zIndex }}
                        {...attributes.popper}
                        className="rounded mr-1 bg-white cursor-pointer text-sm"
                    >
                        <div
                            onClick={onClickProfile}
                            className="py-2 px-3 rounded-t hover:bg-indigo-200"
                        >
                            <b className="text-xs text-blueGray-600 uppercase">
                                <i className="fas fa-user mr-2 text-base align-middle" />
                                Profil
                            </b>
                        </div>
                        <div className="border-t border-blueGray-600" />
                        <div 
                            onClick={() => setState({ ...state, openLogout: true, zIndex: -1, open: false })}
                            className="py-2 px-3 rounded-b hover:bg-indigo-200"
                        >
                            <b className="text-xs text-blueGray-600 uppercase">
                                <i className="fas fa-sign-out-alt mr-2 text-base align-middle" />
                                Se déconnecter
                            </b>
                        </div>
                    </div>
                </Portal>
            )}
            {/* --------------------------- LOGOUT --------------------------- */}
            {state.openLogout && (
                <Modal
                    title="Déconnexion"
                    width="w-6/12"
                >
                    <Logout
                        onClose={() => setState({ ...state, openLogout: false })}
                    />
                </Modal>
            )}
        </>
    );
};

export default Profile;
