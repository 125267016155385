import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Toast Notifications
import { useToasts } from 'react-toast-notifications';

// Services
import { setToken } from 'services/storage';

// Components
import Loading from 'components/loading';
import Input from 'components/input';
import Checkbox from 'components/checkbox';

const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            user {
                id
                first_name
                last_name
                fullName
            }
            token
        }
    }
`;

// ---------------------------------------- LOGIN ------------------------------------------------ \\
const Login = () => {
    const { addToast } = useToasts();
    const history = useHistory();
    const [login, { loading, error, data }] = useMutation(LOGIN);

    const today = new Date();
    const option_date = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const [state, setState] = useState({
        email: '',
        password: '',
        remember_me: false,
    });

    const onLogin = () => {
        if (state.email === '' || state.password === '') {
            addToast('Veuillez saisir vos identifiants', { appearance: 'warning' });
        }
        else {
            login({ variables: { email: state.email, password: state.password } });
        }
    }

    useEffect(() => {
        if (data) {
            setToken(data, state.remember_me);
            history.push('/app');
        }
        if (error) {
            addToast(error.message, { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    if (loading) return <Loading />;

    return (
        <>
            {/* -------------------- WELCOME -------------------- */}
            <div className="text-center text-white mb-2 -mt-6">
                Bienvenue sur le portail AVS
                <br />
                Nous sommes aujourd'hui le <b>{today.toLocaleDateString('fr-FR', option_date)}</b>
            </div>
            {/* -------------------- LOGIN -------------------- */}
            <div className="w-full grid grid-cols-3">
                <div />
                <div className="bg-white p-4 rounded shadow">
                    <form>
                        {/* -------------------- TITLE -------------------- */}
                        <div className="text-blueGray-400 text-center font-bold">
                            <small>Se connecter</small>
                        </div>
                        {/* -------------------- EMAIL -------------------- */}
                        <Input
                            value={state.email}
                            onChange={e => setState({ ...state, email: e.target.value })}
                            type="email"
                            placeholder="Email"
                        />
                        {/* -------------------- PASSWORD -------------------- */}
                        <Input
                            value={state.password}
                            onChange={e => setState({ ...state, password: e.target.value })}
                            type="password"
                            placeholder="Mot de passe"
                        />
                        {/* -------------------- REMEBER ME -------------------- */}
                        <Checkbox
                            value={state.remember_me}
                            onChange={() => setState({ ...state, remember_me: !state.remember_me })}
                            placeholder="Se souvenir de moi"
                        />
                        {/* -------------------- LOGIN -------------------- */}
                        <button
                            onClick={onLogin}
                            type="submit"
                            className="flex items-center justify-center bg-indigo-600 hover:bg-indigo-700 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none w-full"
                        >
                            <b className="text-white text-sm uppercase">
                                Connexion
                            </b>
                        </button>
                    </form>
                </div>
            </div>
            {/* -------------------- FORGOTTEN PASSWORD -------------------- */}
            <div className="text-center text-white mt-2">
                <Link to="/auth/forgotten-password">Mot de passe oublié ?</Link>
            </div>
        </>
    );
};

export default Login;
