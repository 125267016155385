import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Apollo
import { gql, useQuery } from '@apollo/client';

// Views
import AddPhotoToProduct from './upload-extra-category-photo';
import Extras from './extras';

// Components
import Card from 'components/card';
import Loading from 'components/loading';
import Error from 'components/error';
import Button from 'components/button';
import Modal from 'components/modal';
import PhotoDisplayer from 'components/photo-displayer';
import PhotoSelector from 'components/photo-selector';

const EXTRA_CATEGORY = gql`
    query ExtraCategory($id: ID!) {
        extraCategory(id: $id) {
            id
            name
            description
            photo
            extras {
                id
                name
            }
            creator {
                fullName
            }
            lastModifier {
                fullName
            }
        }
    }
`;

// ------------------------------------------- EXTRA CATEGORY --------------------------------------------------- \\
const ExtraCategory = () => {
    const history = useHistory();
    const { extraCategoryId } = useParams();

    const [state, setState] = useState({
        openExtras: false,
        openPhoto: false,
    });

    const { loading, error, data, refetch } = useQuery(EXTRA_CATEGORY, {
        variables: {
            id: extraCategoryId,
        },
        fetchPolicy: 'network-only',
    });

    const onRefetch = () => {
        setState({ ...state, openPhoto: false });
        refetch();
    };

    if (loading) return <Loading />;
    if (error || data.extraCategory == null) return <Error />;

    return (
        <>
            <Card>
                {/* -------------------- TOOLBAR -------------------- */}
                <div className="flex items-center p-3">
                    <div className="w-1/3">
                        <Button
                            label="Retour"
                            onClick={() => history.push('/app/extra-categories')}
                        />
                    </div>
                    <div className="w-1/3 text-center">
                        <h3 className="font-semibold text-base text-blueGray-700">
                            {data.extraCategory.name}
                        </h3>
                    </div>
                </div>
                {/* -------------------- CREATOR & LAST MODIFIER -------------------- */}
                <div className="flex items-center justify-between bg-blueGray-100 p-3">
                    {data.extraCategory.creator && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Créateur : {data.extraCategory.creator.fullName}
                        </div>
                    )}
                    {data.extraCategory.lastModifier && (
                        <div className="text-xs uppercase font-semibold text-blueGray-500">
                            Dernière modification : {data.extraCategory.lastModifier.fullName}
                        </div>
                    )}
                </div>
                <div className="flex flex-col lg:flex-row items-center p-6">
                    {/* -------------------- INFOS -------------------- */}
                    <div className="w-12/12 lg:w-9/12">
                        {/* -------------------- EXTRAS -------------------- */}
                        <div className="flex items-center w-full py-2">
                            <div className="w-4/12">
                                <b className="text-sm text-blueGray-600">Extras liés</b>
                            </div>
                            <div className="w-8/12">
                                {data.extraCategory.extras && data.extraCategory.extras.length > 0 ? (
                                    <button
                                        onClick={() => setState({ ...state, openExtras: true })}
                                        type="button"
                                        className="border border-blueGray-400 px-3 py-1 rounded text-blueGray-400 text-sm font-semibold uppercase
                                            hover:text-white hover:bg-blueGray-400"
                                    >
                                        Voir la liste
                                    </button>
                                ) : (
                                    <b className="text-sm text-blueGray-600">Aucun</b>
                                )}
                            </div>
                        </div>
                        {/* -------------------- DESCRIPTION -------------------- */}
                        {data.extraCategory.description && (
                            <div className="flex items-center w-full py-2">
                                <div className="w-4/12">
                                    <b className="text-sm text-blueGray-600">Description</b>
                                </div>
                                <div className="w-8/12">
                                    <b className="text-sm text-blueGray-600">
                                        {data.extraCategory.description}
                                    </b>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* -------------------- PHOTO -------------------- */}
                    <div className="w-12/12 lg:w-3/12 flex items-center justify-center">
                        {(data.extraCategory.photo == null || data.extraCategory.photo === '') ? (
                            <PhotoSelector
                                onClick={() => setState({ ...state, openPhoto: true })}
                            />
                        ) : (
                            <PhotoDisplayer
                                onClick={() => setState({ ...state, openPhoto: true })}
                                fileUrl={data.extraCategory.photo}
                            />
                        )}
                    </div>
                </div>
            </Card>          
            {/* --------------------------- PHOTO --------------------------- */}
            {state.openPhoto && (
                <Modal
                    title="Ajouter une photo"
                    required
                >
                    <AddPhotoToProduct
                        onClose={() => setState({ ...state, openPhoto: false })}
                        refetch={onRefetch}
                        extraCategoryId={extraCategoryId}
                    />
                </Modal>
            )}
            {/* --------------------------- EXTRAS --------------------------- */}
            {state.openExtras && (
                <Modal title={'Extras liés à la catégorie : ' + data.extraCategory.name}>
                    <Extras
                        onClose={() => setState({ ...state, openExtras: false })}
                        extras={data.extraCategory.extras}
                    />
                </Modal>
            )}
        </>
    );
};

export default ExtraCategory;
