import React, { useState, useEffect } from 'react';

// Toast
import { useToasts } from 'react-toast-notifications';

// Apollo
import { gql, useMutation } from '@apollo/client';

// Services
import { checkValidDateWithDayAndMonth} from 'services/helpers';

// Components
import ServiceCategoryForm from './service-category-form';
import ModalButtons from 'components/modal-buttons';
import Loading from 'components/loading';

// Messages
import messages from 'messages.json';

const CREATE_SERVICE_CATEGORY = gql`
    mutation CreateServiceCategory(
        $name: String!,
        $start_date: String!,
        $end_date: String!,
        $status: ServiceCategoryStatus,
        $color: String,
        $description: String,
        $number: Int,
        $priority: Int!,
    ) {
        createServiceCategory(input: {
            name: $name,
            start_date: $start_date,
            end_date: $end_date,
            status: $status,
            color: $color,
            description: $description,
            number: $number,
            priority: $priority,
        }) {
            id
        }
    }
`;

// ---------------------------------------- CREATE SERVICE CATEGORY --------------------------------------- \\
const CreateServiceCategory = ({ onClose, refetch }) => {
    const { addToast } = useToasts();

    const [state, setState] = useState({
        name: '',
        start_date: '01/01',
        end_date: '31/12',
        status: '',
        color: '#4a90e2',
        description: '',
        number: '',
        priority: 1,
    });

    const [createServiceCategory, { loading, error, data }] = useMutation(CREATE_SERVICE_CATEGORY);

    const onCreateServiceCategory = () => {
        if (state.name === '' || state.priority === '') {
            addToast(messages['fields.required'], { appearance: 'warning' });
        }
        else if (!checkValidDateWithDayAndMonth(state.start_date) || !checkValidDateWithDayAndMonth(state.end_date)) {
            addToast('La date de début ou la date de fin n\'est pas correcte', { appearance: 'warning' });
        }
        else {
            createServiceCategory({
                variables: {
                    name: state.name,
                    start_date: state.start_date.split('/')[1] + '-' + state.start_date.split('/')[0],
                    end_date: state.end_date.split('/')[1] + '-' + state.end_date.split('/')[0],
                    status: (state.status != null && state.status !== "") ? state.status.value : null,
                    color: state.color,
                    description: state.description,
                    number: (state.number != null && state.number !== "") ? Number(state.number) : null,
                    priority: Number(state.priority),
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            addToast(messages['service.category.saved'], { appearance: 'success' });
            refetch();
        }
        if (error) {
            addToast(messages['error.occured'], { appearance: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="p-6 w-full">
                        <ServiceCategoryForm state={state} setState={setState} />
                    </div>
                    <ModalButtons
                        onClose={onClose}
                        save={onCreateServiceCategory}
                    />
                </>
            )}
        </>
    );
};

export default CreateServiceCategory;
